import React, { useRef, useState, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import Quagga from "quagga";

const BarcodeReader = ({ onBarcodeMatch, length }) => {
  const webcamRef = useRef(null);
  const [result, setResult] = useState(null);

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    decodeBarcodeFromImage(imageSrc);
  }, [webcamRef]);

  const decodeBarcodeFromImage = (imageSrc) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      Quagga.decodeSingle(
        {
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
              "i2of5_reader",
            ],
          },
          src: img.src,
          inputStream: {
            constraints: {
              width: img.width,
              height: img.height,
            },
            singleChannel: true,
          },
          frequency: 100,
        },
        (result) => {
          if (result?.codeResult && result.codeResult.code.length === length) {
            onBarcodeMatch(result.codeResult.code);
          } else {
            setResult("No barcode found");
          }
        }
      );
    };

    img.src = imageSrc;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (webcamRef.current) {
        captureImage();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [captureImage]);

  return (
    <div>
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          style={{ width: "100%" }}
        />
      </div>
      {result && <p>Result: {result}</p>}
    </div>
  );
};

export default BarcodeReader;
