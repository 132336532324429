import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import prizesService from "../../../services/prizes/prizesService";
import { Button } from "primereact/button";
import clubService from "../../../services/club/clubService";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { ImageUploader } from "../components/ImageUploader";
import { InputNumber } from "primereact/inputnumber";
import { ImageUploaderMultiple } from "../components/ImageUploaderMultiple";

const inputStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  width: "400px",
};

export const UpdatePrizesStep = ({ setIndex, clubId }) => {
  const updatePrizesForm = useForm();
  const [useExistingPrize, setUseExistingPrize] = useState(true);
  const [updatePrizesLoading, setUpdatePrizesLoading] = useState(false);
  const [selectedPrizeIds, setSelectedPrizeIds] = useState([]);

  const [prizes, setPrizes] = useState([]);

  function groupPrizesByClub(prizes) {
    const groupedPrizes = {};

    prizes.forEach((prize) => {
      const { club } = prize;
      const { clubName } = club;

      if (groupedPrizes[clubName]) {
        groupedPrizes[clubName].items.push(prize);
      } else {
        groupedPrizes[clubName] = { clubName, items: [prize] };
      }
    });

    const result = Object.values(groupedPrizes);

    return result;
  }

  useEffect(() => {
    (async () => {
      let response = await prizesService.getAll({
        MaxResultCount: 1000,
      });
      setPrizes(groupPrizesByClub(response?.items));
    })();
  }, []);

  useEffect(() => {
    if (useExistingPrize) {
      updatePrizesForm.reset({});
    } else {
      setSelectedPrizeIds([]);
    }
  }, [useExistingPrize]);

  const onSubmit = async (data) => {
    setUpdatePrizesLoading(true);
    data.id = clubId;
    if (selectedPrizeIds.length > 0) {
      data.existingPrizeIds = selectedPrizeIds;
    }

    await clubService.updatePrizes(data);
    setIndex((idx) => idx + 1);
    setUpdatePrizesLoading(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "30px",
        }}
      >
        <InputSwitch
          checked={useExistingPrize}
          onChange={(e) => {
            setUseExistingPrize(e.value);
          }}
        />
        <h4
          style={{
            margin: 0,
          }}
        >
          Use existing prize
        </h4>
      </div>
      <form
        onSubmit={updatePrizesForm.handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
        }}
      >
        {useExistingPrize ? (
          <div>
            <MultiSelect
              value={selectedPrizeIds}
              options={prizes}
              onChange={(e) => setSelectedPrizeIds(e.value)}
              optionLabel="prizeTitle"
              optionValue="id"
              optionGroupLabel="clubName"
              optionGroupChildren="items"
              placeholder="Select Prizes"
              filter
              resetFilterOnHide
              showSelectAll={false}
              display="chip"
              className="w-full md:w-20rem"
            />
          </div>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div style={inputStyles}>
              <label>Start date</label>

              <Controller
                name="startDate"
                control={updatePrizesForm.control}
                rules={{ required: !useExistingPrize }}
                render={({ field, fieldState }) => (
                  <Calendar
                    {...field}
                    value={new Date(field.value)}
                    dateFormat="dd.mm.yy"
                    onChange={(e) =>
                      field.onChange(new Date(e.value).toISOString())
                    }
                    placeholder="Select a start date"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>End date</label>
              <Controller
                name="endDate"
                rules={{ required: !useExistingPrize }}
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <Calendar
                    {...field}
                    value={new Date(field.value)}
                    dateFormat="dd.mm.yy"
                    onChange={(e) =>
                      field.onChange(new Date(e.value).toISOString())
                    }
                    placeholder="Select an end date"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>Prize Title</label>
              <Controller
                rules={{ required: !useExistingPrize }}
                name="prizeTitle"
                defaultValue={""}
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputText
                    {...field}
                    placeholder="Prize title"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>Description</label>
              <Controller
                rules={{ required: !useExistingPrize }}
                name="description"
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputTextarea
                    {...field}
                    rows={10}
                    placeholder="Description"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>Promo Url</label>
              <Controller
                rules={{ required: !useExistingPrize }}
                name="promoUrl"
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputText
                    {...field}
                    placeholder="Promo Url"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>Max Free Entries</label>
              <Controller
                rules={{ required: !useExistingPrize }}
                defaultValue={10}
                name="maxFreeEntries"
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    inputId="minmax-buttons"
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    mode="decimal"
                    showButtons
                    min={0}
                    max={1000000}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>Prize Link</label>
              <Controller
                rules={{ required: !useExistingPrize }}
                name="prizeLink"
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputText
                    {...field}
                    placeholder="Prize Link"
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <div style={inputStyles}>
              <label>
                Is Free Entrance Closed{" "}
                <span style={{ color: "red" }}>
                 ( not available YouTube video ) 
                </span>
              </label>
              <Controller
                name="isFreeClosed"
                defaultValue={false}
                control={updatePrizesForm.control}
                render={({ field, fieldState }) => (
                  <InputSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ "p-invalid": fieldState.error })}
                  />
                )}
              />
            </div>
            <ImageUploader
              field={{
                field: "fileImage",
                label: "Image",
              }}
              control={updatePrizesForm.control}
              loading={updatePrizesLoading}
              style={{
                width: inputStyles.width,
              }}
            />
            <ImageUploaderMultiple
              field={{
                field: "prizeImages",
                label: "Prize Images",
              }}
              control={updatePrizesForm.control}
              loading={updatePrizesLoading}
              style={{
                width: inputStyles.width,
              }}
            />
          </div>
        )}
        <Button
          label="Update Prizes"
          className="p-button-info"
          type="submit"
          disabled={updatePrizesLoading}
          loading={updatePrizesLoading}
          style={{
            width: "fit-content",
          }}
        />
      </form>
    </div>
  );
};
