import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { imageShowBody } from "../../components/crudTable/crudTableUtils";

const imageBody = (rowData) => {
  return (
    <>
      {rowData.image?.contents ? (
        <img
          src={`data:image/jpeg;base64,${rowData.image?.contents}`}
          alt="notifications"
          style={{ maxWidth: "100px", borderRadius: "10px" }}
        />
      ) : null}
    </>
  );
};

const showClubName = (rowData) => {
  return (
    rowData.club && (
      <Button label={rowData.club.clubName} className="p-button-help" />
    )
  );
};

const showPoints = (rowData) => {
  return <Badge value={rowData.pointsToEarn || "0"} severity="danger" />;
};

const fieldsToShow = [
  {
    header: "Image",
    name: "image",
    body: imageBody,
  },
  {
    header: "Title",
    name: "title",
  },
  {
    header: "Points to earn",
    name: "pointsToEarn",
    body: showPoints,
  },
  {
    header: "Start Date",
    name: "startDate",
    body: (rowData) => <p>{new Date(rowData.startDate).toDateString()}</p>,
  },
  {
    header: "End Date",
    name: "endDate",
    body: (rowData) => <p>{new Date(rowData.endDate).toDateString()}</p>,
  },
  {
    header: "Active",
    name: "isActive",
    body: (rowData) => <Button label={rowData.isActive ? "true" : "false"} />,
  },
  {
    header: "Club",
    name: "club.clubName",
    body: showClubName,
  },
];
const fieldsToEdit = [
  {
    name: "title",
    title: "Title",
    type: "string",
    rules: {
      required: "Title is required",
    },
  },
  {
    name: "startDate",
    title: "StartDate",
    type: "calendar",
    rules: {
      required: "StartDate is required",
    },
  },
  {
    name: "endDate",
    title: "EndDate",
    type: "calendar",
    rules: {
      required: false,
    },
  },
  {
    name: "image",
    title: "Image",
    type: "imageCrop",
  },
  {
    name: "image",
    title: "",
    body: imageShowBody,
  },
  {
    name: "video",
    title: "Video",
    type: "video",
  },
  {
    name: "pointsToEarn",
    title: "PointsToEarn",
    type: "number",
    rules: {
      required: false,
    },
  },
  {
    name: "pointsLimit",
    title: "Points limit",
    type: "number",
  },
  {
    name: "isActive",
    title: "Active",
    type: "bool",
  },
  {
    name: "layoutJson",
    title: "LayoutJson",
    type: "json",
    rules: {
      required: "LayoutJson is required",
    },
  },
  {
    name: "referralLandingPageJson",
    title: "ReferralLandingPageJson",
    type: "json",
    rules: {
      required: "ReferralLandingPageJson is required",
    },
  },
  {
    name: "referFriendsFromContactsJson",
    title: "ReferFriendsFromContactsJson",
    type: "json",
    rules: {
      required: "ReferFriendsFromContactsJson is required",
    },
  },
  {
    name: "explainerScreenJson",
    title: "ExplainerScreenJson",
    type: "json",
    rules: {
      required: "ExplainerScreenJson is required",
    },
  },
  {
    name: "shareToWinScreenJson",
    title: "ShareToWinScreenJson",
    type: "json",
    rules: {
      required: "ShareToWinScreenJson is required",
    },
  },
  {
    name: "explainerVideoJson",
    title: "ExplainerVideoJson",
    type: "json",
    rules: {
      required: "ExplainerVideoJson is required",
    },
  },
];

const fieldsToCreate = [
  {
    name: "title",
    title: "Title",
    type: "string",
    rules: {
      required: "Title is required",
    },
  },
  {
    name: "startDate",
    title: "StartDate",
    type: "calendar",
    rules: {
      required: "StartDate is required",
    },
  },
  {
    name: "endDate",
    title: "EndDate",
    type: "calendar",
    rules: {
      required: "EndDate is required",
    },
  },
  {
    name: "image",
    title: "Image",
    type: "imageCrop",
  },
  {
    name: "video",
    title: "Video",
    type: "video",
  },
  {
    name: "pointsToEarn",
    title: "PointsToEarn",
    type: "number",
    rules: {
      required: false,
    },
  },
  {
    name: "pointsLimit",
    title: "Points limit",
    type: "number",
  },
  {
    name: "isActive",
    title: "Active",
    type: "bool",
  },
  {
    name: "layoutJson",
    title: "LayoutJson",
    type: "json",
    rules: {
      required: "LayoutJson is required",
    },
  },
  {
    name: "explainerVideoJson",
    title: "ExplainerVideoJson",
    type: "json",
    rules: {
      required: "ExplainerVideoJson is required",
    },
  },
  {
    type: "partner-club-select",
  },
];

const defaultValues = {
  explainerVideoJson: {
    btnClose: "",
    btnMain: "",
    btnMainAction: 1,
    videoName: "",
  },
  layoutJson: {
    title: "",
    btnMain: "",
    btnMainAction: "",
  },
};

export { fieldsToShow, fieldsToEdit, fieldsToCreate, defaultValues };
