import http from "../http/httpService";

class TranslationsService {
  async get(entityDto) {
    let result = await http.get("api/services/app/Translations/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Translations/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(entityDto, params) {
    let result = await http.put(
      "api/services/app/Translations/Update",
      entityDto,
      { params }
    );
    return result.data.result;
  }

  async create(entityDto) {
    let result = await http.post(
      "api/services/app/Translations/Create",
      entityDto
    );
    return result.data.result;
  }

  async confirm(params) {
    let result = await http.post(
      "api/services/app/Translations/ConfirmTranslations",
      {},
      { params: params }
    );
    return result.data.result;
  }

  async confirmOne(params) {
    let result = await http.post(
      "api/services/app/Translations/ConfirmSingleTranslation",
      {},
      { params: params }
    );
    return result.data.result;
  }

  async rejectOne(params) {
    let result = await http.post(
      "api/services/app/Translations/RejectSingleTranslation",
      {},
      { params: params }
    );
    return result.data.result;
  }

  async merge(params) {
    let result = await http.post(
      "api/services/app/Translations/MergeTranslations",
      {},
      { params: params }
    );
    return result.data.result;
  }

  async getAllEnvs(params) {
    let result = await http.get(
      "api/services/app/Translations/GetAllEnvironments",
      { params: params }
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Translations/DeleteById", {
      params: entityDto,
    });
    return result.data.result;
  }
}

export default new TranslationsService();
