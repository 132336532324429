const fieldsToShow = [
  {
    header: "Name",
    name: "name",
  },
  {
    header: "Code",
    name: "code",
  },
  {
    header: "Locale",
    name: "locale",
  },
];

const fieldsToEdit = [
  {
    name: "name",
    title: "Name",
    type: "string",
    rules: {
      required: "Name is required",
      maxLength: 50,
    },
  },
  {
    name: "code",
    title: "Code",
    type: "string",
    rules: {
      required: "Code is required",
      maxLength: 2,
    },
  },
  {
    name: "locale",
    title: "Locale",
    type: "string",
    rules: {
      required: "Locale is required",
      maxLength: 8,
    },
  },
];

const fieldsToCreate = [
  {
    name: "name",
    title: "Name",
    type: "string",
    rules: {
      required: "Name is required",
      maxLength: 50,
    },
  },
  {
    name: "code",
    title: "Code",
    type: "string",
    rules: {
      required: "Code is required",
      maxLength: 2,
    },
  },
  {
    name: "locale",
    title: "Locale",
    type: "string",
    rules: {
      required: "Locale is required",
      maxLength: 8,
    },
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
