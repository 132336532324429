import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import translationService from "../../services/translations/translationsService";
import languagesService from "../../services/languages/languagesService";
import { useErrors } from "../../utils/error/useErrors";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import screenService from "../../services/screenLayouts/screenLayoutService";
import { Controller, useForm } from "react-hook-form";
import { sortByProperty } from "../../components/crudTable/crudTableUtils";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { L } from "../../utils/abpUtility";

let abp = window.abp;

const Translations = () => {
  const [translations, setTranslations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { handleSubmit, reset, register, control } = useForm();
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [screens, setScreens] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [createDialog, setCreateDialog] = useState(false);
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [useScreen, setScreen] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100500,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const toast = useRef(null);

  let timer;

  useEffect(() => {
    getAllTranslations();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  useEffect(() => {
    getAllTranslations();
  }, []);

  useEffect(() => {
    screenService
      .getAll({
        MaxResultCount: 1000,
      })
      .then((response) => setScreens(response.items));
  }, []);

  const onEditSubmit = (data) => {
    translationService
      .update(data)
      .then(() => {
        getAllTranslations();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getAllLanguages = async () => {
    let data = await languagesService.getAll();
    setLanguages(data.items);
    return data.items;
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "You have successfully confirmed this translation",
      life: 3000,
    });
  };

  const showReject = () => {
    toast.current.show({
      severity: "error",
      summary: "Rejected",
      detail: "You have successfully rejected this translation",
      life: 3000,
    });
  };

  const generateLanguageFieldName = (langName) => {
    return `${langName}`;
  };

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getPreviousFieldName = (fieldName) => {
    return `${fieldName}previous`;
  };

  const proccessTranslationOnInitialize = (translations) => {
    const processedTranslations = [];

    translations.forEach((translation) => {
      const existingTranslation = processedTranslations.find(
        (t) => t.key === translation.key
      );

      const index = processedTranslations.indexOf(existingTranslation);

      const fieldName = generateLanguageFieldName(translation.language.locale);

      const unconfirmedTranslation = translations.find(
        (t) =>
          t.key === translation.key &&
          t.isConfirmed === false &&
          t.languageId === translation.languageId
      );

      if (existingTranslation) {
        processedTranslations[index][fieldName] = unconfirmedTranslation
          ? unconfirmedTranslation.value
          : translation.value;

        if (unconfirmedTranslation) {
          processedTranslations[index]["isConfirmed"] =
            existingTranslation.isConfirmed === true
              ? unconfirmedTranslation.isConfirmed
              : false;
        }

        if (translation.isConfirmed && unconfirmedTranslation) {
          const prevValueFieldName = getPreviousFieldName(fieldName);
          processedTranslations[index][prevValueFieldName] = translation.value;
        }
      } else {
        const prevValueFieldName = getPreviousFieldName(fieldName);
        const newTranslation = {
          ...translation,
          [fieldName]: unconfirmedTranslation
            ? unconfirmedTranslation.value
            : translation.value,
          isConfirmed:
            unconfirmedTranslation !== undefined
              ? false
              : translation.isConfirmed,
        };

        if (translation.isConfirmed) {
          newTranslation[prevValueFieldName] = translation.value;
        }

        processedTranslations.push(newTranslation);
      }
    });

    return processedTranslations;
  };

  const getAllTranslations = () => {
    setLoading(true);
    translationService.getAllEnvs().then((data) => {
      setEnvironments(data);
    });
    getAllLanguages().then((langs) => {
      var listOfFields = [
        { field: "key", header: "Key", body: renderKeyBody, disableEdit: true },
      ];
      langs.forEach((lang) => {
        listOfFields.push({
          header: generateLanguageFieldName(lang.locale),
          field: generateLanguageFieldName(lang.locale),
          body: (rowData) => (
            <CustomValueTemplate
              field={generateLanguageFieldName(lang.locale)}
              rowData={rowData}
            />
          ),
        });
      });

      listOfFields.push({
        field: "version",
        header: "version",
        body: renderVersion,
        disableEdit: true,
      });

      setFieldsToShow(listOfFields);
      setAllFields(listOfFields);

      translationService
        .getAll({
          SkipCount: lazyParams.first,
          MaxResultCount: lazyParams.rows,
          Keyword: globalFilter,
        })
        .then((translationsData) => {
          var translations = proccessTranslationOnInitialize(
            translationsData.items
          );

          setTranslations(sortByProperty(translations, "screenName"));
          setLazyParams({
            ...lazyParams,
            totalRecords: translationsData.totalCount,
          });
          setLoading(false);
        })
        .catch((e) => {
          checkErrors(e);
          setLoading(false);
        });
    });
  };

  const CustomValueTemplate = ({ field, rowData }) => {
    const [seePrevValue, setSeePrevValue] = useState(false);

    let prevValueFieldName = getPreviousFieldName(field);
    let prevValue = rowData?.[prevValueFieldName];

    return (
      <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
        <p
          style={{ marginBottom: "0px" }}
          onMouseOver={() => {
            if (prevValue && !rowData.isConfirmed) setSeePrevValue(true);
          }}
          onMouseLeave={() => {
            setSeePrevValue(false);
          }}
        >
          {seePrevValue ? prevValue : rowData?.[field]}
        </p>
      </div>
    );
  };

  function capitalizeFirstCharacter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onCreateSubmit = (data) => {
    if (useScreen) {
      data.key = `${capitalizeFirstCharacter(data.prefix)}.${data.key}`;
    }
    setLoading(true);
    translationService
      .create(data)
      .then(() => {
        getAllTranslations();
        setLoading(false);
        reset({
          key: "",
          value: "",
          prefix: "",
          languageId: null,
        });
        setCreateDialog(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    setLoading(true);
    translationService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllTranslations();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const textEditor = (options) => {
    return (
      <InputTextarea
        rows={2}
        cols={30}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onCellEditComplete = async (e) => {
    let { rowData, newValue, field } = e;
    switch (field) {
      case "key":
        rowData[field] = newValue;
        break;
      default:
        rowData.value = newValue;

        rowData.languageId = languages.find(
          (i) => generateLanguageFieldName(i.locale) === field
        ).id;
    }

    await onEditSubmit(rowData);
  };

  const renderVersion = (rowData) => {
    return (
      <p
        className="p-button p-button-outlined p-button-help"
        style={{ cursor: "pointer" }}
      >
        {rowData?.version}
      </p>
    );
  };

  const confirmOne = (key) => {
    translationService.confirmOne({ key }).then((res) => {
      getAllTranslations();
      showSuccess();
    });
  };

  const rejectOne = (key) => {
    translationService.rejectOne({ key }).then((res) => {
      getAllTranslations();
      showReject();
    });
  };

  const deleteBodyAction = (rowData) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {rowData?.isDeleted ? (
          <i
            className="pi pi-times mr-3"
            style={{ fontSize: "1.3em", cursor: "pointer" }}
            onClick={() => onDelete(rowData)}
          ></i>
        ) : null}
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => rejectOne(rowData?.key)}
          disabled={rowData?.isConfirmed}
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success"
          onClick={() => confirmOne(rowData?.key)}
          disabled={rowData?.isConfirmed}
        />
      </div>
    );
  };

  const headerTemplate = (data) => {
    const screenExists = screens.find((i) =>
      data.screenName.toLowerCase().includes(i.layoutName.toLowerCase())
    );
    return (
      <React.Fragment>
        <span className="image-text m-2">
          {data.screenName}{" "}
          {!screenExists && (
            <Button className="m-0 ml-2 p-button-danger p-button-outlined">
              Custom Data
            </Button>
          )}
        </span>
      </React.Fragment>
    );
  };
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = allFields.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setFieldsToShow(orderedSelectedColumns);
  };

  const handleConfirm = () => {
    translationService.confirm().then(() => {
      getAllTranslations();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "You have successfully confirmed all translation",
        life: 3000,
      });
    });
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          flexWrap: "wrap",
          width: "fit-content",
        }}
      >
        <h2>{L("Translations")}</h2>
      </div>
    );
  };

  const renderKeyBody = (rowData) => {
    let color = "purple";
    let iconName = "fa-earth-europe";

    if (rowData?.isConfirmed === false) {
      color = "red";
      iconName = "fa-eye-slash";
    }
    if (rowData?.isDeleted === true) {
      color = "gray";
      iconName = "fa-trash";
    }
    if (rowData?.isConfirmed === true && rowData?.isDeleted === false) {
      color = "green";
      iconName = "fa-signal";
    }
    return (
      <p>
        <i className={`fa-solid ${iconName} mr-2`} style={{ color: color }}></i>
        {rowData.key}
      </p>
    );
  };

  const onMergeEnvButtonClick = () => {
    setShowMergeDialog(false);
    setLoading(true);
    translationService.merge({ envName: "localhost" }).then((res) => {
      getAllTranslations();
    });
  };

  return (
    <>
      <div
        className="card"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toast ref={toast} />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "25px" }}>
          {abp.auth.hasPermission("Pages.Translations.Update") && (
            <React.Fragment>
              <Button
                label="Create"
                type="button"
                onClick={() => setCreateDialog(true)}
              />
              <Button
                label="Confirm All changes"
                type="button"
                className="p-button-help"
                disabled={!translations.find((i) => i.isConfirmed === false)}
                onClick={() => handleConfirm()}
              />

              <Button
                label="Merge Into"
                onClick={() => setShowMergeDialog(true)}
              />
            </React.Fragment>
          )}
          <MultiSelect
            value={fieldsToShow}
            options={allFields}
            optionLabel="header"
            onChange={onColumnToggle}
            style={{ width: "20em" }}
          />
        </div>
        <div>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={translations}
          header={renderHeader}
          loading={loading}
          rowGroupMode="subheader"
          groupRowsBy="screenName"
          expandableRowGroups
          rowGroupHeaderTemplate={headerTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
        >
          {fieldsToShow.map(({ field, header, body, disableEdit }) => {
            return (
              <Column
                key={field}
                field={field}
                body={body}
                header={header}
                style={{ width: "25%" }}
                onCellEditComplete={onCellEditComplete}
                {...{
                  editor: abp.auth.hasPermission("Pages.Translations.Update")
                    ? disableEdit
                      ? null
                      : (options) => textEditor(options)
                    : null,
                }}
              />
            );
          })}
          {abp.auth.hasPermission("Pages.Translations.Update") && (
            <Column header="" body={deleteBodyAction} />
          )}
        </DataTable>

        <Dialog
          visible={createDialog}
          header={<h2>Create translation</h2>}
          onHide={() => setCreateDialog(false)}
          style={{
            width: "40vw",
          }}
        >
          <form
            onSubmit={handleSubmit(onCreateSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 20px",
              gap: "25px",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label>Use Screen: </label>
              <InputSwitch
                checked={useScreen}
                onChange={(e) => setScreen(e.value)}
              />
            </div>

            {useScreen ? (
              <div className="p-inputgroup">
                <Controller
                  name="prefix"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      options={screens}
                      filter
                      dropdownIcon={false}
                      placeholder={"Screen"}
                      optionValue="layoutName"
                      optionLabel="layoutName"
                      {...field}
                    />
                  )}
                ></Controller>
                <span className="p-inputgroup-addon">
                  <i className="fa fa-solid fa-plus"></i>
                </span>
                <InputText
                  placeholder="Key"
                  {...register("key", { required: true })}
                />
              </div>
            ) : (
              <InputText
                placeholder="Key"
                {...register("key", { required: true })}
              />
            )}
            <InputText
              placeholder="Value"
              {...register("value", { required: true })}
            />

            <Controller
              name="languageId"
              rules={{ required: true }}
              control={control}
              defaultValue={languages?.[0]?.id}
              render={({ field }) => (
                <Dropdown
                  options={languages}
                  optionValue="id"
                  placeholder="Lang"
                  optionLabel="name"
                  {...field}
                />
              )}
            ></Controller>
            <Button label="Create" type="submit" />
          </form>
        </Dialog>

        <Dialog
          header="Pick environment"
          visible={showMergeDialog}
          style={{ width: "50vw" }}
          onHide={() => setShowMergeDialog(false)}
          draggable={false}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            {environments.map((x) => (
              <Button
                label={x.name}
                className="p-button-help"
                onClick={() => onMergeEnvButtonClick(x.name)}
              />
            ))}
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Translations;
