import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../hooks/notification/useNotification";
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ColorPicker } from "primereact/colorpicker";

import screenLayoutService from "../../../services/screenLayouts/screenLayoutService";

export const UpdateStyle = ({ clubId, setIndex }) => {
  const updateStyleStep = useForm();
  const { showSuccess } = useNotification();
  const { showError } = useNotification();
  const [updateStyleLoading, setUpdateStyleLoading] = useState(false);

  let keyCount = 0;

  const [color, setColor] = useState({
    colorName: "",
    colorValue: 0,
  });

  const [font, setFont] = useState({
    fontName: "",
    fontSize: 0.0,
    fontWeightIndex: 0,
    letterSpacing: 0.0,
  });

  const [style, setStyle] = useState({
    themeVersion: 0,
    appFontName: "",
    colors: [color],
    fonts: [font],
    landingPageColors: [color],
  });

  const isDigit = (value) => {
    return /^\d+$/.test(value);
  };

  const inputColorStyle = {
    width: "130px",
    height: "36px",
  };

  const onChangeMainHandler = (data, styleProps) => {
    setStyle(style, { ...(style[styleProps] = isDigit(data) ? +data : data) });
  };

  const onChangeColorHandler = (data, styleProps, index, name) => {
    // setStyle(style, {
    //   ...(styleProps[index][name] = isDigit(data) ? +data : data),
    // });
    let newColors = style.colors;
    newColors[index][name] = isDigit(data) ? +data : data;

    setStyle((style) => {
      return {
        ...style,
        colors: newColors,
      };
    });
  };

  const onChangeLandingColorHandler = (data, styleProps, index, name) => {
    // setStyle(style, {
    //   ...(styleProps[index][name] = isDigit(data) ? +data : data),
    // });
    let newLandingColors = style.landingPageColors;
    newLandingColors[index][name] = isDigit(data) ? +data : data;

    setStyle((style) => {
      return {
        ...style,
        landingPageColors: newLandingColors,
      };
    });
  };

  const onChangeFontHandler = (data, styleProps, index, name) => {
    // setStyle(
    //   style,
    //   ...(styleProps[index][name] = isDigit(data)
    //     ? parseFloat(data).toFixed(1)
    //     : data)
    // );
    let newFonts = style.fonts;
    newFonts[index][name] = isDigit(data)
      ? parseFloat(data.toString().replace(",", ".")).toFixed(1)
      : data;
    setStyle((style) => {
      return {
        ...style,
        fonts: newFonts,
      };
    });
  };

  const onSubmit = (data) => {
    setUpdateStyleLoading(true);
    data = { ...style };
    data.clubId = clubId;
    screenLayoutService
      .updateStyle(data)
      .then(() => {
        setIndex((index) => index + 1);
        setUpdateStyleLoading(false);
        showSuccess("Success", "You have successfully update styles");
      })
      .catch(() => {
        showError(
          "An error occured while updating the application styles",
          "Error"
        );
      });
  };

  useEffect(() => {
    screenLayoutService
      .getStyle({
        id: clubId,
      })
      .then((res) => {
        setStyle(res);
      })
      .then(() => {
        setStyle((style) => {
          return {
            ...style,
            fonts: style.fonts.map((x) => {
              return {
                fontName: x.fontName,
                fontSize: x.fontSize.toString().replace(",", "."),
                fontWeightIndex: x.fontWeightIndex,
                letterSpacing: x.letterSpacing.toString().replace(",", "."),
              };
            }),
          };
        });
      });
  }, []);

  return (
    <form
      key={++keyCount}
      onSubmit={updateStyleStep.handleSubmit(onSubmit)}
      className="updateStyle"
    >
      <h2>Update Style</h2>
      <div
        style={{
          display: "flex",
          gap: "7px",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <div>
          <label>Theme Version</label>
          <br />
          <InputText
            key={++keyCount}
            placeholder={style?.themeVersion}
            defaultValue={style?.themeVersion}
            onChange={(e) =>
              onChangeMainHandler(e.target?.value, "themeVersion")
            }
          />
        </div>
        <div>
          <label>App Font Name</label>
          <br />
          <InputText
            key={++keyCount}
            placeholder={style?.appFontName}
            defaultValue={style?.appFontName}
            onChange={(e) =>
              onChangeMainHandler(e.target?.value, "appFontName")
            }
          />
        </div>
        <h5>Aplication Colors:</h5>
        {style?.colors?.map((color, index) => {
          return (
            <Fragment key={++keyCount}>
              {/* <ColorPicker
                value={color?.colorValue && color.colorValue.toString(16)}
                onChange={(e) =>
                  onChangeColorHandler(
                    e.target?.value && parseInt("0xFF" + e.target?.value, 16),
                    style.colors,
                    index,
                    "colorValue"
                  )
                }
                inline
                format="hex"
              /> */}
              <div>
                <label key={++keyCount}>{color?.colorName}</label>
              </div>
              <div>
                <ColorPicker
                  className="mt-1 mr-3 mb-3"
                  disabled={true}
                  value={color?.colorValue.toString(16).substring(2, 8)}
                  onChange={(e) =>
                    onChangeColorHandler(
                      e.target?.value && parseInt("0xFF" + e.target?.value, 16),
                      style.colors,
                      index,
                      "colorValue"
                    )
                  }
                  format="hex"
                />
                <InputText
                  style={inputColorStyle}
                  placeholder={color?.colorValue}
                  value={color?.colorValue.toString(16).substring(2, 8)}
                  onChange={(e) => {
                    onChangeColorHandler(
                      e.target?.value && parseInt("0xFF" + e.target?.value, 16),
                      style.colors,
                      index,
                      "colorValue"
                    );
                  }}
                />
              </div>
            </Fragment>
          );
        })}

        <h5>Aplication Fonts:</h5>
        {style?.fonts?.map((font, index) => {
          return (
            <Fragment key={++keyCount}>
              <div className="">
                <label>Font Name</label>
                <br />
                <InputText
                  key={++keyCount}
                  placeholder={font?.fontName}
                  defaultValue={font?.fontName}
                  onChange={(e) =>
                    onChangeColorHandler(
                      e.target?.value,
                      style.fonts,
                      index,
                      "fontName"
                    )
                  }
                />
              </div>
              <div className="">
                <label>Font Size</label>
                <br />
                <InputText
                  key={++keyCount}
                  placeholder={font?.fontSize}
                  defaultValue={font?.fontSize}
                  onChange={(e) =>
                    onChangeFontHandler(
                      e.target?.value?.replace(",", ".").includes(".")
                        ? e.target?.value.replace(",", ".")
                        : e.target?.value?.replace(",", ".") + ".0",
                      style.fonts,
                      index,
                      "fontSize"
                    )
                  }
                />
              </div>
              <div className="">
                <label>Font Weight Index</label>
                <br />
                <InputText
                  key={++keyCount}
                  placeholder={font?.fontWeightIndex}
                  defaultValue={font?.fontWeightIndex}
                  onChange={(e) =>
                    onChangeColorHandler(
                      e.target?.value,
                      style.fonts,
                      index,
                      "fontWeightIndex"
                    )
                  }
                />
              </div>
              <div className="">
                <label>Letter Spacing</label>
                <br />
                <InputText
                  key={++keyCount}
                  placeholder={font?.letterSpacing}
                  defaultValue={font?.letterSpacing}
                  onChange={(e) =>
                    onChangeFontHandler(
                      e.target?.value?.replace(",", ".").includes(".")
                        ? e.target?.value.replace(",", ".")
                        : e.target?.value?.replace(",", ".") + ".0",
                      style.fonts,
                      index,
                      "letterSpacing"
                    )
                  }
                />
              </div>
            </Fragment>
          );
        })}

        <h5>Landing Page Colors:</h5>
        {style?.landingPageColors?.map((color, index) => {
          return (
            <Fragment key={++keyCount}>
              <label>{color?.colorName}</label>
              <div className="">
                <ColorPicker
                  disabled={true}
                  className="mt-1 mr-3 mb-3"
                  value={color?.colorValue.toString(16).substring(2, 8)}
                  onChange={(e) =>
                    onChangeLandingColorHandler(
                      e.target?.value && parseInt("0xFF" + e.target?.value, 16),
                      style.landingPageColors,
                      index,
                      "colorValue"
                    )
                  }
                  format="hex"
                />
                <InputText
                  style={inputColorStyle}
                  disabled={false}
                  key={++keyCount}
                  placeholder={color?.colorValue}
                  defaultValue={color?.colorValue.toString(16).substring(2, 8)}
                  //defaultValue={color?.colorValue}
                  onChange={(e) =>
                    onChangeLandingColorHandler(
                      e.target?.value && parseInt("0xFF" + e.target?.value, 16),
                      style.landingPageColors,
                      index,
                      "colorValue"
                    )
                  }
                />
              </div>
            </Fragment>
          );
        })}
      </div>
      <Button
        className="mt-3"
        key={++keyCount}
        label="Update styles"
        disabled={updateStyleLoading}
        loading={updateStyleLoading}
      />
    </form>
  );
};
