import http, { getFormData } from "../http/httpService";

class ImagesService {
  async upload(createImageInput) {
    const formData = getFormData(createImageInput);

    let result = await http.post("api/services/app/Images/Upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Images/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Images/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  getImageUrl(imageId) {
    let url = `${process.env.REACT_APP_REMOTE_SERVICE_BASE_URL}/api/services/app/Images/Index?imageId=${imageId}`;
    return url;
  }

  getImageUrlByName(imageName) {
    let url = `${process.env.REACT_APP_REMOTE_SERVICE_BASE_URL}/api/services/app/Images/GetByName?imageName=${imageName}`;
    return url;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Images/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async updateNameById(updateImageInput) {
    let result = await http.put(
      "api/services/app/Images/UpdateNameById",
      {},
      {
        params: updateImageInput,
      }
    );

    return result.data.result;
  }

  async update(updateImageInput) {
    if (updateImageInput?.file?.image) {
      updateImageInput.file = updateImageInput.file.image;
    }

    const formData = new FormData();

    formData.append("file", updateImageInput.file);
    formData.append("imageName", updateImageInput.imageName);

    let result = await http.put("api/services/app/Images/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    await this.updateNameById({
      imageId: updateImageInput.id,
      imageName: updateImageInput.imageName,
    });
    return result.data.result;
  }

  async updateByImageName(updateImageInput) {
    const formData = getFormData(updateImageInput, true);

    let result = await http.put(
      "api/services/app/Images/UpdateImageByName",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result.data.result;
  }
}

export default new ImagesService();
