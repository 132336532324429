import { imagePreviewShowBody } from "../../components/crudTable/crudTableUtils";

const fieldsToShow = [
  {
    header: "Image",
    name: "logoImage",
    type: "image",
  },
  {
    header: "Club Name",
    name: "clubName",
  },
  {
    header: "Store Platform",
    name: "storePlatform",
  },
  {
    header: "Mobile App",
    name: "mobileAppName",
  },
];

const fieldsToEdit = [
  {
    title: "Club Name",
    name: "clubName",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Logo Image",
    name: "logoImage",
    type: "image",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "",
    name: "logoImage",
    body: imagePreviewShowBody,
  },
  {
    title: "OfficialClubImage",
    name: "officialClubLogoImage",
    type: "image",
  },
  {
    title: "",
    name: "officialClubLogo",
    body: imagePreviewShowBody,
  },
  {
    title: "Background Image",
    name: "backgroundImage",
    type: "image",
  },
  {
    title: "",
    name: "backgroundImage",
    body: imagePreviewShowBody,
  },
  {
    title: "Support Your team image",
    name: "supportYourTeamImage",
    type: "image",
  },
  {
    title: "",
    name: "supportYourTeamImage",
    body: imagePreviewShowBody,
  },
  {
    title: "Get Rewards Image",
    name: "getRewardsImage",
    type: "image",
  },
  {
    title: "",
    name: "getRewardsImage",
    body: imagePreviewShowBody,
  },
  {
    title: "Large background image",
    name: "largeBackgroundImage",
    type: "image",
  },
  {
    title: "",
    name: "largeBackgroundImage",
    body: imagePreviewShowBody,
  },
  {
    title: "Order your card image",
    name: "orderYourCardImage",
    type: "image",
  },
  {
    title: "",
    name: "orderYourCardImage",
    body: imagePreviewShowBody,
  },
  {
    title: "DownLoadAppImage",
    name: "downLoadAppImage",
    type: "image",
  },
  {
    title: "",
    name: "downLoadAppImage",
    body: imagePreviewShowBody,
  },
  {
    title: "Default Language Locale",
    name: "defaultLang",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Referral Page Url",
    name: "referralPageUrl",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp Server",
    name: "smtpServer",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp Port",
    name: "smtpPort",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp User",
    name: "smtpUser",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp Password",
    name: "smtpPassword",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp From",
    name: "smtpFrom",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Smtp From Display",
    name: "smtpFromDisplay",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Store Platform",
    name: "storePlatform",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "Mobile App",
    name: "mobileAppName",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "OnlineStoreUrl",
    name: "onlineStoreUrl",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "OnlineStoreApikey",
    name: "onlineStoreApikey",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "ClientId",
    name: "clientId",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "ClientSecret",
    name: "clientSecret",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "UrlAppStore",
    name: "urlAppStore",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "UrlPlayStore",
    name: "urlPlayStore",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
  {
    title: "CardNumberPart",
    name: "cardNumberPart",
    type: "string",
    permission: "Pages.Club.ClubDataManagement",
  },
];

const fieldsToCreate = [
  {
    title: "Club Name",
    name: "clubName",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Logo Image",
    name: "logoImage",
    type: "image",
    rules: {
      required: "This is required",
    },
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
