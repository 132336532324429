import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/StaffTransactions.scss";
import cardsService from "../../services/cards/cardsService";
import transactionsService from "../../services/transactions/transactionsService";
import { Controller, useForm } from "react-hook-form";
import { useUser } from "../../hooks/user/useUser";
import { Toast } from "primereact/toast";
import { DataView } from "primereact/dataview";
import { v4 as uuidv4 } from "uuid";
import { sortByProperty } from "../../components/crudTable/crudTableUtils";
import CurrencyInput from "../../components/currency/CurrencyInput";
import CrudTable from "../../components/crudTable/CrudTable";
import { fieldsToShow } from "../Transactions/transactionsConfig";
import ThemeContext from "../../context/theme/ThemeContext";
import { formatCardNumber, getCardNumber } from "../../utils/cardsUtils";
import BarcodeReader from "../../components/barcodeReader/BarcodeReader";

let timer = 0;

const StaffTransaction = () => {
  const toast = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [products, setProducts] = useState([]);
  const [scanning, setScanning] = useState(false);
  const { register, handleSubmit, control, reset } = useForm();
  const user = useUser();
  const { colorScheme } = useContext(ThemeContext);

  useEffect(() => {
    getPreviousTransactions();
  }, []);

  useEffect(() => {
    setSuggestions([]);

    if (filterValue === "") {
      setSelectedCard(null);
      return;
    }

    setCardsLoading(true);
    cardsService
      .getAll({
        Keyword: getCardNumber(filterValue),
        MaxResultCount: 10,
      })
      .then((response) => {
        setSuggestions(
          response.items.filter((x) => x?.cardNumber?.length <= 16)
        );
        setCardsLoading(false);
      });
  }, [filterValue]);

  const getPreviousTransactions = () => {
    transactionsService
      .getAll({
        MaxResultCount: 5,
      })
      .then((response) => {
        const items = sortByProperty(response.items, "datePaid", true);

        setTransactions(items);
      });
  };

  const onSuggestionClick = (card) => {
    setSuggestions([]);
    setInputValue(formatCardNumber(card.cardNumber));
    setSelectedCard(card);
  };

  const onSubmit = (data) => {
    let productsNames = products.map((i) => i.name);
    let description =
      productsNames.length !== 0 ? productsNames.join(",") : data.description;
    setLoading(true);
    if (!selectedCard) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "You need to select a card",
      });
      setLoading(false);
      return null;
    }
    let numRegex = /-?\d+(?:\.\d+)?/;
    data.amount = data.amount.replace(/,/g, "");
    const match = data.amount.match(numRegex);
    const number = match ? parseFloat(match[0]) : null;

    const request = {
      cardNumber: getCardNumber(selectedCard.cardNumber),
      paid: true,
      orderTotal: number,
      transactionType: user.partner ? 1 : user.club ? 2 : 0,
      clubId: user.club?.id,
      partnerId: user.partner?.id,
      description: description,
    };

    transactionsService
      .create(request)
      .then((response) => {
        if (response.cardTransactionId || response.clubTransactionLogId) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "You have successfuly created a transaction",
          });

          setProducts([]);
          setSelectedCard(null);
          setInputValue("");
          setFilterValue("");

          reset({
            amount: 0,
            cardNumber: "",
          });

          getPreviousTransactions();
          setLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "There was an error creating the transaction",
          });
          setLoading(false);
          return null;
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There was an error creating the transaction",
        });
        setLoading(false);
      });
  };

  const itemTemplate = (product) => {
    return (
      <div
        style={{
          width: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 20px",
        }}
        className="text-2xl"
      >
        <div className="">
          <div className="text-2xl font-bold text-900">{product.name}</div>
        </div>
        <div className="">
          <i
            className="fa fa-xmark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setProducts((prev) => prev.filter((i) => i.id !== product.id));
            }}
          />
        </div>
      </div>
    );
  };

  function handleTextareaKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault(); // prevent default newline behavior
      const textareaValue = event.target.value.trim();
      if (textareaValue !== "") {
        // add item only if not empty
        setProducts((prevArray) => [
          ...prevArray,
          {
            id: uuidv4(),
            name: textareaValue,
          },
        ]);
        event.target.value = ""; // clear the textarea
      }
    }
  }

  function debounce(func, timeout = 900) {
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const onInputValueChange = debounce((e) => {
    setFilterValue(e);
  });

  const onBarcodeMatch = (result) => {
    setScanning(false);
    setFilterValue(result);
    setInputValue(result)
  };

  return user.partner?.id !== undefined || user.club?.id !== undefined ? (
    <div
      className={`card staffTransactions ${
        colorScheme === "dark" ? "staffTransactionsDark" : ""
      }`}
    >
      <Toast ref={toast} />
      <div className="header">
        <h3>
          {user.partner && (
            <span>
              <img
                src={`data:image/png;base64,${user.partner?.logoImage?.contents}`}
                alt="partner logo"
              />
            </span>
          )}
          Create a transaction{" "}
        </h3>
      </div>
      <div className="mainContainer">
        <form className="left" onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <label htmlFor="cardNumber">Card Number</label>
            <div className="cardInput">
              <i
                className={`fa ${
                  cardsLoading ? "fa-spinner spinner" : "fa fa-barcode"
                }`}
                onClick={() => setScanning(true)}
              ></i>
              <input
                className="cardInputField"
                id="cardNumber"
                type="text"
                onChange={(e) => {
                  setInputValue(e.target.value);
                  onInputValueChange(e.target.value);
                }}
                value={inputValue}
                disabled={loading}
                placeholder="1224-1234-1234-1234"
                maxLength={16}
              ></input>
            </div>
            <ul className="suggestionList">
              {suggestions.length > 0
                ? suggestions.map((suggestion, index) => (
                    <li key={suggestion?.id}>
                      <img
                        src={suggestion?.cardType?.image?.imageUrl || `data:image/png;base64,${suggestion?.cardType?.image?.contents}`}
                        alt="cardImage"
                      />
                      <p onClick={() => onSuggestionClick(suggestion)}>
                        {formatCardNumber(suggestion?.cardNumber)} -{" "}
                        {suggestion?.fullName}
                      </p>
                    </li>
                  ))
                : inputValue &&
                  !selectedCard &&
                  !cardsLoading && (
                    <React.Fragment>
                      {cardsLoading ? (
                        <li>No cards found...</li>
                      ) : (
                        <li>Loading...</li>
                      )}
                    </React.Fragment>
                  )}
            </ul>
          </div>
          {scanning && (
            <BarcodeReader onBarcodeMatch={onBarcodeMatch} length={16} />
          )}
          <div className="input">
            <label htmlFor="description">
              Description <span>(press Enter to add)</span>
            </label>
            <textarea
              id="description"
              cols="30"
              rows="10"
              disabled={loading}
              placeholder="Write a short description here"
              {...register("description")}
              onKeyDown={handleTextareaKeyDown}
            ></textarea>
          </div>
          <DataView value={products} itemTemplate={itemTemplate} rows={5} />

          <div className="input">
            <label>Amount</label>

            <Controller
              name="amount"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CurrencyInput
                  onChange={field.onChange}
                  placeholder={"Amount"}
                  value={field.value}
                />
              )}
            ></Controller>
          </div>

          <button className="submitButton" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Create Transaction"}
          </button>
        </form>
        <div className="right">
          {selectedCard ? (
            <React.Fragment>
              <h3>
                <i className="fa fa-user"></i>
                {selectedCard?.fullName}
              </h3>
              <h3>
                <i className="fa fa-user"></i>
                {selectedCard?.balance ?? 0} points
              </h3>
              <img
                src={selectedCard?.cardType?.image?.imageUrl || `data:image/jpeg;base64,${selectedCard?.cardType?.image?.contents}`}
                alt="cardTypeImage"
              />
            </React.Fragment>
          ) : (
            <div>
              <h4>No card chosen</h4>
            </div>
          )}
        </div>
      </div>
      <div className="previousTransactions">
        <CrudTable
          data={transactions}
          fieldsToShow={fieldsToShow}
          loading={loading}
          headerTitle="Manage Transactions"
          setLoading={setLoading}
          lazy={true}
          lazyParams={{
            first: 0,
            rows: 5,
          }}
          canDelete={false}
          canEdit={false}
          hideHeader={true}
          canCreate={false}
        />
      </div>
    </div>
  ) : (
    <div className="card">
      <h2>You have to be part of either a club or a partner to make transaction</h2>
    </div>
  );
};

export default StaffTransaction;
