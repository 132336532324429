import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./ActivateCard.scss";
import cardTypesService from "../../services/cardTypes/cardTypesService";
import { Skeleton } from "primereact/skeleton";
import cardsService from "../../services/cards/cardsService";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import clubsService from "../../services/club/clubService";
import { useUser } from "../../hooks/user/useUser";
import { useQuery } from "@tanstack/react-query";
import { TranslationPaging } from "../../components/crudTable/CrudTable";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import ActivateDialog from "./ActivateDialog";
import { defaultImage } from "../../components/crudTable/crudTableUtils";
import cardBatchesService from "../../services/cardBatches/cardBatchesService";
import { Dropdown } from "primereact/dropdown";

const ActivateCard = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [totalSelectionCount, setTotalSelectionCount] = useState(25);
  const user = useUser();
  const { data: clubsItems, isLoading: clubsLoading } = useQuery({
    queryKey: ["clubs"],
    queryFn: () => clubsService.getAll({ HasNoPaginator: true }),
  });

  let clubs = clubsItems?.items || [];

  const [selectedClub, setSelectedClub] = useState(null);
  const { data: cardTypesItems } = useQuery({
    queryKey: ["cardTypes", selectedClub],
    enabled: Boolean(selectedClub || user.clubId),
    queryFn: () =>
      cardTypesService.getAll({
        clubId: selectedClub || user.clubId,
      }),
  });
  let cardTypes = useMemo(() => {
    const items = cardTypesItems?.items || [];
    return items.filter((i) => !i.virtual);
  }, [cardTypesItems]);

  const [selectedCardTypeId, setSelectedCardTypeId] = useState(null);
  const [filter, setFilter] = useState("");
  const {
    data: optionsItems,
    isLoading: cardsLoading,
    refetch: refetchCards,
  } = useQuery({
    queryKey: ["activateCards", filter, selectedCardTypeId, first, rows],
    enabled: !!selectedCardTypeId,
    queryFn: () =>
      cardsService.getAll({
        Keyword: filter,
        CardTypeId: selectedCardTypeId,
        IsNotActivated: true,
        Sorting: "OrderedOn",
        MaxResultCount: rows,
        HasMapping: true,
        Light: true,
        SkipCount: first,
        IsNotInBatch: true,
      }),
  });

  let totalCount = optionsItems?.totalCount || 0;
  let cards = optionsItems?.items ? optionsItems?.items : [];

  const [showDialog, setShowDialog] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleImageClick = (imageId) => {
    setSelectedCardTypeId(imageId);
  };

  useEffect(() => {
    if (user.clubId) {
      setSelectedClub(user.clubId);
    }
  }, [user.clubId]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleStartBtnClicked = async () => {
    setDialogLoading(true);
    let response = await cardsService.getAll({
      Keyword: filter,
      CardTypeId: selectedCardTypeId,
      IsNotActivated: true,
      Sorting: "OrderedOn",
      HasMapping: true,
      MaxResultCount: totalSelectionCount,
      Light: true,
    });
    let cards = response?.items ? response?.items : [];
    setSelectedCards(cards);
    setShowDialog(true);
    setDialogLoading(false);
  };

  return (
    <div className="card">
      <div className="activate-card-container">
        {!user.clubId && (
          <Fragment>
            <h1>Select club: </h1>
            <div className="image-grid">
              {!clubsLoading
                ? clubs.map((club) => (
                    <div
                      key={club.id}
                      className={`image-club-container ${
                        selectedClub === club.id ? "selected" : ""
                      }`}
                      onClick={() => setSelectedClub(club.id)}
                    >
                      <img
                        src={club?.logoImage?.imageUrl || defaultImage}
                        className="image-item"
                        alt="club logo"
                      />
                    </div>
                  ))
                : [...Array(6)].map((fakeClub) => (
                    <div className="image-container" key={fakeClub}>
                      <Skeleton
                        width="250px"
                        height="140.6px"
                        className="mb-2 image-item"
                      ></Skeleton>
                    </div>
                  ))}
            </div>
          </Fragment>
        )}
        {selectedClub && (
          <Fragment>
            <h1>Select card type:</h1>
            <div className="image-grid">
              {!cardTypes.length < 1
                ? cardTypes.map((type) => (
                    <div
                      key={type.id}
                      className={`image-container ${
                        selectedCardTypeId === type.id ? "selected" : ""
                      }`}
                      onClick={() => handleImageClick(type.id)}
                    >
                      <div className="image-item">
                        <img
                          src={type.image?.imageUrl || defaultImage}
                          alt={type.displayName}
                        />
                      </div>
                    </div>
                  ))
                : [...Array(3)].map((fakeType) => (
                    <div key={fakeType} className="image-container">
                      <Skeleton
                        width="250px"
                        height="140.6px"
                        className="mb-2 image-item"
                      ></Skeleton>
                    </div>
                  ))}
            </div>
          </Fragment>
        )}

        {selectedCardTypeId && (
          <>
            <h2>User details:</h2>

            <div className="cards-actions">
              <InputText
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="name-input"
                placeholder="Full Name"
              />
              <InputNumber
                value={totalSelectionCount}
                onValueChange={(e) => setTotalSelectionCount(e.value)}
                showButtons
                buttonLayout="horizontal"
                step={10}
                min={0}
                decrementButtonClassName="p-button-secondary"
                incrementButtonClassName="p-button-secondary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
              <Button
                className="p-button-info"
                disabled={dialogLoading || !cards || totalSelectionCount <= 0}
                loading={dialogLoading}
                label="Start"
                onClick={handleStartBtnClicked}
              />
            </div>

            <Dialog
              header="Activate Cards"
              visible={showDialog}
              style={{ width: "50vw" }}
              onHide={() => {
                setShowDialog(false);
                refetchCards();
              }}
            >
              <ActivateDialog
                cards={selectedCards}
                refetchCards={refetchCards}
                setShowDialog={setShowDialog}
                cardType={cardTypes.find((i) => i.id === selectedCardTypeId)}
              />
            </Dialog>

            <DataTable
              value={cards}
              tableStyle={{ minWidth: "50rem" }}
              loading={cardsLoading}
              dataKey="id"
              paginator
              lazy={true}
              rowsPerPageOptions={[5, 25, 50, 100]}
              currentPageReportTemplate={TranslationPaging(
                "{first}",
                "{last}",
                "{totalRecords}"
              )}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              first={first}
              totalRecords={totalCount}
              rows={rows}
              onPage={onPageChange}
            >
              <Column field="cardNumber" header="Card Number"></Column>
              <Column field="fullName" header="Name"></Column>
              <Column
                field="orderedOn"
                header="Ordered On"
                body={(rowData) => (
                  <b>{new Date(rowData.orderedOn).toLocaleDateString()}</b>
                )}
              ></Column>
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivateCard;
