import React, { useState, useEffect, useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import { Outlet } from "react-router-dom";

import AppTopbar from "../components/menus/AppTopbar";
import AppRightPanel from "../components/menus/AppRightPanel";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../styles/App.scss";
import "../styles/CustomQuill.scss";

import ThemeContext from "../context/theme/ThemeContext";

/**
 * It renders the topbar, right panel, and the main content
 * @returns The MainLayout component is being returned.
 */
const MainLayout = () => {
  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const { menuMode } = useContext(ThemeContext);
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle] = useState("filled");
  const [ripple] = useState(false);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const { topbarScheme } = useContext(ThemeContext);
  const { menuScheme } = useContext(ThemeContext);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);

  const menu = [
    {
      label: "Home",
      icon: "pi pi-home",
      to: "/",
    },
    {
      label: "Login",
      icon: "pi pi-user",
      to: "/login",
      auth: false,
    },
    {
      label: "Staff",
      icon: "fa fa-cart-shopping",
      to: "/staff",
      permission: "Pages.StaffTransactions",
    },
    {
      label: "Wizard",
      icon: "fa-solid fa-wand-magic-sparkles",
      to: "/wizard",
      permission: "Pages.Club.Wizard",
    },
    {
      label: "Management",
      icon: "pi pi-users",
      items: [
        {
          label: "Users",
          icon: "pi pi-users",
          to: "/users",
          permission: "Pages.Users",
        },
        {
          label: "Tenants",
          icon: "pi pi-calendar",
          to: "/tenants",
          permission: "Pages.Tenants",
        },
        {
          label: "Roles",
          icon: "pi pi-calendar",
          to: "/roles",
          permission: "Pages.Roles",
        },
        {
          label: "Translations",
          icon: "pi pi-bolt",
          to: "/translations",
          permission: "Pages.Translations",
        },
        {
          label: "Database Synchronizer",
          icon: "fa fa-rotate",
          to: "/databaseSynchronizer",
          permission: "Pages.DatabaseSynchronize.Sync",
        },
        {
          label: "Environments",
          icon: "pi pi-home",
          to: "/environments",
          permission: "Pages.Environments",
        },
        {
          label: "Admin Pages",
          icon: "fa-solid fa-hammer",
          to: "/clubAdmins",
        },
        {
          label: "Partner Applications",
          icon: "fa-solid pi-shopping-bag",
          to: "/partnerApplication",
          permission: "Pages.PartnerApplications"
        },
        {
          label: "Languages",
          icon: "pi pi-flag",
          to: "/languages",
          permission: "Pages.Languages",
        },
      ],
    },
    {
      label: "Content",
      icon: "pi pi-desktop",
      to: "/screens",
      items: [
        {
          label: "Screens",
          icon: "pi pi-desktop",
          to: "/screens",
          permission: "Pages.ScreenLayouts",
        },
        {
          label: "Static Content",
          icon: "pi pi-align-center",
          to: "/static",
          permission: "Pages.StaticContents",
        },
        {
          label: "Notifications",
          icon: "pi pi-envelope",
          to: "/notifications",
          permission: "Pages.Notifications",
        },
        {
          label: "Tickets",
          icon: "pi pi-ticket",
          to: "/tickets",
          permission: "Pages.Dynamics365Tickets",
        },
        // {
        //   label: "Onboardings",
        //   icon: "pi pi-compass",
        //   to: "/onboarding",
        //   permission: "Pages.Onboarding",
        // },
        {
          label: "Prizes",
          icon: "pi pi-shopping-bag",
          to: "/prizes/",
          permission: "Pages.Prizes",
        },
        {
          label: "Campaigns",
          icon: "pi pi-check-square",
          to: "/campaigns",
          permission: "Pages.Campaigns",
        },
      ],
    },
    {
      label: "Cards",
      icon: "pi pi-paperclip",
      to: "/cards",
      items: [
        {
          label: "Card Types",
          icon: "pi pi-qrcode",
          to: "/card-types",
          permission: "Pages.CardTypes",
        },
        {
          label: "Cards",
          icon: "pi pi-paperclip",
          to: "/cards",
          permission: "Pages.Cards",
        },
        {
          label: "Activate Cards",
          icon: "fa-solid fa-play",
          to: "/activateCard",
          permission: "Pages.Card.Activate",
        },
        {
          label: "Transactions",
          icon: "pi pi-share-alt",
          to: "/transactions",
          permission: "Pages.Transactions",
        },
        {
          label: "Card Batches",
          icon: "pi pi-book",
          to: "/batches",
          permission: "Pages.CardsBatches",
        },
      ],
    },
    {
      label: "Media",
      icon: "pi pi-images",
      to: "/images",
      permission: "Pages.Images",
      items: [
        {
          label: "Images",
          icon: "pi pi-image",
          to: "/images",
          permission: "Pages.Images",
        },
        {
          label: "Videos",
          icon: "pi pi-camera",
          to: "/videos",
          permission: "Pages.Videos",
        },
      ],
    },
    {
      label: "Partners",
      icon: "pi pi-users",
      to: "/partners",
      permission: "Pages.Partners",
    },
    {
      label: "Clubs",
      icon: "pi pi-id-card",
      to: "/clubs",
      permission: "Pages.Clubs",
    },
    {
      label: "PartnerClubMapping",
      icon: "fa-solid fa-handshake",
      to: "/partnerclubmapping",
      permission: "Pages.PartnersClubMapping",
    },
    {
      label: "Settings",
      icon: "fa fa-gear",
      items: [
        {
          label: "Team",
          to: "/settings/team",
        },
      ],
    },
  ];

  let menuClick;
  let rightPanelClick;
  let configClick;
  let searchClick;
  let topbarUserMenuClick;

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true);
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === "layout-menu-container") {
      setResetActiveIndex(true);
      setMenuActive(false);
    }
    menuClick = true;
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setRightPanelActive(false);

    if (isMobile()) {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const onRightPanelClick = () => {
    rightPanelClick = true;
  };

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState);
    rightPanelClick = true;
  };

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState);
    searchClick = true;
  };

  const onTopbarSearchClick = () => {
    searchClick = true;
  };

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState);
    topbarUserMenuClick = true;
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false);
      searchClick = false;
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false);
      topbarUserMenuClick = false;
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false);
    }

    if (!configClick && configActive) {
      setConfigActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true);
        setMenuActive(false);
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    searchClick = false;
    topbarUserMenuClick = false;
    rightPanelClick = false;
    configClick = false;
    menuClick = false;
  };

  const onSidebarMouseOver = () => {
    setSidebarActive(!isMobile());
  };

  const onSidebarMouseLeave = () => {
    setSidebarActive(false);
  };

  const onToggleMenu = (event) => {
    menuClick = true;
    setSidebarStatic((prevState) => !prevState);

    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const layoutClassName = classNames(
    "layout-wrapper",
    {
      "layout-sidebar": menuMode === "sidebar",
      "layout-static": menuMode === "sidebar" && sidebarStatic,
      "layout-horizontal": menuMode === "horizontal",
      "layout-rightpanel-active": rightPanelActive,
      "layout-slim": menuMode === "slim",
      "layout-mobile-active": staticMenuMobileActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
    },
    "layout-menu-" + menuScheme + " layout-topbar-" + topbarScheme
  );

  const mainContainerStyles = {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("${process.env.PUBLIC_URL}/images/background.jpeg")
      no-repeat`,
  };

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <AppTopbar
        topbarScheme={topbarScheme}
        onRightPanelButtonClick={onRightPanelButtonClick}
        searchActive={searchActive}
        onTopbarSearchToggle={onTopbarSearchToggle}
        onTopbarSearchClick={onTopbarSearchClick}
        topbarUserMenuActive={topbarUserMenuActive}
        onTopbarUserMenuClick={onTopbarUserMenuClick}
        menu={menu}
        menuActive={menuActive}
        onRootMenuItemClick={onRootMenuItemClick}
        mobileMenuActive={staticMenuMobileActive}
        onMenuItemClick={onMenuItemClick}
        menuMode={menuMode}
        sidebarStatic={sidebarStatic}
        sidebarActive={sidebarActive}
        onSidebarMouseOver={onSidebarMouseOver}
        onSidebarMouseLeave={onSidebarMouseLeave}
        onToggleMenu={onToggleMenu}
        onMenuButtonClick={onMenuButtonClick}
        resetActiveIndex={resetActiveIndex}
        onMenuClick={onMenuClick}
      />

      <div className="layout-main" style={{ ...mainContainerStyles }}>
        <div className="layout-content">
          <Outlet />
        </div>
      </div>

      <div className="layout-mask modal-in"></div>
    </div>
  );
};

export default MainLayout;
