import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import clubAdminsService from "../../services/clubAdmins/clubAdminsService";
import { useErrors } from "../../utils/error/useErrors";
import clubService from "../../services/club/clubService";

const ClubAdmins = () => {
  const [clubAdmins, setClubAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [clubs, setClubs] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    clubService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setClubs(res.items);
      });
  }, []);

  useEffect(() => {
    getAllClubAdmins();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllClubAdmins = () => {
    setLoading(true);
    clubAdminsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((clubAdmins) => {
        setClubAdmins(clubAdmins.items);
        setLazyParams({ ...lazyParams, totalRecords: clubAdmins.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    clubAdminsService
      .update(data)
      .then(() => {
        getAllClubAdmins();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDeleteSubmit = (data) => {
    clubAdminsService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllClubAdmins();
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    clubAdminsService
      .create(data)
      .then((res) => {
        getAllClubAdmins();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleClubAdmin = async (id) => {
    const batchData = await clubAdminsService.get({ id });
    return batchData;
  };

  const fieldsToShow = [
    {
      header: "Url",
      name: "adminUrl",
    },
    {
      header: "Id",
      name: "clubId",
    },
  ];

  const fieldsToEdit = [
    {
      title: "Url",
      name: "adminUrl",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
    {
      name: "clubId",
      title: "Club",
      type: "dropdown",
      rules: { required: "Club is required" },
      props: {
        options: clubs,
        optionLabel: "clubName",
        optionValue: "id",
        placeholder: "Select club...",
      },
    },
  ];

  const fieldsToCreate = [
    {
      title: "Url",
      name: "adminUrl",
      type: "string",
      rules: {
        required: "Title is required",
      },
    },
    {
      name: "clubId",
      title: "Club",
      type: "dropdown",
      rules: { required: "Club is required" },
      props: {
        options: clubs,
        optionLabel: "clubName",
        optionValue: "id",
        placeholder: "Select club...",
      },
    },
  ];

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={clubAdmins}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Admin Pages"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleClubAdmin}
        onEditSubmit={onEditSubmit}
        onDelete={onDeleteSubmit}
        onCreateSubmit={onCreateSubmit}
        createPermission={"Pages.ClubAdmins.Create"}
        deletePermission={"Pages.ClubAdmins.Delete"}
        editPermission={"Pages.ClubAdmins.Edit"}
      />
    </div>
  );
};

export default ClubAdmins;
