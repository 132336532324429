import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import videosService from "../../services/videos/videosService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./videosConfig";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllVideos();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllVideos = () => {
    setLoading(true);
    videosService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((clubs) => {
        setVideos(clubs.items);
        setLazyParams({ ...lazyParams, totalRecords: clubs.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    videosService
      .update(data)
      .then((res) => {
        getAllVideos();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    videosService
      .upload(data)
      .then((res) => {
        getAllVideos();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    videosService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllVideos();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleImage = async (id) => {
    const imageData = await videosService.get({ id });
    return imageData;
  };

  return (
    <div>
      <CrudTable
        data={videos}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Videos"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        primaryKey="id"
        getSingleEntity={getSingleImage}
        createPermission="Pages.Video.Create"
        editPermission={"Pages.Video.Edit"}
        deletePermission={"Pages.Video.Delete"}
        onRowDoubleClick={(e) => window.open(e.data?.videoUrl, "_blank")}
      />
    </div>
  );
};

export default Videos;
