import { useState, useRef } from "react";
import imagesService from "../../../services/images/imagesService";
import { ProgressSpinner } from "primereact/progressspinner";
import "./ImageVisualizer.scss";

export const ImageVisualizer = ({ imageName }) => {
  const uploadRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    let image = e.target.files?.[0];

    if (!image) return;

    if (!image.type.startsWith("image/")) {
      setError("File must be an image");
      return;
    }

    setLoading(true);
    imagesService
      .updateByImageName({ file: image, imageName })
      .then((res) => {
        setLoading(false);
        setUploadedImage(URL.createObjectURL(image));
      })
      .catch((res) => {
        alert("An error occured");
        setLoading(false);
      });
  };

  return !loading ? (
    <div>
      <div
        className="imageVisualizer"
        onClick={() => uploadRef.current.click()}
      >
        <img
          style={{ maxWidth: "200px", objectFit: "contain" }}
          src={uploadedImage || imagesService.getImageUrlByName(imageName)}
          alt={imageName}
        />
        <div className="preview">
          <i className="pi pi-eye" />
        </div>
      </div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <input
        type="file"
        ref={uploadRef}
        onChange={(e) => handleFileChange(e)}
        style={{ visibility: "hidden" }}
      />
    </div>
  ) : (
    <ProgressSpinner />
  );
};
