import React, { useCallback, useContext, useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import { ListBox } from "primereact/listbox";
import usersService from "../../services/users/usersService";
import { Controller, useForm } from "react-hook-form";
import rolesService from "../../services/roles/rolesService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import authenticationService from "../../services/authentication/authenticationService";
import { useErrors } from "../../utils/error/useErrors";
import partnersService from "../../services/partners/partnersService";
import clubsService from "../../services/club/clubService";
import { Dropdown } from "primereact/dropdown";
import UserContext from "../../context/user/userContext";
import { L } from "../../utils/abpUtility";
import { InputSwitch } from "primereact/inputswitch";

let abp = window.abp;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [rolesForInvite, setRolesForInvite] = useState([]);
  const [roles, setRoles] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState(null);
  const [club, setClub] = useState(null);
  const [showPartner, setShowPartner] = useState(false);
  const [showClub, setShowClub] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const { register, handleSubmit, control } = useForm();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const { checkErrors } = useErrors();
  const { user, refresh } = useContext(UserContext);
  let timer;

  useEffect(() => {
    setShowClub(!!selectedEntity?.clubId);
    setShowPartner(!!selectedEntity?.partnerId);
  }, [selectedEntity]);

  useEffect(() => {
    getAllUsers();
  }, [lazyParams.first, lazyParams.rows, globalFilter, partner, club]);

  useEffect(() => {
    partnersService
      .getAll({
        hasNoPaginator: true,
        hasNoImages: true,
        sorting: "companyName%20ASC",
      })
      .then((res) => {
        setPartners(res.items);
      });
  }, []);

  useEffect(() => {
    clubsService
      .getAll({
        hasNoPaginator: true,
        hasNoImages: true,
        sorting: "clubName%20ASC",
      })
      .then((res) => {
        setClubs(res.items);
      });
  }, []);

  useEffect(() => {
    if (user) {
      getAllRoles();
    }
  }, [user]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllUsers = () => {
    setLoading(true);
    usersService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        PartnerId: partner,
        ClubId: club,
      })
      .then((users) => {
        setUsers(
          users.items.map((user) => {
            return {
              ...user,
              userPartnerClub: user.usersPartnersClubs?.[0] || {},
            };
          })
        );
        setLazyParams({ ...lazyParams, totalRecords: users.totalCount });
        rolesService.getAll().then((roles) => {
          setRoles(roles.items);
          setLoading(false);
        });
      });
  };

  const getAllRoles = useCallback(() => {
    rolesService
      .getRoles({
        Partner: user.partnerId != null,
        club: user.clubId != null,
      })
      .then((roles) => {
        setRolesForInvite(roles.items);
      });
  }, [user]);

  const onEditSubmit = (data) => {
    data.usersPartnersClubs = data.usersPartnersClubs?.[0]
      ? data.usersPartnersClubs.map((i) => {
          return {
            ...i,
            partnerId: showPartner ? data.partnerId : null,
            clubId: showClub ? data.clubId : null,
          };
        })
      : [
          {
            partnerId: showPartner ? data.partnerId : null,
            clubId: showClub ? data.clubId : null,
          },
        ];
    usersService.update(data).then((res) => {
      getAllUsers();
      refresh();
      setLoading(false);
    });
  };

  const onCreateSubmit = (data) => {
    usersService.create(data).then((res) => {
      getAllUsers();
      setLoading(false);
      refresh();
    });
  };

  const onDelete = (data) => {
    usersService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllUsers();
        setLoading(false);
        refresh();
      });
  };

  const rolesEditBody = (data) => {
    return (
      <Controller
        name={data.name}
        control={data.control}
        rules={{ required: "You need to choose a role" }}
        render={({ field }) => (
          <ListBox
            options={roles}
            multiple={true}
            optionLabel="normalizedName"
            optionValue="normalizedName"
            filter
            {...field}
          />
        )}
      ></Controller>
    );
  };
  const getSingleUser = async (id) => {
    let userData = await usersService.get({ id });
    userData = {
      ...userData,
      partnerId: userData.usersPartnersClubs?.[0]?.partnerId,
      clubId: userData.usersPartnersClubs?.[0]?.clubId,
    };
    return userData;
  };

  const showRelation = (rowData) => {
    const entity = rowData.userPartnerClub;
    return (
      <div>
        <Button
          label={
            entity?.partner?.companyName || entity?.club?.clubName || "N/A"
          }
        />
      </div>
    );
  };

  const fieldsToShow = [
    {
      header: "Name",
      name: "name",
    },
    {
      header: "Surname",
      name: "surname",
    },
    {
      header: "Email",
      name: "emailAddress",
    },
    {
      header: "Is Active",
      name: "isActive",
      body: (rowData) => (
        <Button className="p-button-outlined p-button-danger">
          {rowData.isActive ? "true" : "false"}
        </Button>
      ),
    },
    {
      header: "Relation",
      name: "userPartnerClub",
      hidden: window.club,
      body: showRelation,
    },
  ];

  const partnerEditField = ({ data, name, control }) => {
    return (
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <div>
              <InputSwitch
                checked={showPartner}
                onChange={(e) => {
                  setShowPartner(e.value);
                  if (e.value) {
                    setShowClub(!e.value);
                  }
                }}
                className={"mb-1"}
              />
              {showPartner && (
                <Dropdown
                  {...field}
                  options={partners}
                  optionValue="id"
                  optionLabel="companyName"
                  placeholder={"Select a partner here"}
                  disabled={showClub}
                  showClear={true}
                  onChange={(e) => {
                    field.onChange(e.value ? e.value : null);
                  }}
                />
              )}
            </div>
          )}
        />
      </div>
    );
  };

  const clubEditField = ({ data, name, control }) => {
    return (
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <div>
              <InputSwitch
                checked={showClub}
                onChange={(e) => {
                  setShowClub(e.value);
                  if (e.value) {
                    setShowPartner(false);
                  }
                }}
                className={"mb-1"}
              />
              {showClub && (
                <Dropdown
                  {...field}
                  options={clubs}
                  optionValue="id"
                  optionLabel="clubName"
                  disabled={showPartner}
                  showClear={true}
                  onChange={(e) => {
                    field.onChange(e.value ? e.value : null);
                  }}
                  placeholder={"Select a club here"}
                />
              )}
            </div>
          )}
        />
      </div>
    );
  };

  const fieldsToEdit = [
    {
      name: "name",
      title: "First Name",
      type: "string",
      rules: { required: "Name is required" },
    },
    {
      name: "surname",
      title: "Surname",
      type: "string",
      rules: { required: "Surname is required" },
    },
    {
      name: "userName",
      title: "User Name",
      type: "string",
      rules: { required: "Username is required" },
    },
    {
      name: "isActive",
      hidden: !abp.auth.hasPermission("Pages.Users.Activation"),
      title: "Active",
      type: "bool",
    },
    {
      name: "emailAddress",
      title: "Email",
      type: "string",
      rules: { required: "Email is required" },
    },
    {
      name: "roleNames",
      title: "Roles",
      body: rolesEditBody,
    },
    {
      name: "partnerId",
      title: "Partner",
      body: partnerEditField,
    },
    {
      name: "clubId",
      title: "Club",
      body: clubEditField,
    },
  ];

  const fieldsToCreate = [
    {
      name: "name",
      title: "First Name",
      type: "string",
    },
    {
      name: "surname",
      title: "Surname",
      type: "string",
    },
    {
      name: "userName",
      title: "User Name",
      type: "string",
    },
    {
      name: "isActive",
      hidden: !abp.auth.hasPermission("Pages.Users.Activation"),
      title: "Active",
      type: "bool",
    },
    {
      name: "emailAddress",
      title: "Email",
      type: "string",
    },
    {
      name: "password",
      title: "Password",
      type: "password",
    },
    {
      name: "partnerId",
      title: "Partner",
      type: "dropdown",
      props: {
        options: partners,
        optionValue: "id",
        optionLabel: "companyName",
        placeholder: L("Select partner"),
      },
    },
    {
      name: "clubId",
      title: "Club",
      type: "dropdown",
      props: {
        options: clubs,
        optionValue: "id",
        optionLabel: "clubName",
        placeholder: L("Select club"),
      },
    },
    {
      name: "roleNames",
      title: "Roles",
      body: rolesEditBody,
    },
  ];

  const extendHeader = () => {
    return (
      <>
        {abp.auth.hasPermission("Pages.Users.Invite") ? (
          <Button
            label={L("Invite")}
            icon="pi pi-user"
            className="p-button-help ml-2"
            onClick={() => setOpenRegisterDialog(true)}
          />
        ) : null}
        {!(user.partnerId || user.clubId) && (
          <Dropdown
            options={partners}
            optionValue="id"
            className="mx-3"
            optionLabel="companyName"
            placeholder={L("Select partner")}
            value={partner}
            onChange={(e) => setPartner(e.value)}
            showClear
          />
        )}
        {!(user.partnerId || user.clubId) && (
          <Dropdown
            options={clubs}
            optionValue="id"
            optionLabel="clubName"
            placeholder={L("Select club")}
            value={club}
            onChange={(e) => setClub(e.value)}
            showClear
          />
        )}
      </>
    );
  };

  const createRegistrationFooter = () => (
    <div className="align-items-center justify-content-end flex ">
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        disabled={formLoading}
        loading={formLoading}
        type="submit"
      />
    </div>
  );

  const generateUrl = (id) => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}${process.env.PUBLIC_URL}/verification/email/${id}`;
    return url;
  };

  const onSubmit = (data) => {
    const baseUrl = window.location.origin;

    setFormLoading(true);
    authenticationService
      .registerUser({
        emailAddress: data.email,
        roleNames: data.roleNames,
        baseVerificationUrl: `${baseUrl}${process.env.PUBLIC_URL}/verification/email/`,
      })
      .then(({ userId }) => {
        setOpenRegisterDialog(false);
        const url = generateUrl(userId);
        navigator.clipboard.writeText(url);
        getAllUsers();
        setFormLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setFormLoading(false);
      });
  };

  return (
    <div>
      <CrudTable
        data={users}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Users"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        primaryKey="id"
        getSingleEntity={getSingleUser}
        extendHeader={extendHeader}
        updateSelectedEntity={setSelectedEntity}
        createPermission="Pages.Users.Create"
        editPermission={"Pages.Users.Update"}
        deletePermission={"Pages.Users.Delete"}
      />

      <Dialog
        visible={openRegisterDialog}
        style={{ width: "450px" }}
        header={`
          ${L("Invite user to ")}
          ${
            user.partner
              ? user.partner.companyName
              : user.club
              ? user.club.clubName
              : ""
          }
            `}
        modal
        onHide={() => setOpenRegisterDialog(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="field"
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <InputText
              {...register("email", { required: true, minLength: 5 })}
              type="email"
              disabled={formLoading}
              placeholder={L("Email address")}
            />

            <Controller
              name={"roleNames"}
              control={control}
              render={({ field }) => (
                <ListBox
                  options={rolesForInvite}
                  multiple={true}
                  optionLabel="displayName"
                  optionValue="displayName"
                  filter
                  disabled={formLoading}
                  {...field}
                />
              )}
            ></Controller>
          </div>
          {createRegistrationFooter()}
        </form>
      </Dialog>
    </div>
  );
};

export default Users;
