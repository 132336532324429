import http from "../http/httpService";

class Transactions {
  async get(entityDto) {
    let result = await http.get(
      "api/services/app/Transactions/GetById",
      {
        params: entityDto,
      }
    );
    return result.data.result;
  }

  async create(entityDto) {
    let result = await http.post(
      "api/services/app/Transactions/Create",
      entityDto
    );
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Transactions/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new Transactions();
