import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import clubService from "../../services/club/clubService";
import { useErrors } from "../../utils/error/useErrors";
import onboardingService from "../../services/onboarding/onboardingService";
import { fieldsToShow, fieldsToEdit, fieldsToCreate } from "./onboardingConfig";
import { sortByProperty } from "../../components/crudTable/crudTableUtils";

const Onboarding = () => {
  const [onboarding, setOnboarding] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldsCreate, setFieldsCreate] = useState([]);
  const [fieldsEdit, setFieldsEdit] = useState([]);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    clubService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setFieldsCreate([
          ...fieldsToCreate,
          {
            name: "clubId",
            title: "Club",
            type: "dropdown",
            rules: { required: "Club is required" },
            props: {
              options: res.items,
              optionLabel: "clubName",
              optionValue: "id",
              placeholder: "Select club...",
            },
          },
        ]);
        setFieldsEdit([
          ...fieldsToEdit,
          {
            name: "clubId",
            title: "Club",
            type: "dropdown",
            rules: { required: "Club is required" },
            props: {
              options: res.items,
              optionLabel: "clubName",
              optionValue: "id",
              placeholder: "Select club...",
            },
          },
        ]);
      });
  }, []);

  useEffect(() => {
    getAllOnboarding();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const onEditSubmit = (data) => {
    onboardingService
      .update(data)
      .then(() => {
        getAllOnboarding();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getAllOnboarding = () => {
    setLoading(true);
    onboardingService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((onboarding) => {
        setOnboarding(sortByProperty(onboarding.items, "club.clubName"));
        setLazyParams({ ...lazyParams, totalRecords: onboarding.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    onboardingService
      .create(data)
      .then((res) => {
        getAllOnboarding();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    onboardingService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllOnboarding();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleOnboarding = async (id) => {
    const onb = await onboardingService.get({ id });
    return onb;
  };

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="image-text m-2">{data.club?.clubName}</span>
      </React.Fragment>
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={onboarding}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsEdit}
        fieldsToCreate={fieldsCreate}
        loading={loading}
        headerTitle="Manage Onboarding"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleOnboarding}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        editPermission="Pages.Onboarding.Edit"
        createPermission={"Pages.Onboarding.Create"}
        deletePermission={"Pages.Onboarding.Delete"}
        rowGroupMode="subheader"
        groupRowsBy="club.clubName"
        sortMode="single"
        sortField="club.clubName"
        sortOrder={1}
        expandableRowGroups
        rowGroupHeaderTemplate={headerTemplate}
      />
    </div>
  );
};

export default Onboarding;
