import React from "react";
import { v4 as uuidv4 } from "uuid";

const ClubLogin = ({ partners }) => {
  return (
    <div className="loginPartnersAside">
      {partners?.map((image) => (
        <img src={image} key={uuidv4()} alt="partnerImage" />
      ))}
    </div>
  );
};

export default ClubLogin;
