import React, { useEffect, useRef, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import partnersService from "../../services/partners/partnersService";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./partnersConfig";
import { Controller } from "react-hook-form";
import { ListBox } from "primereact/listbox";
import { useErrors } from "../../utils/error/useErrors";
import clubService from "../../services/club/clubService";
import { LanguageSelect } from "../../components/crudTable/crudTableUtils";

let previousLang = null;

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [supportedPayments, setSupportedPayments] = useState([]);
  const [fieldsToEditRevised, setEditFields] = useState(fieldsToEdit);
  const [fieldsToCreateRevised, setCreateFields] = useState(fieldsToCreate);
  const { checkErrors } = useErrors();
  const [lang, setLang] = useState(1);
  const ref = useRef();

  let timer;

  useEffect(() => {
    if (lang !== previousLang && previousLang !== null) {
      ref.current.refetch({ lang });
    }
  }, [lang]);

  useEffect(() => {
    partnersService.getSupportedPayments().then((res) => {
      setSupportedPayments(res.items);
    });

    clubService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setClubs(res.items);
      });
  }, []);

  useEffect(() => {
    getAllPartners();
  }, [lazyParams.first, lazyParams.rows, globalFilter, lazyParams.sortField]);

  const supportedPaymentsEditBody = ({ name, control, data }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={data.partnerSupportedPayment?.map((meth) => meth.id)}
        render={({ field }) => (
          <ListBox
            {...field}
            options={supportedPayments}
            optionLabel="paymentName"
            optionValue="id"
            multiple
          />
        )}
      />
    );
  };

  useEffect(() => {
    setEditFields([
      ...fieldsToEdit,
      ...[
        {
          title: "Supported Payment",
          name: "partnerSupportedPaymentNew",
          body: supportedPaymentsEditBody,
        },
        {
          title: "Partner Club Mapping",
          name: "partnerClubMappings",
          body: partnerClubMappingEditBody,
        },
      ],
    ]);

    setCreateFields([
      ...fieldsToCreate,
      ...[
        {
          title: "Supported Payment",
          name: "partnerSupportedPaymentNew",
          body: supportedPaymentsEditBody,
        },
      ],
    ]);
  }, [supportedPayments, clubs]);

  const ExtendDialog = ({ state }) => {
    return (
      state === "edit" && (
        <div className="pb-3">
          <h4>Select Language:</h4>
          <LanguageSelect
            value={lang}
            onChange={(e) => {
              previousLang = lang;
              setLang(e.target.value);
            }}
          />
        </div>
      )
    );
  };

  const partnerClubMappingEditBody = ({ control, name, data }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={data?.partnerClubMappings?.map((map) => {
          return {
            partnerId: map.partnerId,
            clubId: map.clubId,
          };
        })}
        render={({ field }) => (
          <ListBox
            {...field}
            options={clubs}
            optionLabel={"clubName"}
            optionValue={"id"}
            multiple
            filter
            value={field.value?.map((map) => {
              return map.clubId ?? map;
            })}
            onChange={(e) =>
              field.onChange(
                e.value?.map((map) => {
                  return {
                    clubId: map.clubId ?? map,
                    partnerId: data.id,
                  };
                })
              )
            }
          />
        )}
      />
    );
  };

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllPartners = () => {
    setLoading(true);
    partnersService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        Sorting: lazyParams.sortField || "",
      })
      .then((partners) => {
        setPartners(partners.items);
        setLazyParams({ ...lazyParams, totalRecords: partners.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    partnersService
      .update(data, lang)
      .then((res) => {
        getAllPartners();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    partnersService
      .create(data)
      .then((res) => {
        getAllPartners();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    partnersService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllPartners();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSinglePartner = async (id, options) => {
    const partnerData = await partnersService.get({ id, ...options, lang: options?.lang || lang });
    return partnerData;
  };

  const onSort = (field) => {
    setLazyParams({ ...lazyParams, sortField: field });
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={partners}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEditRevised}
        fieldsToCreate={fieldsToCreateRevised}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Partners"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSinglePartner}
        createPermission="Pages.Partner.Create"
        editPermission={"Pages.Partner.Edit"}
        deletePermission={"Pages.Partner.Delete"}
        extendDialog={ExtendDialog}
        onSort={onSort}
        ref={ref}
      />
    </div>
  );
};

export default Partners;
