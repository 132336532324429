import Lottie from "react-lottie-player";

const imageBody = (rowData) => {
  if (rowData?.fileName?.endsWith(".json")) {
    const jsonData = JSON.parse(rowData?.contents)
    return (
      <div>
        <Lottie
          loop
          animationData={jsonData}
          play
          style={{ width: 150, height: 150 }}
        />
      </div>
    );
  }
  return (
    <img
      src={rowData?.imageUrl || `data:image/jpeg;base64,${rowData?.contents}`}
      alt="images"
      style={{ maxWidth: "100px", borderRadius: "10px" }}
    />
  );
};

const fieldsToShow = [
  {
    header: "Image",
    name: "image",
    body: imageBody,
  },
  {
    header: "Name",
    name: "imageName",
  },
  {
    header: "File Name",
    name: "fileName",
  },
  {
    header: "Content Type",
    name: "contentType",
  },
];

const fieldsToEdit = [
  {
    name: "file",
    title: "Image",
    type: "imageCrop",
  },
  {
    name: "imageName",
    title: "Image Name",
    type: "string",
  },
  {
    name: "fileName",
    title: "File Name",
    type: "string",
  },
];

const fieldsToCreate = [
  {
    name: "file",
    title: "Image",
    type: "imageCrop",
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
