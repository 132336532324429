import { useNotification } from "../../hooks/notification/useNotification";

/**
 * It takes an error object and checks if it has a message or validationErrors property. If it does, it
 * displays the error message(s) using the showError function from the useNotification hook
 * @returns An object with a function called checkErrors
 */
const useErrors = () => {
  const { showError } = useNotification();

  /**
   * If the error has a message, show it. If it has validation errors, show them
   * @param e - The error object that is returned from the server.
   * @returns the error message.
   */
  const checkErrors = (e) => {
    const errors = e.response?.data.error;
    if (!errors) return;

    if (errors.message) showError(errors.message);

    const validationErrors = errors.validationErrors;

    if (validationErrors) {
      for (var error of validationErrors) {
        showError(error.message);
      }
    }
  };
  return { checkErrors };
};

export { useErrors };
