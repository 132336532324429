import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useForm } from "react-hook-form";
import clubService from "../../services/club/clubService";
import CrudTable from "../../components/crudTable/CrudTable";
import { useNotification } from "../../hooks/notification/useNotification";
import notificationsService from "../../services/notifications/notificationsService";
import { useErrors } from "../../utils/error/useErrors";
import {
  fieldsToShow,
  fieldsToEdit,
  fieldsToCreate,
} from "./notificationsConfig";
import { L } from "../../utils/abpUtility";

let abp = window.abp;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldsCreate, setFieldsCreate] = useState(fieldsToCreate);
  const [fieldsEdit, setFieldsEdit] = useState(fieldsToEdit);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [openClearCacheDialog, setOpenClearCacheDialog] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [clubId, setClubId] = useState(null);
  const [clubs, setClubs] = useState([]);
  const { showSuccess } = useNotification();
  const { showError } = useNotification();
  const { register, handleSubmit } = useForm();
  const { checkErrors } = useErrors();
  const [formLoading, setFormLoading] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [club, setClub] = useState({
    label: "",
    value: 0,
  });
  const notificationValuesToFilter = [2, 3, 5, 6, 7];
  const [notification, setNotification] = useState({
    title: "",
    subTitle: "",
    startDate: Date,
    endDate: Date,
    clubId: "00000000-0000-0000-0000-000000000000",
    imageId: "00000000-0000-0000-0000-000000000000",
    videoId: "00000000-0000-0000-0000-000000000000",
    interval: 0,
    notificationType: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");

  let timer;

  useEffect(() => {
    getAllNotifications();
    getAllClubs();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllNotifications = () => {
    setLoading(true);
    notificationsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((notification) => {
        setNotifications(notification.items);
        setLazyParams({ ...lazyParams, totalRecords: notification.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    notificationsService
      .update(data)
      .then((res) => {
        getAllNotifications();
        setLoading(false);

        window.location.reload();
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    notificationsService
      .create(data)
      .then((res) => {
        getAllNotifications();
        setLoading(false);

        window.location.reload();
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    notificationsService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllNotifications();
        setLoading(false);

        window.location.reload();
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getAllClubs = () => {
    clubService
      .getAll({
        HasNoImages: true,
        HasNoPaginator: true,
      })
      .then((res) => {
        let rs = res.items.map((x) => {
          return { label: x.clubName, value: x.id };
        });
        setClubs(rs);

        setFieldsCreate((fields) => [
          ...fields,
          {
            name: "clubId",
            title: "Club",
            type: "dropdown",
            rules: { required: L("Club is required") },
            props: {
              options: res.items,
              optionLabel: "clubName",
              optionValue: "id",
              placeholder: L("Select club"),
            },
          },
          {
            name: "notificationType",
            title: "Notification type",
            type: "dropdown",
            rules: { required: L("Notification type is required") },
            props: {
              options: notificationTypes.filter(n => notificationValuesToFilter.includes(n.value)),
              optionLabel: "label",
              optionValue: "value",
              placeholder: L("Select nptification type"),
            },
          },
        ]);

        setFieldsEdit((fields) => [
          ...fields,
          {
            name: "clubId",
            title: "Club",
            type: "dropdown",
            rules: { required: L("Club is required") },
            props: {
              options: res.items,
              optionLabel: "clubName",
              optionValue: "id",
              placeholder: L("Select club"),
            },
          },
          {
            name: "notificationType",
            title: "Notification type",
            type: "dropdown",
            rules: { required: L("Notification type is required") },
            props: {
              options: notificationTypes.filter(n => notificationValuesToFilter.includes(n.value)),
              optionLabel: "label",
              optionValue: "value",
              placeholder: L("Select nptification type"),
            },
          },
        ]);
      });
  };

  const getSingleNotification = async (id) => {
    const notification = await notificationsService.get({ id });
    return notification;
  };

  const extendHeader = () => {
    return (
      <>
        {abp.auth.hasPermission("Pages.Notification.Send") && (
          <Button
            label="Send"
            icon="pi pi-ticket"
            className="p-button-help ml-2"
            onClick={() => {
              setOpenSendDialog(true);
            }}
          />
        )}
        {abp.auth.hasPermission("Pages.Notification.ClearCache") && (
          <Button
            label="Clear Cache"
            icon="pi pi-ticket"
            className="p-button-help ml-2"
            onClick={() => {
              setOpenClearCacheDialog(true);
            }}
          />
        )}
      </>
    );
  };

  const createSendFooter = () => (
    <div className="align-items-center justify-content-end flex ">
      <Button
        label="Send"
        icon="pi pi-check"
        disabled={formLoading}
        loading={formLoading}
        type="submit"
      />
    </div>
  );

  const createClearCacheFooter = () => (
    <div className="align-items-center justify-content-end flex ">
      <Button
        label="Clear"
        icon="pi pi-check"
        // disabled={formLoading}
        // loading={formLoading}
        // type="submit"
        //TODO: when form works remove this
        onClick={onClearCacheSubmit}
      />
    </div>
  );

  const onClearCacheSubmit = (data) => {
    //TODO: make form works
    data.clearCacheData = true;
    let modifyData = {};
    modifyData.clearCacheData = true;

    setFormLoading(true);
    notificationsService
      .clearCache(modifyData)
      .then(() => {
        setOpenClearCacheDialog(false);
        setFormLoading(false);
        showSuccess("Success", "You have successfully clear cache");
      })
      .catch((e) => {
        checkErrors(e);
        setFormLoading(false);
        showError("An error occured while clear cache", "Error");
      });
  };

  const onSubmit = (data) => {
    data.notificationType = notificationType;
    data.clubId = clubId;
    setFormLoading(true);
    notificationsService
      .send(data)
      .then(() => {
        setOpenSendDialog(false);
        setFormLoading(false);
        showSuccess("Success", "You have successfully send notification");
      })
      .catch((e) => {
        checkErrors(e);
        setFormLoading(false);
        showError("An error occured while send notification", "Error");
      });
  };

  const notificationTypes = [
    { label: "None", value: 0 },
    { label: "Order", value: 1 },
    { label: "Prize", value: 2 },
    { label: "PrizeExpire", value: 3 },
    { label: "PointTransaction", value: 4 },
    { label: "InviteAndWin", value: 5 },
    { label: "Partner", value: 6 },
    { label: "Common", value: 7 },
    { label: "End", value: 8 },
  ];

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={notifications}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsEdit}
        fieldsToCreate={fieldsCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Notifications"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleNotification}
        createPermission={"Pages.Notification.Create"}
        editPermission={"Pages.Notification.Edit"}
        deletePermission={"Pages.Notification.Delete"}
        extendHeader={extendHeader}
      />
      <Dialog
        visible={openSendDialog}
        style={{ width: "450px" }}
        header={`Send notification`}
        modal
        onHide={() => setOpenSendDialog(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="field"
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <Dropdown
              placeholder={
                (clubs && clubs.find((x) => x.value === clubId)?.label) ?? "Club"
              }
              options={clubs}
              onChange={(e) => {
                setClubId(e.value);
              }}
            />
            <InputText
              {...register("title", { required: true, minLength: 3 })}
              type="title"
              disabled={formLoading}
              placeholder="Title"
            />
            <InputText
              {...register("accountId")}
              type="acountId"
              disabled={formLoading}
              placeholder="AccountId"
            />
            {/* <InputText
              {...register("notificationType", {
                required: true,
                minLength: 1,
              })}
              type="type"
              disabled={formLoading}
              placeholder="Notification Type"
            /> */}
            <Dropdown
              placeholder={
                notificationTypes[notificationType]?.label ??
                "Notification Type"
              }
              options={notificationTypes}
              onChange={(e) => {
                setNotificationType(e.value);
              }}
            />
          </div>
          {createSendFooter()}
        </form>
      </Dialog>
      <Dialog
        visible={openClearCacheDialog}
        style={{ width: "450px" }}
        header={`Clear All Stored Caches`}
        modal
        onHide={() => setOpenClearCacheDialog(false)}
      >
        {/* <form onSubmit={handleSubmit(onClearCacheSubmit)}>
          <div
            className="field"
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <InputText
              {...register("clearCache")}
              type="clearCache"
              disabled={true}
              placeholder="ClearCache"
            />
          </div> */}
        {createClearCacheFooter()}
        {/* </form> */}
      </Dialog>
    </div>
  );
};

export default Notifications;
