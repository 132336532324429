import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import authenticationService from "../../services/authentication/authenticationService";
import usersService from "../../services/users/usersService";

var abp = window.abp;

const LanguageSelect = () => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    const languages = abp.localization.languages.filter((val) => {
      return !val.isDisabled;
    });

    setLanguages(languages);
    setSelectedLanguage(abp.localization.currentLanguage);
  }, []);

  const changeSelectedLanguage = async (lang) => {
    setSelectedLanguage(lang);

    if (authenticationService.isAuthenticated()) {
      await usersService.changeLanguage({
        languageName: lang.name,
      });
    }

    abp.utils.setCookieValue(
      "Abp.Localization.CultureName",
      lang.name,
      new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
      abp.appPath
    );

    window.location.reload();
  };

  const optionTemplate = (option) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
        }}
      >
        {option?.icon && (
          <span
            style={{
              width: "18px",
              height: "12px",
            }}
            className={`flag flag-${option.icon.replace(
              "famfamfam-flags ",
              ""
            )}`}
            alt=""
          />
        )}
        <div>{option?.displayName}</div>
      </div>
    );
  };

  return (
    <div>
      <Dropdown
        options={languages}
        value={selectedLanguage}
        valueTemplate={optionTemplate}
        itemTemplate={optionTemplate}
        onChange={(event) => changeSelectedLanguage(event.target.value)}
        placeholder="Select language"
        optionLabel="displayName"
      />
    </div>
  );
};

export default LanguageSelect;
