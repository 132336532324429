import http, { getFormData } from "../http/httpService";

class VideosService {
  async upload(createImageInput) {
    const formData = getFormData(createImageInput);

    let result = await http.post("api/services/app/Videos/Upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Videos/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Videos/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Videos/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updateImageInput) {
    const formData = getFormData(updateImageInput);

    let result = await http.put("api/services/app/Videos/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }
}

export default new VideosService();
