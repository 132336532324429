import { useState } from "react";
import clubService from "../../../services/club/clubService";
import { Button } from "primereact/button";

export const GenerateScreensStep = ({ clubId, setIndex }) => {
  const [generateLoading, setGenerateLoading] = useState(false);
  const generateScreens = () => {
    setGenerateLoading(true);
    clubService
      .generateScreens({
        clubId: clubId,
      })
      .then(() => {
        setGenerateLoading(false);
        setIndex(2);
      })
      .catch(() => {
        setGenerateLoading(false);
      });
  };
  return (
    <div>
      <h2>Generate Screens</h2>
      <Button
        label="Generate Screen"
        onClick={generateScreens}
        loading={generateLoading}
        disabled={generateLoading}
      />
    </div>
  );
};
