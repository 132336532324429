import AppConsts from "../http/appconst";
import http from "../http/httpService";
import tokenAuthService from "../tokenAuth/tokenAuthService";

var abp = window.abp;

class AuthenticationService {
  isAuthenticated() {
    if (!abp.session.userId) return false;

    return true;
  }

  async getCurrentLoginInfo() {
    let result = await http.get(
      "api/services/app/Session/GetCurrentLoginInformations"
    );
    return result.data.result;
  }

  async registerUser(data) {
    let result = await http.post("api/services/app/Account/RegisterUser", data);
    return result.data.result;
  }

  async confirmUser(data) {
    let result = await http.post("api/services/app/Account/ActivateUser", data);
    return result.data.result;
  }

  async forgotPassword(data) {
    let result = await http.post(
      "api/services/app/Account/ForgotPassword",
      data
    );
    return result.data.result;
  }

  async forgotPasswordReset(data) {
    let result = await http.post(
      "api/services/app/Account/ForgotPasswordReset",
      data
    );
    return result.data.result;
  }

  async login(model) {
    let result = await tokenAuthService.authenticate({
      userNameOrEmailAddress: model.userNameOrEmailAddress,
      password: model.password,
      rememberClient: model.rememberMe,
    });

    var tokenExpireDate = model.rememberMe
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined;
    abp.auth.setToken(result.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      window.location.origin
    );
    if (!result.accessToken) {
      throw Error("error");
    }
    return result;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
  }
}
export default new AuthenticationService();
