import http, { getFormData } from "../http/httpService";
import imagesService from "../images/imagesService";
import partnerClubMappingService from "../partnerClubMapping/partnerClubMappingService";

class PartnersService {
  async create(createPartnerInput) {
    createPartnerInput.partnerSupportedPayment =
      createPartnerInput.partnerSupportedPaymentNew;

    const formData = getFormData(createPartnerInput);

    let result = await http.post("api/services/app/Partners/Create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Partners/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Partners/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Partners/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
  async getAllByClub(params) {
    let result = await http.get(
      "api/services/app/Partners/GetPartnersByClubId",
      {
        params,
      }
    );
    return result.data.result;
  }
  async getPartnersOverview() {
    let result = await http.get(
      "api/services/app/Partners/GetPartnersOverview"
    );
    let items = result.data.result.filter(
      (i) => i?.imageId && i.partnerType === "Active"
    );
    let data = items.map((i) => imagesService.getImageUrl(i.imageId));

    return data;
  }

  async update(updatePartnerInput, lang) {
    updatePartnerInput.partnerSupportedPayment =
      updatePartnerInput.partnerSupportedPaymentNew;

    const formData = getFormData(updatePartnerInput);

    let result = await http.put("api/services/app/Partners/Update", formData, {
      params: { lang },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    await partnerClubMappingService.createOrUpdatePartnerToClub(
      updatePartnerInput.partnerClubMappings
    );

    return result.data.result;
  }

  async getSupportedPayments(entityDto) {
    let result = await http.get("api/services/app/SupportedPayments/GetAll", {
      params: entityDto,
    });
    return result.data.result;
  }
}

export default new PartnersService();
