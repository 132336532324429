import http from "../http/httpService";

class ScreensService {
  async update(entityDto, lang) {
    entityDto.layoutJson = JSON.stringify(entityDto.layoutJson);
    let result = await http.put(
      "api/services/app/ScreenLayouts/Update",
      entityDto,
      { params: { lang } }
    );
    return result.data.result;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/ScreenLayouts/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/ScreenLayouts/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async getStyle(entityDto) {
    let result = await http.get("api/services/app/ScreenLayouts/GetStyleByClubId", {
      params: entityDto,
    });
    return result.data.result;
  }

  async updateStyle(entityDto) {
    let result = await http.put(
      "api/services/app/ScreenLayouts/UpdateStyleByClubId",
      entityDto
    );
    return result.data.result;
  }
}

export default new ScreensService();
