import React, { useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import AppMenu from "./AppMenu";
import { useNavigate } from "react-router-dom";
import authenticationService from "../../services/authentication/authenticationService";
import ProtectedAuthContent from "../routes/ProtectedAuthContent";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { L } from "../../utils/abpUtility";
import { useUser } from "../../hooks/user/useUser";
import "./Menu.scss";

/**
 * It renders the topbar of the application
 * @param props - The props passed to the component.
 * @returns A functional component that returns a div with a class of layout-topbar.
 */
const AppTopbar = (props) => {
  const navigate = useNavigate();
  const searchPanelDesktop = useRef(null);
  const searchPanelMobile = useRef(null);
  const user = useUser();

  useEffect(() => {
    if (props.searchActive) {
      if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
      else searchPanelMobile.current.focus();
    }
  }, [props.searchActive]);

  return (
    <div className="layout-topbar">
      <div className="layout-topbar-wrapper">
        <div className="layout-topbar-left">
          <button
            tabIndex="0"
            className="menu-button p-link"
            onClick={props.onMenuButtonClick}
          >
            <i className="pi pi-bars"></i>
          </button>
          <button
            tabIndex="0"
            id="logo-link"
            onClick={() => navigate("/")}
            className="layout-topbar-logo p-link"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/layout/images/logo-${
                props.topbarScheme === "dark" ? "freya-white" : "freya"
              }.svg`}
              alt="freya-layout"
            />
          </button>
        </div>
        <AppMenu
          menuMode={props.menuMode}
          sidebarActive={props.sidebarActive}
          sidebarStatic={props.sidebarStatic}
          model={props.menu}
          menuActive={props.menuActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMobileMenuActive={props.onMobileMenuActive}
          onMenuItemClick={props.onMenuItemClick}
          onSidebarMouseOver={props.onSidebarMouseOver}
          onSidebarMouseLeave={props.onSidebarMouseLeave}
          onToggleMenu={props.onToggleMenu}
          resetActiveIndex={props.resetActiveIndex}
          onMenuClick={props.onMenuClick}
        />
        <div className="layout-topbar-right">
   
          <ul className="layout-topbar-actions">
            <li className="topbar-item">
              <LanguageSelect />
            </li>
            <li
              className={classNames("topbar-item user-profile", {
                "active-topmenuitem fadeInDown": props.topbarUserMenuActive,
              })}
            >
              <button
                className="p-link"
                style={{ cursor: "pointer" }}
                onClick={props.onTopbarUserMenuClick}
              >
                <img
                  src={
                    user?.profilePicture
                      ? `data:image/jpeg;base64,${user?.profilePicture}`
                      : `${process.env.PUBLIC_URL}/images/defaultProfilePicture.jpeg`
                  }
                  alt="freya-layout"
                />
              </button>
              <ul className="fadeInDown">
                <ProtectedAuthContent auth={true}>
                  <li onClick={() => navigate("/profile")}>
                    <button className="p-link">
                      <span>{L("Profile")}</span>
                    </button>
                  </li>
                </ProtectedAuthContent>
                <li onClick={() => navigate("/settings")}>
                  <button className="p-link">
                    <span>{L("Settings")}</span>
                  </button>
                </li>
                <ProtectedAuthContent auth={true}>
                  <li
                    onClick={() => {
                      authenticationService.logout();
                      window.location.replace(process.env.PUBLIC_URL);
                    }}
                  >
                    <button className="p-link">
                      <span>{L("Logout")}</span>
                    </button>
                  </li>
                </ProtectedAuthContent>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppTopbar;
