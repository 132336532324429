import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import clubService from "../../../services/club/clubService";
import { Controller, useForm } from "react-hook-form";
import { AutoComplete } from "primereact/autocomplete";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

export const CreateClubStep = ({ setIndex, setClubId, setClubName }) => {
  const [createClubLoading, setCreateClubLoading] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["clubs"],
    queryFn: () =>
      clubService.getAll({
        HasNoImages: true,
        HasNoPaginator: true,
      }),
  });

  let clubs = data?.items;

  const createClubForm = useForm();

  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(clubs);
  }, [clubs]);

  const search = (event) => {
    let results = clubs.filter((i) =>
      i.clubName.toLowerCase().includes(event.query.toLowerCase())
    );
    setItems(results);
  };

  const onClubCreateSubmit = (data) => {
    setCreateClubLoading(true);

    if (value?.clubName) {
      let id = value?.id;
      setClubId(id);
      setClubName(value?.clubName);
      setIndex(1);
      setCreateClubLoading(false);
    } else {
      clubService
        .create({
          clubName: value,
          logoImage: data.logoImage,
        })
        .then((response) => {
          let id = response.id;
          setClubId(id);
          setClubName(value);
          setIndex(1);
          setCreateClubLoading(false);
        });
    }
  };

  return !isLoading ? (
    <div className="wizard-step-one">
      <h2>Create or select Club</h2>
      <form onSubmit={createClubForm.handleSubmit(onClubCreateSubmit)}>
        <div className="formInput">
          <p>Please enter the name of the club here or create one</p>
          <AutoComplete
            field="clubName"
            suggestions={items}
            placeholder="Select or create club"
            completeMethod={search}
            dropdown
            value={value}
            onChange={(e) => setValue(e.value)}
          />
        </div>

        {!value?.clubName && (
          <div className="imageField">
            <label>{"Club Logo"}</label>
            <Controller
              name={"logoImage"}
              control={createClubForm.control}
              render={({ field }) => (
                <React.Fragment>
                  <FileUpload
                    disabled={createClubLoading}
                    accept="image/*, .json,application/json"
                    maxFileSize={100000000}
                    mode="basic"
                    onSelect={(event) => field.onChange(event.files[0])}
                  />
                  <img
                    src={field.value?.objectURL}
                    alt={field.label}
                    style={{
                      maxWidth: "200px",
                      objectFit: "contain",
                    }}
                  />
                </React.Fragment>
              )}
            ></Controller>
          </div>
        )}

        <Button
          label={value?.clubName ? "Select club" : "Create Club"}
          disabled={createClubLoading}
        />
      </form>
    </div>
  ) : (
    <ProgressSpinner />
  );
};
