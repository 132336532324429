import React, { useMemo, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate, useParams } from "react-router-dom";
import prizesService from "../../services/prizes/prizesService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TranslationPaging } from "../../components/crudTable/CrudTable";
import { L } from "../../utils/abpUtility";
import { useQuery } from "@tanstack/react-query";
import ReactPlayer from "react-player";

const PrizeDetails = () => {
  const [winnerDialog, setWinnerDialog] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [winner, setWinner] = useState(null);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });

  const { data: prizeEntriesResult, isLoading: isPrizeEntriesLoading } =
    useQuery({
      queryKey: [
        "prizeEntries",
        { id: id, first: lazyParams.first, rows: lazyParams.rows },
      ],
      queryFn: () =>
        prizesService.getAllPrizeEntries({
          maxResultCount: lazyParams.rows,
          skipCount: lazyParams.first,
          prizeId: id,
        }),
      onSuccess: (data) => {
        setLazyParams({
          ...lazyParams,
          totalRecords: data.totalCount,
        });
      },
    });

  const prizeEntries = useMemo(() => {
    return prizeEntriesResult?.items || [];
  }, [prizeEntriesResult]);

  const {
    data: prize,
    isLoading: isPrizeLoading,
    refetch: refetchPrize,
  } = useQuery({
    queryKey: ["prize", id],
    queryFn: () => prizesService.get({ id, lang: 1 }),
    enabled: !!id,
  });

  if (isPrizeLoading) {
    return (
      <div className="card" style={{ display: "flex", alignItems: "center" }}>
        <ProgressSpinner />
      </div>
    );
  }

  const onPage = (event) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  const pickWinner = async () => {
    const winner = await prizesService.getPrizeWinner({ prizeId: id });
    setWinner(winner);
  };

  const confirmWinnerAccount = async () => {
    setConfirmationLoading(true);
    await prizesService.confirmPrizeWinner({
      prizeEntryId: winner.id,
    });
    setConfirmationLoading(false);
    setWinnerDialog(false);

    await refetchPrize();
  };

  return (
    <div
      className="card p-7"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 15,
          top: 15,
          zIndex: 10,
        }}
      >
        <Button
          severity="danger"
          icon="pi pi-pencil"
          label="Edit"
          onClick={() => navigate("/prizes/update/" + id)}
        ></Button>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
        <img
          src={`data:image/jpeg;base64,${prize?.image?.contents}`}
          alt="club logo"
          style={{
            maxWidth: "330px",
            borderRadius: "15px",
            maxHeight: "300px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <h1 className="m-0">{prize.prizeTitle}</h1>
          <h5 className="m-0">{prize.description}</h5>
          {!prize.winnerAccountId && (
            <Button
              label="Pick a winner"
              onClick={() => setWinnerDialog(true)}
              style={{ width: "200px" }}
            />
          )}

          <Dialog
            header="Pick a winner"
            visible={winnerDialog}
            style={{ width: "50vw" }}
            onHide={() => setWinnerDialog(false)}
            draggable={false}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <Button
                label="Pick a winner"
                onClick={pickWinner}
                style={{ width: "250px" }}
              />
              {winner && (
                <div>
                  <h4 className="mt-5">Winner:</h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {winner.user && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "15px",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            winner.user.profileImage?.imageUrl ||
                            `data:image/png;base64,${winner.user.profileImage?.contents}`
                          }
                          style={{
                            aspectRatio: 1,
                            width: "40px",
                            borderRadius: "50px",
                          }}
                        />
                        <h4 className="m-0">{winner.user.fullName}</h4>
                        {" - "}
                        <p>{winner.user.email}</p>
                      </div>
                    )}

                    <h6 className="m-0">Entries: {winner.entries}</h6>
                    <h6 className="m-0">Points: {winner.points}</h6>
                    <h6 className="m-0">
                      Entry Date:{" "}
                      {new Date(winner.entryDate).toLocaleDateString()}
                    </h6>
                  </div>
                  <Button
                    className="mt-5 w-full"
                    label="CONFIRM WINNER"
                    severity="help"
                    onClick={confirmWinnerAccount}
                    loading={confirmationLoading}
                    disabled={confirmationLoading}
                  />
                </div>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      {prize?.winnerPrizeEntry && (
        <div className="mt-2 mb-3">
          <h4 className="mt-5">Winner:</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {prize.winnerPrizeEntry?.user && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "15px",
                }}
              >
                <img
                  alt=""
                  src={
                    prize.winnerPrizeEntry?.user.profileImage?.imageUrl ||
                    `data:image/png;base64,${prize.winnerPrizeEntry?.user.profileImage?.contents}`
                  }
                  style={{
                    aspectRatio: 1,
                    width: "40px",
                    borderRadius: "50px",
                  }}
                />
                <h4 className="m-0">{prize.winnerPrizeEntry?.user.fullName}</h4>
                {" - "}
                <p>{prize.winnerPrizeEntry?.user.email}</p>
              </div>
            )}

            <h6 className="m-0">Entries: {prize.winnerPrizeEntry.entries}</h6>
            <h6 className="m-0">Points: {prize.winnerPrizeEntry.points}</h6>
            <h6 className="m-0">
              Entry Date:{" "}
              {new Date(prize.winnerPrizeEntry.entryDate).toLocaleDateString()}
            </h6>
            {prize.winnerVideoUrl && <ReactPlayer url={prize.winnerVideoUrl} />}
          </div>
        </div>
      )}
      <div className="entries">
        {lazyParams.totalRecords && (
          <h2>Prize Entries - {lazyParams.totalRecords}</h2>
        )}
        <DataTable
          loading={isPrizeEntriesLoading}
          value={prizeEntries}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginator
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          emptyMessage={L("No available options")}
          lazy={true}
          onPage={onPage}
          {...lazyParams}
          currentPageReportTemplate={TranslationPaging(
            "{first}",
            "{last}",
            "{totalRecords}"
          )}
        >
          <Column field="user.fullName" header="Full Name"></Column>
          <Column field="user.email" header="Email"></Column>
          <Column
            field="user.entryDate"
            header="Entry Date"
            body={(rowData) => (
              <Button
                label={new Date(rowData.entryDate).toLocaleDateString()}
                severity="help"
              />
            )}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PrizeDetails;
