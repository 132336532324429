import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import LoginWrapper from "../../components/login/LoginWrapper";
import authenticationService from "../../services/authentication/authenticationService";
import { L } from "../../utils/abpUtility";
import { useErrors } from "../../utils/error/useErrors";

const ForgotPasswordReset = () => {
  const { register, handleSubmit, control, watch } = useForm();
  const [globalError, setGlobalError] = useState(null);
  const params = useParams();
  const { checkErrors } = useErrors();
  const navigate = useNavigate();

  useEffect(() => {
    var subscription = watch(() => {
      setGlobalError(null);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onSubmit = (data) => {
    data.resetCode = params.resetCode;

    authenticationService
      .forgotPasswordReset(data)
      .then(() => {
        navigate("/login");
      })
      .catch((e) => {
        checkErrors(e);
        setGlobalError("Error with forgot password reset");
      });
  };

  return (
    <LoginWrapper showAside={false}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="logo p-link"
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="freya-layout"
            className="loginLogo"
          />
        </button>

        {globalError && <div className="login_errorPanel">{globalError}</div>}

        <h2 className="loginTitle">{L("ForgotPasswordReset")}</h2>

        <Controller
          control={control}
          name="newPassword"
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <input
              type="password"
              className="loginInput"
              placeholder={L("NewPassword")}
              {...field}
            />
          )}
        ></Controller>

        <Controller
          control={control}
          name="confirmPassword"
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <input
              type="password"
              className="loginInput"
              placeholder={L("ConfirmPassword")}
              {...field}
            />
          )}
        ></Controller>

        <Button
          label={L("UpdatePassword")}
          type="submit"
          className="loginSubmitBtn"
        ></Button>
      </form>
    </LoginWrapper>
  );
};

export default ForgotPasswordReset;
