import http from "../http/httpService";

class PartnerApplicationsService {

  async create(createPartnerApplicationInput) {
    let result = await http.post("api/services/app/PartnersApplications/Create", createPartnerApplicationInput);

    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/PartnersApplications/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/PartnersApplications/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAllByPartnerId(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/PartnersApplications/GetAllByPartnerId", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updatePartnerApplicationInput) {
    let result = await http.put("api/services/app/PartnersApplications/Update", updatePartnerApplicationInput);

    return result.data.result;
  }
}

export default new PartnerApplicationsService();