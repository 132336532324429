import { PartnerAppUsage } from "../components/PartnerAppUsage";
import PartnerTransactionsPerMonthChart from "../components/PartnerTransactionsPerMonthChart";
import { RenderTeamMembers } from "../components/RenderTeamMembers";

export const PartnerDashboard = ({ data, loading, dates }) => {
  return (
    <div className="partner-dashboard">
      <div className="dataColumn">
        <div className="card overview-box blue dashboard-card">
          <div className="overview-info">
            <h6>Transactions</h6>
            <h1>{data.numberOfTransactions}</h1>
          </div>
          <i className="pi pi-users"></i>
        </div>
        <RenderTeamMembers teamMembers={data?.teamMembers} />
      </div>
      <div className="col-10 mt-4">
        <h3>Transactions per month:</h3>
        <PartnerTransactionsPerMonthChart data={data} />
      </div>

      <PartnerAppUsage data={data} />
    </div>
  );
};
