import React, { useEffect, useState } from "react";
import dbSyncService from "../../services/databaseSynchronizer/databaseSynchronizerService";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import "./DatabaseSynchronizer.scss";
import { Button } from "primereact/button";

const DatabaseSynchronizer = () => {
  const [tables, setTables] = useState([]);
  const [options, setOptions] = useState([]);
  const [source, setSource] = useState();
  const [target, setTarget] = useState();
  const [tablesSelected, setTablesSelected] = useState([]);
  const [dbLoading, setDbLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setDbLoading(true);
      let data = await dbSyncService.getAll();
      let tablesItems = await dbSyncService.getAllTableNames();
      setTables(tablesItems.items);
      setOptions(data.items);
      setDbLoading(false);
    })();
  }, []);

  const onTablesChange = (e) => {
    let _tables = [...tablesSelected];

    if (e.checked) _tables.push(e.value);
    else _tables.splice(_tables.indexOf(e.value), 1);

    setTablesSelected(_tables);
  };

  const onBtnClicked = async () => {
    setDbLoading(true);
    let tables = tablesSelected.map((i) => {
      return { name: i };
    });

    dbSyncService.syncronizeData({
      databaseSorceId: source,
      databaseTargetId: target,
      tables: tables,
    });
    setDbLoading(false);
  };

  if (dbLoading) {
    return (
      <div
        className="card"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div className="card dbSync">
      <h1>Database Synchronizer</h1>
      <div className="dbs">
        <Dropdown
          options={options}
          value={source}
          onChange={(e) => setSource(e.value)}
          showClear
          optionLabel="name"
          optionValue="id"
          placeholder="Source"
        />
        <Dropdown
          options={options.filter((i) => i.id !== source)}
          value={target}
          onChange={(e) => setTarget(e.value)}
          optionLabel="name"
          showClear
          optionValue="id"
          placeholder="Target"
        />
      </div>
      {tables.map((i) => (
        <div className="flex align-items-center" key={i.name}>
          <Checkbox
            inputId={i.name}
            name={i.name}
            value={i.name}
            onChange={onTablesChange}
            checked={tablesSelected.includes(i.name)}
          />
          <label htmlFor={i.name} className="ml-2">
            {i.name}
          </label>
        </div>
      ))}
      <Button
        label="Sync"
        disabled={!tablesSelected.length >= 1 || !target || !source}
        onClick={() => onBtnClicked()}
      />
    </div>
  );
};

export default DatabaseSynchronizer;
