import React, { useEffect, useRef, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import screenService from "../../services/screenLayouts/screenLayoutService";
import clubsService from "../../services/club/clubService";
import { useErrors } from "../../utils/error/useErrors";
import {
  fieldsToCreate,
  fieldsToEdit,
  fieldsToShow,
} from "./screenLayoutsConfig";
import { Dropdown } from "primereact/dropdown";
import { L } from "../../utils/abpUtility";
import { LanguageSelect } from "../../components/crudTable/crudTableUtils";

let previousLang = null;

const ScreenLayouts = () => {
  const [screens, setScreens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState({});
  const { checkErrors } = useErrors();
  const [lang, setLang] = useState(1);
  const ref = useRef();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllClubsMinimized();
  }, []);

  useEffect(() => {
    getAllScreens();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  useEffect(() => {
    if (lang !== previousLang && previousLang !== null) {
      ref.current.refetch({ lang });
    }
  }, [lang]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllClubsMinimized = async () => {
    const data = await clubsService.getAll({
      hasNoImages: true,
      hasNoPaginator: true,
    });
    setClubs(data.items);
  };

  const getAllScreens = (clubId) => {
    setLoading(true);
    screenService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        ClubId: clubId,
      })
      .then((screens) => {
        setScreens(screens.items);
        setLazyParams({ ...lazyParams, totalRecords: screens.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    screenService
      .update(data, lang)
      .then((res) => {
        getAllScreens();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleScreen = async (id, options) => {
    const screen = await screenService.get({
      id,
      ...options,
      lang: options?.lang || lang,
    });
    screen.layoutJson = JSON.parse(screen.layoutJson);

    return screen;
  };

  const onClubChange = (value) => {
    setSelectedClub(value);
    getAllScreens(value);
  };

  const extendHeader = () => {
    return (
      <div>
        {!window.club && (
          <Dropdown
            value={selectedClub}
            onChange={(e) => onClubChange(e.target.value)}
            options={clubs}
            optionValue="id"
            optionLabel="clubName"
            placeholder={L("Select club")}
          />
        )}
      </div>
    );
  };

  const ExtendDialog = ({ state }) => {
    return (
      state === "edit" && (
        <div className="pb-3">
          <h4>Select Language:</h4>
          <LanguageSelect
            value={lang}
            onChange={(e) => {
              previousLang = lang;
              setLang(e.target.value);
            }}
          />
        </div>
      )
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={screens}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Screens"
        setLoading={setLoading}
        lazy={true}
        onEditSubmit={onEditSubmit}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleScreen}
        extendHeader={extendHeader}
        canCreate={false}
        canDelete={false}
        editPermission="Pages.ScreenLayout.Edit"
        extendDialog={ExtendDialog}
        ref={ref}
      />
    </div>
  );
};

export default ScreenLayouts;
