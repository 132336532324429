import PointsUsage from "../components/PointsUsage";
import { RenderTeamMembers } from "../components/RenderTeamMembers";
import SDCardsPerMonthChart from "../components/SDCardsPerMonthChart";
import { SDClubCards } from "../components/SDClubCards";

export const SportsDynamicsDashboard = ({ data, loading, dates }) => {
  return (
    <div className="club-dashboard">
      <div className="dataColumn">
        <div className="card overview-box dashboard-card">
          <div className="overview-info">
            <h6>SD prizes</h6>
            <h1>{data.numberOfSdPrizes}</h1>
            <p>{data.sdPrizesCost} лв.</p>
          </div>
          <i className="fa-solid fa-gift"></i>
        </div>
        <div className="card overview-box blue dashboard-card">
          <div className="overview-info">
            <h6>Number of cards</h6>
            <h1>{data.numberOfSDCards}</h1>
            <p>Users: {data.numberOfCardUsers}</p>
          </div>
          <i className="pi pi-users"></i>
        </div>
        <RenderTeamMembers teamMembers={data?.teamMembers} />
      </div>
      <div className="">
        <h3>Cards per months:</h3>
        <SDCardsPerMonthChart data={data} />
        <div className="col-6">
          {/* <ClubAppUsage dates={dates} data={data} /> */}
        </div>
      </div>
      <br />
      <div className="dataColumn">
        <div className="col-12">
          <SDClubCards data={data} />
        </div>
      </div>
      <div className="dataColumn">
        <div className="col-12">
          <PointsUsage data={data} />
        </div>
      </div>
    </div>
  );
};
