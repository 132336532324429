import { Button } from "primereact/button";

const showClubName = (rowData) => {
  return (
    rowData.clubName && (
      <Button label={rowData.clubName} className="p-button-success" />
    )
  );
};

const showStatus = (rowData) => {
  return <Button label={rowData.status || ""} className="p-button-help" />;
};

const fieldsToShow = [
  {
    header: "Subject",
    name: "subject",
  },
  {
    header: "RequesterEmail",
    name: "requesterEmail",
  },
  // {
  //   header: "Status",
  //   name: "status",
  //   body: showStatus,
  // },
  {
    header: "PartnerName",
    name: "partnerName",
  },
  {
    header: "Created At",
    name: "createdAt",
    body: (rowData) => <p>{new Date(rowData.createdAt).toDateString()}</p>,
  },
  {
    header: "Club Name",
    name: "clubName",
    body: showClubName,
  },
];

const fieldsToEdit = [
  {
    name: "requesterEmail",
    title: "RequesterEmail",
    type: "string",
    rules: {
      required: "RequesterEmail is required",
    },
  },
  {
    name: "subject",
    title: "Subject",
    type: "string",
    rules: {
      required: "Subject is required",
    },
  },
  {
    name: "status",
    title: "Status",
    type: "string",
    rules: {
      required: "Status is required",
    },
  },
  {
    name: "clubName",
    title: "ClubName",
    type: "string",
    rules: {
      required: "ClubName is required",
    },
  },
  {
    name: "partnerName",
    title: "PartnerName",
    type: "string",
    rules: {
      required: "PartnerName is required",
    },
  },
  {
    name: "unknown",
    title: "Unknown",
    type: "string",
    rules: {
      required: "Unknown is required",
    },
  },
  {
    name: "descrition",
    title: "Descrition",
    type: "string",
    rules: {
      required: "Descrition is required",
    },
  },
  {
    name: "createdAt",
    title: "CreatedAt",
    type: "undefined",
    rules: {
      required: "CreatedAt is required",
    },
  },
  {
    name: "updatedAt",
    title: "UpdatedAt",
    type: "undefined",
    rules: {
      required: "UpdatedAt is required",
    },
  },
];

const fieldsToCreate = [];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
