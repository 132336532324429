import http from "../http/httpService";

class TicketsService {
  async get(entityDto) {
    let result = await http.get("api/services/app/Tickets/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Tickets/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new TicketsService();
