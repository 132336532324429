import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import clubService from "../../../services/club/clubService";
import { useNotification } from "../../../hooks/notification/useNotification";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { ImageUploader } from "../components/ImageUploader";
import ImageUpload from "../../../components/imageUpload/ImageUpload";

export const UpdateLandingPage = ({ clubId, setIndex }) => {
  const [landingPageLoading, setLandingPageLoading] = useState(false);
  const { showError } = useNotification();
  const landingPageForm = useForm();

  const landingPageImageFields = [
    {
      field: "header.backGroundImage",
      label: "Header Background Image",
      preview: "landingHeaderBackgroundImage.png",
    },
    {
      field: "header.footbalPlayerImage",
      label: "Header Footbal Player Image",
      preview: "landingFootballPlayerImage.png",
    },
    {
      field: "downLoadApp.image",
      label: "Download App Image",
      preview: "landingDownloadAppImage.png",
    },
    {
      field: "chooseYourFavoriteDesign.image",
      label: "Choose Your Favorite Design Image",
      preview: "landingChooseDesignImage.png",
    },
    {
      field: "earnPoints.image",
      label: "Earn Points Image",
      preview: "landingEarnPointsImage.png",
    },
    {
      field: "getRewards.image",
      label: "Get Rewards Image",
      preview: "landingGetRewardsImage.png",
    },
    {
      field: "separator.backGroundImage",
      label: "Separator Background Image",
      preview: "landingSeparatorBackgroundImage.png",
    },
  ];

  const onSubmit = (data) => {
    setLandingPageLoading(true);
    data.id = clubId;

    clubService
      .updateLandingPage(data)
      .then(() => {
        setIndex((index) => index + 1);
        setLandingPageLoading(false);
      })
      .catch(() => {
        showError(
          "An error occured while updating the landing page items",
          "Error"
        );
      });
  };

  return (
    <form
      onSubmit={landingPageForm.handleSubmit(onSubmit)}
      className="updateOnboarding"
    >
      <h2>Update landing page</h2>

      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        {landingPageImageFields.map((field) => (
          <ImageUploader
            field={field}
            control={landingPageForm.control}
            loading={landingPageLoading}
            key={field.field}
            style={{
              width: "350px",
            }}
          />
        ))}

        <label>Advertise</label>
        <Controller
          name="footer.advertise"
          control={landingPageForm.control}
          render={({ field }) => (
            <ImageUpload
              {...field}
              multiple
              onSelect={(event) => {
                let data = field.value || [];
                for (const file of event.files) {
                  data.push(file);
                }

                field.onChange(data);
              }}
            />
          )}
        />
      </div>
      <Button
        label="Update landing page"
        disabled={landingPageLoading}
        loading={landingPageLoading}
      />
    </form>
  );
};
