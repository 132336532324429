import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import clubService from "../../../services/club/clubService";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { ImageUploader } from "../components/ImageUploader";

export const UpdateCards = ({ clubId, setIndex }) => {
  const updateCardsStep = useForm();
  const [updateCardsLoading, setUpdateCardsLoading] = useState(false);

  const onUpdateCardsSubmit = (data) => {
    setUpdateCardsLoading(true);
    clubService
      .updateCards({ ...data, id: clubId })
      .then(() => {
        setUpdateCardsLoading(false);
        setIndex(5);
      })
      .catch(() => {
        setUpdateCardsLoading(false);
      });
  };

  const updateCardsImages = [
    {
      field: "cardTypeFreeFileImage",
      label: "Card Type Free File Image",
    },
    {
      field: "cardTypeStandardFileImage",
      label: "Card Type Standard File Image",
    },
    {
      field: "cardTypeVirtualFileImage",
      label: "Card Type Virtual File Image",
    },
    {
      field: "cardActivateFileImage",
      label: "Card Activate File Image",
    },
    {
      field: "cardVirtualRotatedFileImage",
      label: "Card Virtual Rotated File Image",
    },
  ];

  return (
    <div className="wizard-update-cards">
      <h2>Upload Cards' images</h2>
      <form onSubmit={updateCardsStep.handleSubmit(onUpdateCardsSubmit)}>
        {updateCardsImages.map((field) => (
          <ImageUploader
            field={field}
            key={field.field}
            loading={updateCardsLoading}
            control={updateCardsStep.control}
            style={{
              width: "350px"
            }}
          />
        ))}
        <Button
          label="Upload all Images"
          loading={updateCardsLoading}
          disabled={updateCardsLoading}
        />
      </form>
    </div>
  );
};
