import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import clubService from "../../services/club/clubService";
import cardTypesService from "../../services/cardTypes/cardTypesService";
import cardBatchesService from "../../services/cardBatches/cardBatchesService";
import { useErrors } from "../../utils/error/useErrors";
import {
  fieldsToCreate,
  fieldsToEdit,
  fieldsToShow,
} from "./cardBatchesConfig";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";

const useClubsQuery = ({ keys, ...others }) => {
  const query = useQuery({
    queryKey: ["clubs", keys],
    queryFn: () =>
      clubService.getAll({ hasNoPaginator: true, hasNoImages: true }),
    ...others,
  });

  return query;
};


const CustomBatchData = ({ props }) => {
  let { control, watch } = props;
  let clubId = watch("clubId");

  let { data } = useClubsQuery({
    keys: { hasNoPaginator: true, hasNoImages: true },
  });

  let clubs = data?.items || [];

  const { data: cardTypesResponse } = useQuery({
    queryKey: ["cardTypes", { clubId: clubId }],
    queryFn: () => cardTypesService.getAll({ ClubId: clubId }),
    enabled: !!clubId,
  });

  let cardTypes = cardTypesResponse?.items || [];

  return (
    <React.Fragment>
      <Controller
        name={"clubId"}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <Dropdown
            {...field}
            showClear
            onChange={(e) => {
              field.onChange(e.value ? e.value : null);
            }}
            optionLabel="clubName"
            optionValue="id"
            options={clubs}
            placeholder="Select club..."
          />
        )}
      ></Controller>

      <br />

      <label htmlFor="name">Card Type</label>

      <Controller
        name={"cardTypeId"}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <Dropdown
            {...field}
            showClear
            onChange={(e) => {
              field.onChange(e.value ? e.value : null);
            }}
            optionLabel="displayName"
            optionValue="id"
            options={cardTypes}
            placeholder="Select cardType..."
            disabled={cardTypes.length === 0}
          />
        )}
      ></Controller>
    </React.Fragment>
  );
};

const CardBatches = () => {
  const [cardBatches, setCardBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [fieldsCreate, setFieldsCreate] = useState(fieldsToCreate);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    setFieldsCreate((f) => [
      ...f,
      {
        name: "clubId",
        title: "Club",
        body: (props) => <CustomBatchData props={props} />,
      },
    ]);
  }, []);

  useEffect(() => {
    getAllCardBatches();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllCardBatches = () => {
    setLoading(true);
    cardBatchesService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((batches) => {
        setCardBatches(batches.items);
        setLazyParams({ ...lazyParams, totalRecords: batches.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    cardBatchesService
      .update(data)
      .then(() => {
        getAllCardBatches();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    cardBatchesService
      .create(data)
      .then((res) => {
        getAllCardBatches();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleBatch = async (id) => {
    const batchData = await cardBatchesService.get({ id });
    return batchData;
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={cardBatches}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsCreate}
        loading={loading}
        headerTitle="Manage Card Batches"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleBatch}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        canDelete={false}
        createPermission={"Pages.CardBatche.Create"}
        editPermission={"Pages.CardBatch.Update"}
      />
    </div>
  );
};

export default CardBatches;
