import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { formatCardNumber } from "../../utils/cardsUtils";

const showDate = (rowData) => {
  return <p>{new Date(rowData.datePaid).toDateString()}</p>;
};

const showCardNumber = (rowData) => {
  return <Button label={formatCardNumber(rowData.card?.cardNumber)} className="p-button-info" />;
};

const showPartnerName = (rowData) => {
  return (
    <Button label={rowData.partner?.companyName} className="p-button-success" />
  );
};

const showAmount = (rowData) => {
  return (
    <Badge
      value={Math.abs(rowData.transactionLog?.transactionAmount)}
      severity="danger"
    />
  );
};

const fieldsToShow = [
  {
    header: "Card Number",
    name: "card.cardNumber",
    body: showCardNumber,
  },
  {
    header: "Partner Name",
    name: "partner.companyName",
    body: showPartnerName,
  },
  {
    header: "Commission",
    name: "commission",
    sortable: true,
  },
  {
    header: "Total",
    name: "orderTotal",
    sortable: true,
  },
  {
    header: "Card Name",
    name: "card.fullName",
  },
  {
    header: "Staff",
    name: "partnerStaff.firstName",
  },
  {
    header: "Date Paid",
    name: "datePaid",
    body: showDate,
  },
  {
    header: "Amount",
    name: "transactionLog.transactionAmount",
    body: showAmount,
    sortable: true,
  },
];

export { fieldsToShow };
