import React from "react";
import { v4 as uuidv4 } from "uuid";
import { L } from "../../utils/abpUtility";

const ClientsPartnersLogin = ({ clubs, partners }) => {
  return (
    <div className="loginAsideClients">
      <h3>{L("Clubs")}:</h3>
      <div className="loginClients">
        {clubs?.map((image) => (
          <img
            loading="lazy"
            src={image}
            style={{
              filter: "drop-shadow(3px 3px 3px #d9d9d9)",
            }}
            key={uuidv4()}
            alt="clientImage"
          />
        ))}
      </div>

      <h3>{L("Partners")}:</h3>
      <div className="loginClients">
        {partners?.map((image) => (
          <img
            src={image}
            loading="lazy"
            style={{
              filter: "drop-shadow(3px 3px 3px #d9d9d9)",
            }}
            key={uuidv4()}
            alt="partnerImage"
          />
        ))}
      </div>
    </div>
  );
};

export default ClientsPartnersLogin;
