import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import clubService from "../../../services/club/clubService";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ImageUploader } from "../components/ImageUploader";
import { VideoUploader } from "../components/VideoUploader";

export const UpdateCampaign = ({ clubId, setIndex }) => {
  const updateCampaignForm = useForm();
  const [updateCampaignLoading, setUpdateCampaignLoading] = useState(false);

  const onSubmit = (data) => {
    setUpdateCampaignLoading(true);
    data.id = clubId;
    clubService.updateCampaign(data).then(() => {
      setIndex((idx) => idx + 1);
      setUpdateCampaignLoading(false);
    });
  };

  const inputStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    width: "350px",
  };

  return (
    <form
      onSubmit={updateCampaignForm.handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
      }}
    >
      <div style={inputStyles}>
        <label>Start date</label>

        <Controller
          name="startDate"
          control={updateCampaignForm.control}
          render={({ field }) => (
            <Calendar
              {...field}
              value={new Date(field.value)}
              dateFormat="dd.mm.yy"
              onChange={(e) => field.onChange(new Date(e.value).toISOString())}
              placeholder="Select a start date"
            />
          )}
        />
      </div>
      <div style={inputStyles}>
        <label>End date</label>

        <Controller
          name="endDate"
          control={updateCampaignForm.control}
          render={({ field }) => (
            <Calendar
              {...field}
              value={new Date(field.value)}
              dateFormat="dd.mm.yy"
              onChange={(e) => field.onChange(new Date(e.value).toISOString())}
              placeholder="Select an end date"
            />
          )}
        />
      </div>
      <div style={inputStyles}>
        <label>Points to earn</label>

        <Controller
          name="pointsToEarn"
          control={updateCampaignForm.control}
          render={({ field }) => (
            <InputNumber
              placeholder="Points to earn"
              value={field.value}
              onValueChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>
      <ImageUploader
        field={{ field: "refferAFriendImage", label: "Reffer A Friend Image" }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />
      <ImageUploader
        field={{ field: "orderYourCardImage", label: "Order Your Card Image" }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />
      <ImageUploader
        field={{ field: "winPrizesImage", label: "Win prizes image" }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />
      <ImageUploader
        field={{ field: "earnPointsImage", label: "Earn points image" }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />
      <ImageUploader
        field={{ field: "getRewardsImage", label: "Get rewards image" }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />
      <ImageUploader
        field={{
          field: "supportYourTeamImage",
          label: "Support your team image",
        }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
        style={{
          width: inputStyles.width,
        }}
      />

      <VideoUploader
        field={{
          field: "referAFriendVideo",
          label: "Refer A Friend Video",
        }}
        control={updateCampaignForm.control}
        loading={updateCampaignLoading}
      />
      <Button
        label="Update Referral"
        className="p-button-info"
        type="submit"
        style={{
          width: "fit-content",
        }}
      />
    </form>
  );
};
