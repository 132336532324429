import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import prizesService from "../../services/prizes/prizesService";
import { Calendar } from "primereact/calendar";
import "./clubPrizeReport.scss";
import { Skeleton } from "primereact/skeleton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { datePickingOptions } from "../Dashboard/Dashboard";
import { Button } from "primereact/button";

export const ClubPrizeReport = () => {
  const { id } = useParams();
  const [fromToValues, setFromToValue] = useState({});
  const navigate = useNavigate();
  const [calendarValue, setCalendarValue] = useState(null);

  const { data, isLoading: isReportLoading } = useQuery({
    queryKey: ["clubPrizeReport", id, fromToValues],
    queryFn: () =>
      prizesService.getClubPrizeReport({
        clubId: id,
        startDate: fromToValues?.startDate,
        endDate: fromToValues?.endDate,
      }),
    enabled: !!id,
  });

  const { data: prizesDates, isLoading: isPrizesDatesLoading } = useQuery({
    queryKey: ["prizesDates", id],
    queryFn: () =>
      prizesService.getPrizesDates({
        clubId: id,
      }),
    enabled: !!id,
  });

  useEffect(() => {
    if (calendarValue?.[0] && calendarValue?.[1]) {
      setFromToValue({
        startDate: calendarValue[0],
        endDate: calendarValue[1],
      });
    }
  }, [calendarValue]);

  const dateTemplate = (date) => {
    const isPrizeDate = prizesDates?.find((i) => {
      const prizeStartDate = new Date(i.startDate);
      return (
        prizeStartDate.getDate() === date.day &&
        prizeStartDate.getMonth() === date.month &&
        prizeStartDate.getFullYear() === date.year
      );
    });

    if (isPrizeDate) {
      return (
        <span
          style={{
            backgroundColor: "#1c80cf",
            color: "white",
            width: "30px",
            height: "30px",
            textAlign: "center",
            position: "relative",
            borderRadius: "50px",
            padding: "6px",
          }}
        >
          {date.day}
        </span>
      );
    }

    return date.day;
  };

  return (
    <div className="card clubPrizeReport">
      <h1>
        Prizes Report: <span>{data?.clubName}</span>
      </h1>
      <Calendar
        value={calendarValue}
        onChange={(e) => setCalendarValue(e.value)}
        placeholder="Select a date range"
        selectionMode="range"
        className="mr-3"
        style={{ width: "260px", zIndex: 10 }}
        dateTemplate={dateTemplate}
        disabled={isReportLoading || isPrizesDatesLoading}
        numberOfMonths={2}
      />
      <Dropdown
        value={fromToValues}
        onChange={(e) => {
          setCalendarValue(null);
          setFromToValue(e.value);
        }}
        options={datePickingOptions}
        placeholder="Select a period"
        disabled={isReportLoading || isPrizesDatesLoading}
        className="mr-3"
      />
      <Button
        label="Clear"
        severity="danger"
        onClick={() => {
          setFromToValue(null);
          setCalendarValue(null);
        }}
      />

      {isReportLoading ? (
        <Skeleton height="180px" className="mt-4"></Skeleton>
      ) : (
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            margin: "16px 0",
            height: "180px",
          }}
        >
          <h4 style={{ fontSize: "1.5rem", marginBottom: "16px" }}>
            Reports Overview
          </h4>

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p className="m-0">
              Prizes:{" "}
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                }}
              >
                {data?.reportOverview?.totalPrizes}
              </span>
            </p>
            <p className="m-0">
              Total Entries:{" "}
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                }}
              >
                {data?.reportOverview?.totalEntries}
              </span>
            </p>
            <p className="m-0">
              Total Entry Points:{" "}
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                }}
              >
                {data?.reportOverview?.totalEntryPoints}
              </span>
            </p>
            <p className="m-0">
              Total People Entered:{" "}
              <span
                style={{
                  fontWeight: "600",
                  marginLeft: "3px",
                }}
              >
                {data?.reportOverview?.totalPeople}
              </span>
            </p>
          </div>
        </div>
      )}
      {isReportLoading ? (
        <Skeleton height="55px" className="mt-4" />
      ) : (
        <div className="list">
          {data?.prizes?.map((prize) => (
            <div
              className="prize"
              key={prize.prizeId}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/prizes/" + prize.prizeId)}
            >
              <div
                className="prize-info"
                style={{
                  background: `linear-gradient(to bottom, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8)), url('${prize.image?.imageUrl}')`,
                }}
              >
                {prize.daysAgo < 0 ? (
                  <p className="days-mark">
                    <span>{Math.abs(prize.daysAgo)}</span> days remaining
                  </p>
                ) : (
                  <p className="days-mark">
                    <span>{prize.daysAgo}</span> days ago
                  </p>
                )}
                <h4>{prize.prizeTitle}</h4>
              </div>
              <div className="data">
                <p>People Entered: {prize.entries}</p>
                <p>Total Entries: {prize.totalEntries}</p>
                <p>Entry Points: {prize.entryPoints}</p>
                <p>Cost: {prize.cost}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
