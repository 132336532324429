import React, { useEffect, useState } from "react";
import tenantsService from "../../services/tenants/tenantsService";
import CrudTable from "../../components/crudTable/CrudTable";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllTenants();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllTenants = () => {
    setLoading(true);
    tenantsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((tenants) => {
        setTenants(tenants.items);
        setLazyParams({ ...lazyParams, totalRecords: tenants.totalCount });
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    // partnersService.update(data).then((res) => {
    //   setLoading(false);
    // });
    getAllTenants();
    setLoading(false);
  };

  const onCreateSubmit = (data) => {
    tenantsService.create(data).then((res) => {
      getAllTenants();
      setLoading(false);
    });
  };

  const onDelete = (data) => {
    tenantsService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllTenants();
        setLoading(false);
      });
  };

  const activeBody = (data) => {
    return (
      <Button
        icon="pi pi-check"
        className={classNames(
          "p-button-rounded",
          data.isActive ? "p-button-success" : "p-button-danger"
        )}
        aria-label="Filter"
      />
    );
  };

  const fieldsToShow = [
    {
      header: "Tenancy Name",
      name: "tenancyName",
    },
    {
      header: "Name",
      name: "name",
    },
    {
      header: "Is Active",
      name: "isActive",
      body: activeBody,
    },
  ];

  const fieldsToEdit = [
    {
      name: "tenancyName",
      title: "Tenancy Name",
      type: "string",
    },
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "isActive",
      title: "Is Active",
      type: "bool",
    },
  ];

  const fieldsToCreate = [
    {
      name: "tenancyName",
      title: "Tenancy Name",
      type: "string",
    },
    {
      name: "name",
      title: "Name",
      type: "string",
    },
    {
      name: "adminEmailAddress",
      title: "Admin Email Address",
      type: "string",
    },
    {
      name: "connectionString",
      title: "Connection String",
      type: "string",
    },
    {
      name: "isActive",
      title: "Is Active",
      type: "bool",
    },
  ];

  return (
    <div>
      <h1>Tenants</h1>
      <CrudTable
        data={tenants}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Tenants"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
      />
    </div>
  );
};

export default Tenants;
