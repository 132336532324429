import React from "react";
import authenticationService from "../../services/authentication/authenticationService";

/**
 * If the auth prop is true, then the children will be rendered if the user is authenticated. If the
 * auth prop is false, then the children will be rendered if the user is not authenticated
 */
function ProtectedAuthContent({ children, auth }) {
  const isAuth = authenticationService.isAuthenticated();
  return (
    <React.Fragment>
      {auth ? (isAuth ? children : null) : isAuth ? null : children}
    </React.Fragment>
  );
}

export default ProtectedAuthContent;
