import http from "../http/httpService";

class EnvironmentsService {
  async create(createCampaignInput) {
    let result = await http.post(
      "api/services/app/Environments/Create",
      createCampaignInput
    );
    return result.data.result;
  }

  async get(createCampaignInput) {
    let result = await http.get("api/services/app/Environments/GetById", {
      params: createCampaignInput,
    });
    return result.data.result;
  }

  async update(createCampaignInput) {
    let result = await http.put(
      "api/services/app/Environments/Update",
      createCampaignInput
    );
    return result.data.result;
  }

  async delete(createCampaignInput) {
    let result = await http.delete("api/services/app/Environments/DeleteById", {
      params: createCampaignInput,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Environments/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new EnvironmentsService();
