import React, { useEffect, useRef, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import partnerApplicationsService from "../../services/partnerApplications/partnerApplicationsService";
import partnersService from "../../services/partners/partnersService";
import { useErrors } from "../../utils/error/useErrors";
import {
  fieldsToCreate,
  fieldsToEdit,
  fieldsToShow,
} from "./partnerApplicationsConfig";
import { Dropdown } from "primereact/dropdown";
import { L } from "../../utils/abpUtility";

const PartnerApplication = () => {
  const [applications, setApplications] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkErrors } = useErrors();
  const ref = useRef();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllPartnersMinimized();
  }, []);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllPartnersMinimized = async () => {
    const data = await partnersService.getAll({
      hasNoImages: true,
      hasNoPaginator: true,
    });
    setPartners(data.items);
  };

  const getPartnerApplications = (partnerId) => {
    setLoading(true);
    partnerApplicationsService
      .getAllByPartnerId({
        PartnerId: partnerId,
      })
      .then((application) => {
        setApplications(application.items);
        // setLazyParams({ ...lazyParams, totalRecords: applications.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    partnerApplicationsService
      .create({ ...data, partnerId: selectedPartner })
      .then((res) => {
        getPartnerApplications(selectedPartner);
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    partnerApplicationsService
      .update(data)
      .then((res) => {
        getPartnerApplications(selectedPartner);
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    partnerApplicationsService
      .delete({
        id: data.id,
      })
      .then(() => {
        getPartnerApplications(selectedPartner);
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleApplication = async (id, options) => {
    const application = await partnerApplicationsService.get({id});
    return application;
  };

  const onPartnerChange = (value) => {
    setSelectedPartner(value);
    getPartnerApplications(value);
  };

  const extendHeader = () => {
    return (
      <div>
        {!window.partner && (
          <Dropdown
            value={selectedPartner}
            onChange={(e) => onPartnerChange(e.target.value)}
            options={partners}
            optionValue="id"
            optionLabel="companyName"
            placeholder={L("Select partner")}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={applications}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        onCreateSubmit={onCreateSubmit}
        onEditSubmit={onEditSubmit}
        onDelete={onDelete}
        loading={loading}
        headerTitle="Manage Applications"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        //  setLazyParams={setLazyParams}
        getSingleEntity={getSingleApplication}
        extendHeader={extendHeader}
        canCreate={selectedPartner}
        canDelete={true}
        createPermission="Pages.PartnerApplication.Create"
        editPermission={"Pages.PartnerApplication.Edit"}
        deletePermission={"Pages.PartnerApplication.Delete"}
        ref={ref}
      />
    </div>
  );
};

export default PartnerApplication;
