import { RadioButton } from "primereact/radiobutton";
import React, { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { L } from "../../utils/abpUtility";

const Settings = () => {
  const { colorScheme, menuMode, setMenuMode, changeColorScheme } =
    useContext(ThemeContext);

  return (
    <div className="card">
      <h1>{L("Settings")}</h1>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h5>{L("ColorScheme")}</h5>
        <div className="field-radiobutton">
          <RadioButton
            name="colorScheme"
            value="light"
            inputId="colorScheme1"
            checked={colorScheme === "light"}
            onChange={(e) => changeColorScheme(e.value)}
          ></RadioButton>
          <label htmlFor="colorScheme1">{L("Light")}</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="colorScheme"
            value="dark"
            inputId="colorScheme2"
            checked={colorScheme === "dark"}
            onChange={(e) => changeColorScheme(e.value)}
          ></RadioButton>
          <label htmlFor="colorScheme2">{L("Dark")}</label>
        </div>
      </div>
      <h5>{L("Menu Types")}</h5>
      <div className="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="horizontal"
          inputId="mode1"
          checked={menuMode === "horizontal"}
          onChange={(e) => setMenuMode(e.value)}
        ></RadioButton>
        <label htmlFor="mode1">{L("Horizontal")}</label>
      </div>
      <div className="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="sidebar"
          inputId="mode2"
          checked={menuMode === "sidebar"}
          onChange={(e) => setMenuMode(e.value)}
        ></RadioButton>
        <label htmlFor="mode2">{L("Sidebar")}</label>
      </div>
      <div className="field-radiobutton">
        <RadioButton
          name="menuMode"
          value="slim"
          inputId="mode3"
          checked={menuMode === "slim"}
          onChange={(e) => setMenuMode(e.value)}
        ></RadioButton>
        <label htmlFor="mode3">{L("Slim")}</label>
      </div>
      <hr />
    </div>
  );
};

export default Settings;
