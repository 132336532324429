import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import languagesService from "../../services/languages/languagesService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./languagesConfig";

const Languages = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllLanguages();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllLanguages = () => {
    setLoading(true);
    languagesService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((langs) => {
        setLanguages(langs.items);
        setLazyParams({ ...lazyParams, totalRecords: langs.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleLanguage = async (id) => {
    const langData = await languagesService.get({ id });
    return langData;
  };

  const onCreateSubmit = (data) => {
    setLoading(true);
    languagesService
      .create(data)
      .then(() => {
        getAllLanguages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    setLoading(true);
    languagesService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllLanguages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    languagesService
      .update(data)
      .then(() => {
        getAllLanguages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={languages}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Languages"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleLanguage}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        onEditSubmit={onEditSubmit}
        createPermission={"Pages.Languages.Create"}
        editPermission={"Pages.Languages.Update"}
        deletePermission={"Pages.Languages.Delete"}
      />
    </div>
  );
};

export default Languages;
