import { Button } from "primereact/button";

const showBatchName = (rowData) => {
  return (
    rowData.batch && (
      <Button label={rowData.batch?.title} className="p-button-success" />
    )
  );
};

const showClubName = (rowData) => {
  return (
    rowData.club && (
      <Button label={rowData.club?.clubName} severity="info" />
    )
  );
};

const showStatus = (rowData) => {
  return (
    rowData.cardStatus && (
      <Button label={rowData.cardStatus} className="p-button-help" />
    )
  );
};

const activateBody = (rowData) => {
  return (
    <p>
      {rowData.activatedOn ? new Date(rowData.activatedOn).toDateString() : "-"}
    </p>
  );
};

const fieldsToShow = [
  {
    header: "Card Number",
    name: "cardNumber",
  },
  {
    header: "Full Name",
    name: "fullName",
    sortable: true
  },
  {
    header: "City",
    name: "city",
    sortable: true
  },
  {
    header: "Address",
    name: "streetAddress",
    sortable: true
  },
  {
    header: "Balance",
    name: "balance",
    sortable: true
  },
  {
    header: "Activated On",
    name: "activatedOn",
    sortable: true,
    body: activateBody,
  },
  {
    header: "Status",
    name: "cardStatus",
    sortable: true,
    body: showStatus,
  },
  {
    header: "Batch",
    name: "batch.title",
    sortable: true,
    body: showBatchName,
  },
  {
    header: "Club",
    name: "club",
    sortable: true,
    body: showClubName,
  },
];

const fieldsToEdit = [
  {
    title: "Full Name",
    name: "fullName",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "City",
    name: "city",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Address",
    name: "streetAddress",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Zip Code",
    name: "zipCode",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Phone Number",
    name: "phoneNumber",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
];

const fieldsToCreate = [
  {
    title: "Full Name",
    name: "fullName",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "City",
    name: "city",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Address",
    name: "streetAddress",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Zip Code",
    name: "zipCode",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Phone Number",
    name: "phoneNumber",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
