import {
  imageShowBody,
  videoShowBody,
} from "../../components/crudTable/crudTableUtils";
import { Button } from "primereact/button";

const commisionBody = (rowData) => {
  return <p>{rowData.defaultCommision} %</p>;
};

const showPriority = (rowData) => {
  return <p>{rowData.priority || 0}</p>;
};

const partnerTypes = [
  { name: "Active" },
  { name: "InActive" },
  { name: "ComingSoon" },
];

const showPartnerType = (rowData) => {
  return (
    <Button
      label={rowData.partnerType}
      className={`${
        rowData.partnerType === "InActive"
          ? "p-button-danger"
          : rowData.partnerType === "Active"
          ? "p-button-success"
          : "p-button-info"
      }`}
    />
  );
};

const fieldsToShow = [
  {
    header: "Logo",
    name: "logoImage",
    type: "image",
  },
  {
    header: "Company Name",
    name: "companyName",
  },
  {
    header: "Priority",
    name: "priority",
    body: showPriority,
    sortable: true,
  },
  {
    header: "Default Commision",
    name: "defaultCommision",
    body: commisionBody,
  },
  {
    header: "Partner type",
    name: "partnerType",
    body: showPartnerType,
  },
];

const fieldsToEdit = [
  {
    name: "companyName",
    title: "Company Name",
    type: "string",
  },
  {
    name: "logoImage",
    title: "Logo Image",
    type: "image",
  },
  {
    name: "logoImage",
    title: "",
    body: imageShowBody,
  },
  {
    name: "image",
    title: "Image",
    type: "imageCrop",
  },
  {
    name: "image",
    title: "",
    body: imageShowBody,
  },
  {
    name: "partnerType",
    title: "Partner type",
    type: "dropdown",
    rules: { required: "Partner type is required" },
    props: {
      options: partnerTypes,
      optionLabel: "name",
      optionValue: "name",
      placeholder: "Select partner type...",
    },
  },
  {
    name: "defaultCommision",
    title: "Default Commision",
    type: "number",
  },
  {
    name: "specialConditions",
    title: "Special Conditions",
    type: "textarea",
  },
  {
    name: "description",
    title: "Description",
    type: "textarea",
    rules: {
      maxLength: 1000,
      required: true,
    },
  },
  {
    name: "apiKey",
    title: "Api Key",
    type: "string",
  },
  {
    name: "webUrl",
    title: "Web Url",
    type: "string",
  },
  {
    name: "priority",
    title: "Priority",
    type: "number",
  },
  {
    name: "tileSize",
    title: "Tile Size",
    type: "number",
  },
  {
    name: "fileVideo",
    title: "Video",
    type: "video",
  },
  {
    name: "video",
    title: "",
    body: videoShowBody,
  },
];
const fieldsToCreate = [
  {
    name: "companyName",
    title: "Company Name",
    type: "string",
    rules: {
      required: "Company Name is required",
    },
  },
  {
    name: "logoImage",
    title: "Logo Image",
    type: "image",
  },
  {
    name: "image",
    title: "Image",
    type: "imageCrop",
  },
  {
    name: "defaultCommision",
    title: "Default Commision",
    type: "number",
    rules: {
      required: "Commision is required",
      min: 1,
    },
  },
  {
    name: "specialConditions",
    title: "Special Conditions",
    type: "textarea",
  },
  {
    name: "description",
    title: "Description",
    type: "textarea",
    rules: {
      required: "Description is required",
      maxLength: 1000,
    },
  },
  {
    name: "apiKey",
    title: "Api Key",
    type: "string",
  },
  {
    name: "webUrl",
    title: "Web Url",
    type: "string",
  },
  {
    name: "priority",
    title: "Priority",
    type: "number",
  },
  {
    name: "tileSize",
    title: "Tile Size",
    type: "number",
  },
  {
    name: "fileVideo",
    title: "Video",
    type: "video",
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
