import React, { useRef } from "react";
import NotificationContext from "./NotificationContext";
import { Toast } from "primereact/toast";

const NotificationWrapper = ({ children }) => {
  const toast = useRef(null);

  const showError = (
    message = "An error occured",
    title = "Error",
    life = 5000
  ) => {
    toast.current?.show({
      severity: "error",
      summary: title,
      detail: message,
      life: life,
    });
  };

  const showSuccess = (
    message = "Success",
    title = "success",
    life = 5000
  ) => {
    toast.current.show({
      severity: "success",
      summary: title,
      detail: message,
      life: life,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        showError,
        showSuccess
      }}
    >
      <Toast ref={toast} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationWrapper;
