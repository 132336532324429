import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";

const ratios = [
  {
    value: 16 / 9,
    label: "16 / 9",
  },
  {
    value: 4 / 3,
    label: "4 / 3",
  },
  {
    value: 3 / 3,
    label: "3 / 3",
  },
];

const ImageCropDialog = ({
  image,
  setCroppedImg,
  setImage,
  isEdit,
  imageName,
  onCancel,
  ratio,
  hideRatios,
  hideRawUpload,
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect, setAspect] = useState(ratio || 16 / 9);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImg(croppedImageUrl);
    setImage(null);
  };

  const onRawButtonClick = async () => {
    var result = await fetch(image);
    var blob = await result.blob();
    setCroppedImg(blob);
    setImage(null);
  };

  const items = [
    {
      label: "Upload Original",
      icon: "pi pi-refresh",
      command: onRawButtonClick,
    },
  ];

  return (
    <div>
      <div className="crop-container">
        <Cropper
          image={image}
          zoom={zoom}
          crop={crop}
          aspect={aspect}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />

        {!hideRatios && (
          <Dropdown
            options={ratios}
            optionValue="value"
            optionLabel="label"
            className="cropInput"
            value={aspect}
            onChange={(e) => setAspect(e.value)}
          ></Dropdown>
        )}

        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-outlined close-dialog-button"
          aria-label="button"
          onClick={() => onCancel()}
        />

        <div className="uploadImageButtons">
          <SplitButton
            label="Choose photo"
            icon="pi pi-upload"
            disabled={!isEdit && !imageName}
            onClick={() => onCrop()}
            model={items}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCropDialog;
