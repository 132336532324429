import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import CrudTable from "../../components/crudTable/CrudTable";
import transactionsService from "../../services/transactions/transactionsService";
import partnersService from "../../services/partners/partnersService";
import authenthicationService from "../../services/authentication/authenticationService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToShow } from "./transactionsConfig";
import { Controller } from "react-hook-form";
import { useSearchParams } from "react-router-dom/dist";
import { getCardNumber } from "../../utils/cardsUtils";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPartner, setIsPartner] = useState(false);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  let timer;

  useEffect(() => {
    authenthicationService.getCurrentLoginInfo().then((res) => {
      setPartner(res?.user?.partnerId);
      setIsPartner(res?.user?.partnerId != null);
    });
  }, []);

  useEffect(() => {
    partnersService
      .getAll({
        hasNoPaginator: true,
        hasNoImages: true,
        sorting: "companyName%20ASC",
      })
      .then((res) => {
        setPartners(res.items);
      });
  }, []);

  useEffect(() => {
    var partnerStaffId = searchParams.get("partnerStaff");

    getAllTransactions({ userPartnerStaffId: partnerStaffId });
  }, [lazyParams.first, lazyParams.rows, globalFilter, lazyParams.sortField]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllTransactions = (options) => {
    setLoading(true);
    transactionsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        Sorting: lazyParams.sortField,
        ...options,
      })
      .then((trans) => {
        setTransactions(trans.items);
        setLazyParams({ ...lazyParams, totalRecords: trans.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleCard = async (id) => {
    const cardData = await transactionsService.get({ id });
    return cardData;
  };

  const onSort = (field) => {
    setLazyParams({ ...lazyParams, sortField: field });
  };

  const onCreateSubmit = (data) => {
    data.partnerId = partner;
    data.cardNumber = getCardNumber(data.cardNumber);
    transactionsService
      .create(data)
      .then((res) => {
        getAllTransactions();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const partnersEditBody = (data) => {
    return (
      <span className="p-fluid">
        <Dropdown
          options={partners}
          disabled={isPartner}
          optionValue="id"
          className="mx-0"
          optionLabel="companyName"
          placeholder="Select partner..."
          value={partner}
          onChange={(e) => setPartner(e.value)}
          showClear
        />
      </span>
    );
  };

  const cardNumberEditBody = ({ name, control, data }) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputMask
            id="date"
            mask="9999-9999-9999-9999"
            placeholder="0000-0000-0000-0000"
            {...field}
          ></InputMask>
        )}
      />
    );
  };

  const fieldsToCreate = [
    {
      name: "cardNumber",
      title: "Card Number",
      type: "string",
      body: cardNumberEditBody,
      rules: {
        required: "Card Number is required",
      },
    },
    {
      name: "partners",
      title: "Partners",
      body: partnersEditBody,
    },
    {
      name: "transactionType",
      title: "Transaction Type",
      type: "dropdown",
      rules: {
        required: "Transaction Type is required",
      },
      props: {
        optionValue: "value",
        optionLabel: "label",
        showClear: false,
        options: [
          {
            label: "Credit",
            value: 1,
          },
          {
            label: "Refund",
            value: 2,
          },
        ],
      },
    },
    {
      name: "orderTotal",
      title: "OrderTotal",
      type: "number",
      rules: {
        required: "OrderTotal is required",
      },
    },
  ];

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={transactions}
        fieldsToShow={fieldsToShow}
        fieldsToCreate={fieldsToCreate}
        onCreateSubmit={onCreateSubmit}
        loading={loading}
        headerTitle="Manage Transactions"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleCard}
        canDelete={false}
        canEdit={false}
        onSort={onSort}
        defaultValues={{
          transactionType: 1,
        }}
      />
    </div>
  );
};

export default Transactions;
