import http from "../http/httpService";

class OnboardingService {
  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Onboarding/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async get(params) {
    let result = await http.get("api/services/app/Onboarding/GetById", {
      params,
    });
    return result.data.result;
  }

  async update(updateOnboardingInput) {
    let result = await http.put(
      "api/services/app/Onboarding/Update",
      updateOnboardingInput
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Onboarding/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async create(createOnboardingInput) {
    let result = await http.post(
      "api/services/app/Onboarding/Create",
      createOnboardingInput
    );
    return result.data.result;
  }
}

export default new OnboardingService();
