import React, { useEffect, useRef, useState } from "react";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import cardsService from "../../services/cards/cardsService";
import { ProgressBar } from "primereact/progressbar";
import download from "downloadjs";
import { InputText } from "primereact/inputtext";

const stepModel = [
  {
    label: "Delivery Documents",
  },
  {
    label: "Activate Cards",
  },
];

const ActivateDialog = ({ cards, cardType, refetchCards, setShowDialog }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div>
      <Steps model={stepModel} activeIndex={activeIndex} readOnly={true} />
      <br />
      {activeIndex === 0 && (
        <DownloadDeliveryDocuments
          cards={cards}
          setActiveIndex={setActiveIndex}
        />
      )}
      {activeIndex === 1 && (
        <ActivateCards
          cardType={cardType}
          cards={cards}
          refetchCards={refetchCards}
          setShowDialog={setShowDialog}
        />
      )}
    </div>
  );
};

const DownloadDeliveryDocuments = ({ cards, setActiveIndex }) => {
  const [generatingLoading, setGeneratingLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const generatePdf = async () => {
    setGeneratingLoading(true);
    let items = cards.map((i) => i.id);

    let interval = setInterval(() => {
      let part = 100 / cards?.length;
      setProgress((progress) => progress + part);
    }, 3000);

    let result = await cardsService.generateCardsWaybill({ cards: items });
    download(result?.data, "cardsWaybill.pdf", "application/pdf");
    setGeneratingLoading(false);
    clearInterval(interval);
    setProgress(0);
  };

  return (
    <div>
      {generatingLoading && <ProgressBar value={progress}></ProgressBar>}
      <br />
      <p>
        To generate a PDF containing delivery documents for{"  "}
        <b>{cards?.length} cards</b>, click the "Generate PDF" button below.
        This may take a while! Please wait.
      </p>
      <Button
        label={`Generate PDF for ${cards?.length} cards`}
        disabled={generatingLoading}
        loading={generatingLoading}
        onClick={() => generatePdf()}
        className="p-button p-button-help"
      />
      <Button
        label="Next"
        icon="pi pi-arrow-right"
        onClick={() => setActiveIndex((idx) => idx + 1)}
        className="p-button-secondary ml-3"
      />
    </div>
  );
};

const ActivateCards = ({ cardType, cards, refetchCards, setShowDialog }) => {
  const [dict, setDict] = useState({});
  const [loading, setLoading] = useState(false);
  const [physicalCardNumber, setPhysicalCardNumber] = useState("");
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);

  let card = cards[index];

  let inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
    setPhysicalCardNumber(dict[cards[index]?.id]?.number || "");
  }, [index, cards, dict]);

  let handleActivateSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(false);

    let response = await cardsService
      .activate({
        cardId: card?.id,
        physicalCardNumber: physicalCardNumber,
      })
      .catch((e) => {
        setError(e.response.data?.error?.message);
        setPhysicalCardNumber("");
        setLoading(false);
      });

    if (!response) {
      return;
    }

    setDict((dict) => ({
      ...dict,
      [card.id]: { number: physicalCardNumber, activated: true },
    }));
    if (index !== cards.length - 1) {
      setIndex((idx) => idx + 1);
    }
  };

  const handleFinishBtnClicked = () => {
    refetchCards();
    setShowDialog(false);
  };

  return (
    <div className="flex flex-column">
      <h3 className="align-self-end mr-5">
        {`${index + 1} / ${cards.length}`}{" "}
      </h3>
      <br />
      <div className="flex align-items-center justify-content-around mb-3">
        <div>
          <img
            src={cardType.image?.imageUrl || `data:image/jpeg;base64,${cardType.image?.contents}`}
            alt={cardType.displayName}
            width={"250px"}
          />
        </div>
        <div
          className="flex flex-column"
          style={{ maxWidth: "350px", width: "100%" }}
        >
          <h3 className="mb-3">{card?.fullName || "Undefined Name"}</h3>
          <form onSubmit={handleActivateSubmit}>
            <InputText
              ref={inputRef}
              value={physicalCardNumber}
              onChange={(e) => {
                setPhysicalCardNumber(e.target.value);
                setError("");
              }}
              disabled={dict[card?.id]?.activated || loading}
              placeholder="Enter the physical number"
              style={{ width: "100%" }}
            />
          </form>
          <div>
            <Button
              icon="pi pi-arrow-left"
              onClick={() => setIndex((idx) => idx - 1)}
              className="p-button-secondary mt-3 mr-3"
              disabled={index === 0}
            />
            <Button
              icon="pi pi-arrow-right"
              onClick={() => setIndex(index + 1)}
              className="p-button-secondary mt-3"
              disabled={index === cards.length - 1}
            />
          </div>
          {error && (
            <div
              className="mt-3"
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "6px 8px",
                borderRadius: "5px",
                textAlign: "center",
                fontWeight: "600",
                letterSpacing: "0.1px",
                width: "100%",
              }}
            >
              {error}
            </div>
          )}
          {index === cards.length - 1 && (
            <Button
              label="Finish"
              className="mt-3"
              onClick={handleFinishBtnClicked}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivateDialog;
