import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { imageShowBody } from "../../components/crudTable/crudTableUtils";
import { OverlayPanel } from "primereact/overlaypanel";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

/**
 * The function generates a base64 encoded string for a prize video description.
 * @param clubPackageName - The `clubPackageName` parameter represents the name of the club package. It
 * is a string value that specifies the package name of the club.
 * @param prizeId - The `prizeId` parameter is the unique identifier of a prize. It is used to generate
 * a description for a prize video.
 * @returns a base64 encoded string.
 */
export const generatePrizeVideoDescription = (clubPackageName, prizeId) => {
  let description = `${clubPackageName}/en,bg/FreePrizeVideo/${prizeId}`;
  let base64 = btoa(description);
  // this was also not working how to do it properly using react

  return base64;
};

const prizeImageShowBody = (rowData) => {
  let prizeImages = rowData.data?.prizeImages || [];
  return (
    <div>
      <h5>Prize Images:</h5>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {prizeImages?.map((prizeImage) => (
          <img
            key={prizeImage?.imageId}
            src={`data:image/png;base64,${prizeImage?.image?.contents}`}
            alt="prize details"
            style={{ maxWidth: "100px", objectFit: "contain" }}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * The `prizeEditVideoBtn` function generates a YouTube video description based on the provided
 * `packageName` and `prizeId`, and copies it to the clipboard when the button is clicked.
 * @returns The code is returning a Button component with a label, icon, and onClick event handler.
 */
const prizeEditVideoBtn = ({ data }) => {
  if (!data) return;

  let packageName = data.club?.packageName;
  let prizeId = data.id;

  const handleClick = () => {
    let base64 = generatePrizeVideoDescription(packageName, prizeId);
    navigator.clipboard.writeText(base64);
  };
  return (
    <Button
      type="button"
      label="Copy YouTube video description"
      icon="fa-solid fa-copy"
      severity="help"
      onClick={handleClick}
    />
  );
};

/**
 * The function `showStatus` returns a button with a label indicating the status of a given row of
 * data, and the button's color is determined based on the status.
 * @param rowData - The `rowData` parameter is an object that contains information about a particular
 * row of data. It likely has properties such as `isActive`, `winnerAccountId`, `endDate`, and
 * `startDate`.
 * @returns a Button component with a label and a className. The label is set to the value of the
 * "status" variable, and the className is set to "p-button-" followed by the value of the "color"
 * variable.
 */
const showStatus = (rowData) => {
  let status = "";
  let color = "help";
  if (rowData.winnerAccountId) {
    status = `Winner - ${rowData.winnerVideoUrl ? "Video" : "No video"}`;
  } else if (!rowData.isActive) {
    status = "Deactivated";
    color = "danger";
  } else if (
    new Date(rowData.endDate) > new Date() &&
    new Date(rowData.startDate) < new Date()
  ) {
    status = "Started";
    color = "info";
  } else if (new Date(rowData.endDate) < new Date()) {
    status = "Finished";
    color = "secondary";
  } else if (new Date(rowData.startDate) > new Date()) {
    status = "Draft";
    color = "secondary";
  }
  return <Button label={status} className={`p-button-${color}`} />;
};

const PrizeEntries = ({ data }) => {
  const op = useRef(null);
  return (
    <div>
      <Button
        type="button"
        icon="pi pi-search"
        label="Prize Entries"
        onClick={(e) => op.current.toggle(e)}
      />

      <OverlayPanel ref={op} showCloseIcon>
        <DataTable
          value={data.prizeEntries}
          selectionMode="single"
          paginator
          rows={10}
        >
          <Column
            field="user.fullName"
            header="Name"
            sortable
            style={{ minWidth: "15rem" }}
          />
          <Column
            field="user.email"
            header="Email"
            sortable
            style={{ minWidth: "15rem" }}
          />
        </DataTable>
      </OverlayPanel>
    </div>
  );
};

const fieldsToShow = [
  {
    header: "Image",
    name: "image",
    type: "image",
  },
  {
    header: "Title",
    name: "prizeTitle",
  },
  {
    header: "Partner",
    name: "partner.companyName",
  },
  {
    header: "Status",
    name: "status",
    body: showStatus,
  },
  {
    header: "Start Date",
    name: "startDate",
    body: (rowData) => <p>{new Date(rowData.startDate).toDateString()}</p>,
  },
  {
    header: "End Date",
    name: "endDate",
    body: (rowData) => <p>{new Date(rowData.endDate).toDateString()}</p>,
  },
];

export { fieldsToShow };
