import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Error } from "./demo/demoPages/Error";
import { NotFound } from "./demo/demoPages/NotFound";
import { Access } from "./demo/demoPages/Access";
import MainLayout from "./layouts/MainLayout";
import { Login } from "./pages/Login/Login";
import Partners from "./pages/Partners/Partners";
import Clubs from "./pages/Clubs/Clubs";
import Images from "./pages/Images/Images";
import ProtectedRoute from "./components/routes/ProtectedRoutes";
import Tenants from "./pages/Tenants/Tenants";
import Roles from "./pages/Roles/Roles";
import Users from "./pages/Users/Users";
import Settings from "./pages/Settings/Settings";
import Profile from "./pages/Profile/Profile";
import Cards from "./pages/Cards/Cards";
import Transactions from "./pages/Transactions/Transactions";
import Videos from "./pages/Videos/Videos";
import PartnerClubMapping from "./pages/PartnerClubMapping/PartnerClubMapping";
import Prizes from "./pages/Prizes/Prizes";
import CardBatches from "./pages/CardBatches/CardBatches";
import Confirm from "./pages/Confirm/Confirm";
import Notifications from "./pages/Notifications/Notifications";
import CardTypes from "./pages/CardTypes/CardTypes";
import Onboarding from "./pages/Onboarding/Onboarding";
import Campaigns from "./pages/Campaigns/Campaigns";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ForgotPasswordReset from "./pages/ForgotPasswordReset/ForgotPasswordReset";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Tickets from "./pages/Tickets/Tickets";
import ScreenLayouts from "./pages/ScreenLayouts/ScreenLayouts";
import StaticContent from "./pages/StaticContent/StaticContent";
import PrizeDetails from "./pages/PrizeDetails/PrizeDetails";
import Translations from "./pages/Translations/Translations";
import Languages from "./pages/Languages/Languages";
import Dashboard from "./pages/Dashboard/Dashboard";
import Environments from "./pages/Environments/Environments";
import StaffTransaction from "./pages/StaffTransactions/StaffTransaction";
import Team from "./pages/Settings/Team/Team";
import ClubWizard from "./pages/ClubWizard/ClubWizard";
import DatabaseSynchronizer from "./pages/DatabaseSynchronizer/DatabaseSynchronizer";
import ActivateCard from "./pages/ActivateCard/ActivateCard";
import ClubAdmins from "./pages/ClubAdmins/ClubAdmins";
import PartnerApplication from "./pages/PartnerApplications/PartnerApplications";
import { CreatePrize } from "./pages/Prizes/CreatePrize";
import { UpdatePrize } from "./pages/Prizes/UpdatePrize";
import { ClubPrizeReport } from "./pages/Prizes/ClubPrizeReport";

export const ctx = React.createContext();

const RoutesComponent = (props) => {
  const [colorScheme, setColorScheme] = useState("light");
  const location = useLocation();

  return (
    <Routes>
      <Route path="/login" element={<Login colorScheme={colorScheme} />} />
      <Route
        path="/forgot-password"
        element={<ForgotPassword colorScheme={colorScheme} />}
      />
      <Route
        path="/change-password"
        element={<ChangePassword colorScheme={colorScheme} />}
      />
      <Route
        path="/account/forgot-password/:resetCode"
        element={<ForgotPasswordReset colorScheme={colorScheme} />}
      />
      <Route
        path="/verification/email/:id"
        element={<Confirm colorScheme={colorScheme} />}
      />
      <Route path="/error" element={<Error colorScheme={colorScheme} />} />
      <Route
        path="/notfound"
        element={<NotFound colorScheme={colorScheme} />}
      />
      <Route path="/access" element={<Access colorScheme={colorScheme} />} />
      <Route path="/" element={<MainLayout setColorScheme={setColorScheme} />}>
        <Route index element={<Dashboard />} />
        <Route path="settings" element={<Settings />} />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="partners"
          element={
            <ProtectedRoute permission={"Pages.Partners"}>
              <Partners />
            </ProtectedRoute>
          }
        />
        <Route
          path="videos"
          element={
            <ProtectedRoute permission={"Pages.Videos"}>
              <Videos />
            </ProtectedRoute>
          }
        />
        <Route
          path="prizes"
          element={
            <ProtectedRoute permission={"Pages.Prizes"}>
              <Prizes />
            </ProtectedRoute>
          }
        />
        <Route
          path="prizes/create"
          element={
            <ProtectedRoute permission={"Pages.Prize.Create"}>
              <CreatePrize />
            </ProtectedRoute>
          }
        />
        <Route
          path="prizes/update/:id"
          element={
            <ProtectedRoute permission={"Pages.Prize.Edit"}>
              <UpdatePrize />
            </ProtectedRoute>
          }
        />
        <Route path="prizes/:id" element={<PrizeDetails />} />
        <Route
          path="transactions"
          element={
            <ProtectedRoute permission={"Pages.Transactions"}>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="translations"
          element={
            <ProtectedRoute permission={"Pages.Translations"}>
              <Translations />
            </ProtectedRoute>
          }
        />
        <Route
          path="databaseSynchronizer"
          element={
            <ProtectedRoute permission={"Pages.DatabaseSynchronize.Sync"}>
              <DatabaseSynchronizer />
            </ProtectedRoute>
          }
        />
        <Route
          path="activateCard"
          element={
            <ProtectedRoute permission={"Pages.Card.Activate"}>
              <ActivateCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="translations"
          element={
            // <ProtectedRoute permission={"Pages.Transactions"}>
            <Translations />
            // </ProtectedRoute>
          }
        />
        <Route
          path="languages"
          element={
            <ProtectedRoute permission={"Pages.Languages"}>
              <Languages />
            </ProtectedRoute>
          }
        />
        <Route
          path="cards"
          element={
            <ProtectedRoute permission={"Pages.Cards"}>
              <Cards />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings/team"
          element={
            <ProtectedRoute>
              <Team />
            </ProtectedRoute>
          }
        />
        <Route
          path="batches"
          element={
            <ProtectedRoute permission={"Pages.CardsBatches"}>
              <CardBatches />
            </ProtectedRoute>
          }
        />
        <Route
          path="clubAdmins"
          element={
            <ProtectedRoute permission={"Pages.ClubAdmins"}>
              <ClubAdmins />
            </ProtectedRoute>
          }
        />
        <Route
          path="notifications"
          element={
            <ProtectedRoute permission={"Pages.Notifications"}>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="screens"
          element={
            <ProtectedRoute permission={"Pages.ScreenLayouts"}>
              <ScreenLayouts />
            </ProtectedRoute>
          }
        />
        <Route
          path="static"
          element={
            <ProtectedRoute permission={"Pages.StaticContent"}>
              <StaticContent />
            </ProtectedRoute>
          }
        />
        <Route
          path="environments"
          element={
            <ProtectedRoute permission={"Pages.Environments"}>
              <Environments />
            </ProtectedRoute>
          }
        />
        <Route
          path="card-types"
          element={
            <ProtectedRoute permission={"Pages.CardTypes"}>
              <CardTypes />
            </ProtectedRoute>
          }
        />
        <Route
          path="tickets"
          element={
            <ProtectedRoute permission={"Pages.Dynamics365Tickets"}>
              <Tickets />
            </ProtectedRoute>
          }
        />
        <Route
          path="campaigns"
          element={
            <ProtectedRoute permission={"Pages.Campaigns"}>
              <Campaigns />
            </ProtectedRoute>
          }
        />
        <Route
          path="onboarding"
          element={
            <ProtectedRoute permission={"Pages.Onboarding"}>
              <Onboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="clubs"
          element={
            <ProtectedRoute permission={"Pages.Clubs"}>
              <Clubs />
            </ProtectedRoute>
          }
        />
        <Route
          path="clubs/:id/prizes/reports"
          element={
            <ProtectedRoute permission={"Pages.Clubs"}>
              <ClubPrizeReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="images"
          element={
            <ProtectedRoute permission={"Pages.Images"}>
              <Images />
            </ProtectedRoute>
          }
        />
        <Route
          path="partnerclubmapping/:clubId"
          element={
            <ProtectedRoute permission={"Pages.PartnersClubMapping"}>
              <PartnerClubMapping />
            </ProtectedRoute>
          }
        />
        <Route
          path="staff/"
          element={
            <ProtectedRoute permission={"Pages.StaffTransactions"}>
              <StaffTransaction />
            </ProtectedRoute>
          }
        />
        <Route
          path="partnerclubmapping/"
          element={
            <ProtectedRoute permission={"Pages.PartnersClubMapping"}>
              <PartnerClubMapping />
            </ProtectedRoute>
          }
        />
        <Route
          path="partnerApplication/"
          element={
            <ProtectedRoute permission={"Pages.PartnerApplications"}>
              <PartnerApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path="tenants"
          element={
            <ProtectedRoute permission={"Pages.Tenants"}>
              <Tenants />
            </ProtectedRoute>
          }
        />
        <Route
          path="roles"
          element={
            <ProtectedRoute permission={"Pages.Roles"}>
              <Roles />
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute permission={"Pages.Users"}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="wizard"
          element={
            <ProtectedRoute permission={"Pages.Club.Wizard"}>
              <ClubWizard />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to={"/notfound"} />} />
    </Routes>
  );
};

export default RoutesComponent;
