import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../hooks/notification/useNotification";
import React, { useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import clubService from "../../../services/club/clubService";

export const UpdateFirebaseKey = ({ clubId, setIndex }) => {
  const updateFirebaseKeyStep = useForm();
  const { showSuccess } = useNotification();
  const { showError } = useNotification();
  const [updateFirebaseKeyLoading, setUpdateFirebaseKeyLoading] =
    useState(false);

  const bypassFirebaseKey = () => {
    setIndex(6);
    showSuccess("Success", "You skipped the firebase key change");
  };

  const updateFirebaseKeySubmit = (data) => {
    if (data.firebaseKey.type !== "application/json") {
      showError("Only JSON files are allowed.", "Error");
      return;
    }
    clubService
      .updateFirebaseKey({ ...data, id: clubId })
      .then(() => {
        showSuccess(
          "Success",
          "You have successfully updated the club firebase key"
        );
        setIndex(6);
        setUpdateFirebaseKeyLoading(false);
      })
      .catch(() => {
        setUpdateFirebaseKeyLoading(false);
        showError("An error occured while updating the firebase key", "Error");
      });
  };
  const onUpdateFirebaseKeySubmit = (data) => {
    data?.firebaseKey === undefined || null
      ? bypassFirebaseKey()
      : updateFirebaseKeySubmit(data);
  };
  return (
    <div className="wizard-step-six">
      <form
        onSubmit={updateFirebaseKeyStep.handleSubmit(onUpdateFirebaseKeySubmit)}
      >
        <div className="firebaseKeyField">
          <label>{"Update Firebase Key"}</label>
          <Controller
            name={"firebaseKey"}
            control={updateFirebaseKeyStep.control}
            render={({ field }) => (
              <React.Fragment>
                <FileUpload
                  disabled={updateFirebaseKeyLoading}
                  accept=".json"
                  maxFileSize={100000000}
                  mode="basic"
                  onSelect={(event) => field.onChange(event.files[0])}
                />
              </React.Fragment>
            )}
          ></Controller>
        </div>

        <Button className="mt-4" label={"Update Firebase Key"} />
      </form>
    </div>
  );
};
