import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoginWrapper from "../../components/login/LoginWrapper";
import authenticationService from "../../services/authentication/authenticationService";
import { L } from "../../utils/abpUtility";
import { useErrors } from "../../utils/error/useErrors";

const ForgotPassword = () => {
  const { register, handleSubmit, control, watch } = useForm();
  const [globalError, setGlobalError] = useState(null);
  const { checkErrors } = useErrors();
  const navigate = useNavigate();

  useEffect(() => {
    var subscription = watch(() => {
      setGlobalError(null);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const getBaseUrl = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}${process.env.PUBLIC_URL}/account/forgot-password/`;
    return url;
  };

  const onSubmit = (data) => {
    data.baseForgotPasswordUrl = getBaseUrl();
    authenticationService
      .forgotPassword(data)
      .then(() => {
        navigate("/login");
      })
      .catch((e) => {
        checkErrors(e);
        setGlobalError("Error with forgot password");
      });
  };

  return (
    <LoginWrapper showAside={false}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="logo p-link"
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="freya-layout"
            className="loginLogo"
          />
        </button>

        {globalError && <div className="login_errorPanel">{globalError}</div>}

        <h2 className="loginTitle">{L("ForgotPassword")}</h2>

        <input
          type="text"
          className="loginInput"
          placeholder={L("Email:")}
          {...register("emailAddress", { required: true })}
        />

        {/* <Link to={"/forgot-password"} className="loginForgot">
          <button type="button">{L("ForgotPassword")}</button>
        </Link> */}
        <Button
          label="Forgot Password"
          type="submit"
          className="loginSubmitBtn"
        ></Button>
      </form>
    </LoginWrapper>
  );
};

export default ForgotPassword;
