import http from "../http/httpService";

class PartnerClubMapping {
  async create(createPartnerClubMappingInput) {
    let result = await http.post(
      "api/services/app/PartnersClubMapping/CreateOrUpdate",
      createPartnerClubMappingInput
    );
    return result.data.result;
  }

  async createOrUpdatePartnerToClub(createPartnerClubMappingInput) {
    let result = await http.post(
      "api/services/app/PartnersClubMapping/CreateOrUpdatePartnerToClub",
      createPartnerClubMappingInput
    );
    return result.data.result;
  }

  async reordering(reorderingInput) {
    let result = await http.post(
      "api/services/app/PartnersClubMapping/Reordering",
      reorderingInput
    );
    return result.data.result;
  }

  async getAll() {
    let result = await http.get("api/services/app/PartnersClubMapping/GetAllByClubId");
    return result.data.result;
  }

  async getAllByClubId(clubId) {
    let result = await http.get(
      "api/services/app/PartnersClubMapping/GetAllByClubId",
      {
        params: {
          clubId,
        },
      }
    );
    return result.data.result;
  }
}

export default new PartnerClubMapping();
