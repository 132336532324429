import http from "../http/httpService";

class DashboardService {
  async getDashboard(params) {
    let result = await http.get("api/services/app/Reports/GetDashboard", {
      params,
    });
    return result.data.result;
  }
}
export default new DashboardService();
