import React, { useState } from "react";
import { useForm } from "react-hook-form";
import clubService from "../../../services/club/clubService";
import { Button } from "primereact/button";
import { useNotification } from "../../../hooks/notification/useNotification";
import { ImageUploader } from "../components/ImageUploader";

const allImageFields = [
  {
    field: "logoImage",
    label: "Logo Image",
    preview: "logoImage.png",
  },
  {
    field: "officialClubLogoImage",
    label: "Official Club Logo",
  },
  {
    field: "backgroundImage",
    label: "Background Image",
    preview: "backgroundImage.png",
  },
  {
    field: "largeBackgroundImage",
    label: "Large Background Image",
  },
  {
    field: "orderYourCardImage",
    label: "Order Your Card Image",
    preview: "orderYourCardImage.png",
  },
  {
    field: "supportYourTeamImage",
    label: "Support Your Team Image",
    preview: "supportYourTeamImage.png",
  },
  {
    field: "getRewardsImage",
    label: "Get Rewards Image",
    preview: "getRewardsImage.png",
  },
  {
    field: "downLoadAppImage",
    label: "DownLoad App Image",
    preview: "downloadAppImage.png",
  },
  {
    field: "loginLogoImage",
    label: "Login Logo Image",
    preview: "loginLogoImage.png",
  },
  {
    field: "loginBackgroundImage",
    label: "Login Background Image",
    preview: "loginBackgroundImage.png",
  },
];

export const UploadImagesStep = ({ clubId, setIndex }) => {
  const [uploadImagesLoading, setUploadImagesLoading] = useState(false);
  const { showError } = useNotification();
  const uploadImageStep = useForm();

  const onUploadImageSubmit = (data) => {
    setUploadImagesLoading(true);
    clubService
      .updateImages({ ...data, id: clubId })
      .then(() => {
        setIndex(3);
        setUploadImagesLoading(false);
      })
      .catch(() => {
        setUploadImagesLoading(false);
        showError("An error occured while updating the images", "Error");
      });
  };
  return (
    <div className="widget-upload-image">
      <h2>Upload images</h2>
      <form onSubmit={uploadImageStep.handleSubmit(onUploadImageSubmit)}>
        {allImageFields.map((field) => (
          <ImageUploader
            field={field}
            key={field.field}
            loading={uploadImagesLoading}
            control={uploadImageStep.control}
            style={{
              width: "350px",
            }}
          />
        ))}
        <Button
          label="Upload all Images"
          loading={uploadImagesLoading}
          disabled={uploadImagesLoading}
        />
      </form>
    </div>
  );
};
