import React from "react";

const ThemeContext = React.createContext("light");

const changeStyleSheetsColor = (id, value, from) => {
  const element = document.getElementById(id);
  const urlTokens = element.getAttribute("href").split("/");

  if (from === 1) {
    urlTokens[urlTokens.length - 1] = value;
  } else if (from === 2) {
    urlTokens[urlTokens.length - 2] = value;
  }

  const newURL = urlTokens.join("/");

  replaceLink(element, newURL);
};

const isIE = () => {
  return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
};

const replaceLink = (linkElement, href) => {
  if (isIE()) {
    linkElement.setAttribute("href", href);
  } else {
    const id = linkElement.getAttribute("id");
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute("href", href);
    cloneLinkElement.setAttribute("id", id + "-clone");

    linkElement.parentNode.insertBefore(
      cloneLinkElement,
      linkElement.nextSibling
    );

    cloneLinkElement.addEventListener("load", () => {
      linkElement.remove();
      cloneLinkElement.setAttribute("id", id);
    });
  }
};

export { changeStyleSheetsColor };
export default ThemeContext;
