import http from "../http/httpService";

class ClubAdminsService {
  async create(createAdminInput) {
    let result = await http.post(
      "api/services/app/ClubAdmins/Create",
     createAdminInput 
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/ClubAdmins/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/ClubAdmins/Get", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/ClubAdmins/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updatePartnerInput) {
    let result = await http.put(
      "api/services/app/ClubAdmins/Update",
      updatePartnerInput
    );
    return result.data.result;
  }
}

export default new ClubAdminsService();
