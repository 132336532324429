import http from "../http/httpService";

class CardsService {
  async get(entityDto) {
    let result = await http.get("api/services/app/Cards/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async generateCardsWaybill(entityDto) {
    let result = await http.post(
      "api/services/app/Cards/GenerateCardsWaybill",
      entityDto,
      {
        responseType: "blob",
      }
    );
    return result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Cards/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async activate(params) {
    let result = await http.post(
      "api/services/app/Cards/ActivateCard",
      {},
      {
        params,
      }
    );
    return result.data.result;
  }
}

export default new CardsService();
