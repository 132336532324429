import { Button } from "primereact/button";

const showDateTimeField = (rowData) => {
  return (
    <Button
      label={new Date(rowData.date).toDateString()}
      className="p-button-success"
    />
  );
};

const fieldsToShow = [
  {
    header: "Title",
    name: "title",
  },
  {
    header: "Description",
    name: "description",
  },
  {
    header: "Number Of Cards",
    name: "numberOfCards",
  },
  {
    header: "Card Type Id",
    name: "cardTypeId",
  },
  {
    header: "Date",
    name: "date",
    body: showDateTimeField,
  },
];

const fieldsToEdit = [
  {
    title: "Title",
    name: "title",
    type: "string",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Description",
    name: "description",
    type: "textarea",
    rules: {
      required: "This is required",
    },
  },
  {
    title: "Date",
    name: "date",
    type: "calendar",
    rules: {
      required: "This is required",
    },
  },
];

const fieldsToCreate = [
  {
    name: "title",
    title: "Title",
    type: "string",
    rules: {
      required: "Title is required",
    },
  },
  {
    name: "description",
    title: "Description",
    type: "string",
    rules: {
      required: "Description is required",
    },
  },
  {
    name: "date",
    title: "Date",
    type: "calendar",
    rules: {
      required: "Date is required",
    },
  },
  {
    name: "numberOfCards",
    title: "NumberOfCards",
    type: "number",
    rules: {
      required: "NumberOfCards is required",
    },
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
