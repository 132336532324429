import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import staticContentService from "../../services/staticContent/staticContentService";
import languagesService from "../../services/languages/languagesService";
import { useErrors } from "../../utils/error/useErrors";

const StaticContent = () => {
  const [staticContents, setStaticContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [languages, setLanguages] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    languagesService.getAll().then((data) => {
      setLanguages(data.items);
    });
  }, []);

  useEffect(() => {
    getAllStaticContents();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllStaticContents = () => {
    setLoading(true);
    staticContentService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((contents) => {
        setStaticContents(contents.items);
        setLazyParams({ ...lazyParams, totalRecords: contents.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    staticContentService
      .create(data)
      .then((res) => {
        getAllStaticContents();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    staticContentService
      .update(data)
      .then(() => {
        getAllStaticContents();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    staticContentService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllStaticContents();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleStaticContent = async (id) => {
    const screen = await staticContentService.get({ id });
    return screen;
  };

  const showLanguageName = (rowData) => {
    return (
      rowData.language && (
        <Button label={rowData.language.name} className="p-button-help" />
      )
    );
  };

  const fieldsToShow = [
    {
      header: "Internal",
      name: "internal",
    },
    {
      header: "Title",
      name: "title",
    },
    {
      header: "Language",
      name: "language.name",
      body: showLanguageName,
    },
  ];

  const fieldsToEdit = [
    {
      name: "internal",
      title: "Internal",
      type: "string",
      rules: {
        required: "Internal is required",
      },
    },
    {
      name: "title",
      title: "Title",
      type: "string",
      rules: {
        required: "Title is required",
      },
    },
    {
      name: "languageId",
      title: "Language",
      type: "dropdown",
      rules: { required: "Language is required" },
      props: {
        options: languages,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "Select language...",
      },
    },
    {
      name: "content",
      title: "Content",
      type: "editor",
      rules: {
        required: "Content is required",
      },
    },
  ];

  const fieldsToCreate = [
    {
      name: "internal",
      title: "Internal",
      type: "string",
      rules: {
        required: "Internal is required",
      },
    },
    {
      name: "title",
      title: "Title",
      type: "string",
      rules: {
        required: "Title is required",
      },
    },
    {
      name: "languageId",
      title: "Language",
      type: "dropdown",
      rules: { required: "Language is required" },
      props: {
        options: languages,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "Select language...",
      },
    },
    {
      name: "content",
      title: "Content",
      type: "editor",
      rules: {
        required: "Content is required",
      },
    },
  ];

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={staticContents}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Static Contents"
        setLoading={setLoading}
        lazy={true}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleStaticContent}
        editPermission="Pages.StaticContent.Edit"
        createPermission="Pages.StaticContent.Create"
        deletePermission="Pages.StaticContent.Delete"
      />
    </div>
  );
};

export default StaticContent;
