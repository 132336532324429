import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Fragment, useRef } from "react";
import { Controller } from "react-hook-form";
import { OverlayPanel } from "primereact/overlaypanel";

export const VideoUploader = ({ field, control, loading }) => {
  const op = useRef();
  return (
    <div key={field.field} className="field">
      <div className="input">
        <label>{field.label}</label>
        <Controller
          name={field.field}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FileUpload
                  disabled={loading}
                  accept="video/*, .json,application/json"
                  maxFileSize={100000000}
                  mode="basic"
                  onSelect={(event) => onChange(event.files[0])}
                />

                {field.preview && (
                  <Fragment>
                    <OverlayPanel ref={op}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/preview/wizard/${field.preview}`}
                        alt="preview image"
                        style={{ maxWidth: "200px" }}
                      ></img>
                    </OverlayPanel>
                    <Button
                      icon="fa fa-info"
                      onClick={(e) => op.current.toggle(e)}
                      className="p-button-rounded p-button-info p-button-outlined"
                      type="button"
                    />
                  </Fragment>
                )}
              </div>
              {value && <img src={value?.objectURL} alt={field.label} />}
            </Fragment>
          )}
        ></Controller>
      </div>
    </div>
  );
};
