import http, { getFormData } from "../http/httpService";
import imagesService from "../images/imagesService";
import partnerClubMappingService from "../partnerClubMapping/partnerClubMappingService";

class ClubService {
  async create(createClubInput) {
    const formData = getFormData(createClubInput);

    let result = await http.post("api/services/app/Clubs/Create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Clubs/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Clubs/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Clubs/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updateClubInput) {
    const formData = getFormData(updateClubInput);

    let result = await http.put("api/services/app/Clubs/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    await partnerClubMappingService.create(updateClubInput.partnerClubMappings);

    return result.data.result;
  }

  async updateOnboarding(updateOnboardingInput) {
    const formData = getFormData(updateOnboardingInput, true);

    let result = await http.put(
      "api/services/app/Clubs/UpdateOnboardings",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updatePrizes(updatePrizesInput) {
    const formData = getFormData(updatePrizesInput, true);

    let result = await http.put(
      "api/services/app/Clubs/UpdatePrizes",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updateLandingPage(updateLandingPageInput) {
    const formData = getFormData(updateLandingPageInput, true);

    let result = await http.put(
      "api/services/app/Clubs/UpdateLandingPage",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updateCampaign(updateCampaignInput) {
    const formData = getFormData(updateCampaignInput, true);

    let result = await http.put(
      "api/services/app/Clubs/UpdateCampaign",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updateImages(updateImagesInput) {
    const formData = getFormData(updateImagesInput);

    let result = await http.put(
      "api/services/app/Clubs/UpdateImages",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updateCards(updateCardsInput) {
    const formData = getFormData(updateCardsInput, true);

    let result = await http.put(
      "api/services/app/Clubs/UpdateCards",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async updateFirebaseKey(updateFirebaseKeyInput) {
    const formData = getFormData(updateFirebaseKeyInput);

    let result = await http.put(
      "api/services/app/Clubs/UpdateFirebaseKey",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  /**
   * It takes a body, sends it to the server {clubId: Guid}, and returns the result
   * @param body - The body of the request.
   * @returns The result of the post request.
   */
  async generateScreens(body) {
    let result = await http.post(
      "api/services/app/Clubs/GenerateScreens",
      body
    );
    return result.data.result;
  }

  async getClubsOverview() {
    let result = await http.get("api/services/app/Clubs/GetClubOverview");
    let items = result.data.result.filter((i) => i?.imageId);
    let data = items.map((i) => imagesService.getImageUrl(i.imageId));

    return data;
  }
}

export default new ClubService();
