import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import authenticationService from "../../services/authentication/authenticationService";
import LoginWrapper from "../../components/login/LoginWrapper";
import ClientsPartnersLogin from "../../components/login/ClientsPartnersLogin";
import ClubLogin from "../../components/login/ClubLogin";
import { useErrors } from "../../utils/error/useErrors";
import { L } from "../../utils/abpUtility";
import partnersService from "../../services/partners/partnersService";
import ThemeContext from "../../context/theme/ThemeContext";
import clubService from "../../services/club/clubService";
import { useQuery } from "@tanstack/react-query";

export const Login = () => {
  const { register, handleSubmit, control, watch } = useForm();
  const [globalError, setGlobalError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { checkErrors } = useErrors();
  const navigate = useNavigate();
  const { colorScheme } = useContext(ThemeContext);
  const { data: partners } = useQuery(
    ["partnersOverview"],
    partnersService.getPartnersOverview,
    {
      refetchOnWindowFocus: false,
      cacheTime: 300000,
    }
  );

  const { data: clubs } = useQuery(
    ["clubsOverview"],
    clubService.getClubsOverview,
    {
      refetchOnWindowFocus: false,
      cacheTime: 300000,
    }
  );

  useEffect(() => {
    const subscription = watch(() => {
      setGlobalError(null);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onSubmit = (data) => {
    setLoading(true);
    data.rememberMe = true;

    authenticationService
      .login(data)
      .then(() => {
        navigate("/?clearCache=true");
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
        setGlobalError("Wrong email or password");
      });
  };

  const AsideComponent = !window.club
    ? () => <ClientsPartnersLogin clubs={clubs} partners={partners} />
    : () => <ClubLogin partners={partners} />;

  const logoImage = colorScheme === "dark" ? "logo-dark.png" : "logo.png";

  return (
    <LoginWrapper aside={AsideComponent} showAside={true}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <button type="button" className="logo p-link">
          <img
            src={
              !window.club || !clubs?.[0]
                ? `${process.env.PUBLIC_URL}/${logoImage}`
                : clubs[0]
            }
            alt="freya-layout"
            className="loginLogo"
          />
        </button>

        {globalError && <div className="login_errorPanel">{globalError}</div>}

        <h2 className="loginTitle">{L("Login")}</h2>

        <input
          type="text"
          className="loginInput"
          disabled={loading}
          placeholder={L("UserNameOrEmail")}
          {...register("userNameOrEmailAddress", { required: true })}
        />

        <Controller
          control={control}
          name="password"
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <input
              type="password"
              disabled={loading}
              className="loginInput"
              placeholder={L("Password")}
              {...field}
            />
          )}
        ></Controller>

        <Link to={"/forgot-password"} className="loginForgot">
          <button type="button">{L("Forgot Password")}</button>
        </Link>
        <Button
          label="LOGIN"
          type="submit"
          className="loginSubmitBtn"
          loading={loading}
          disabled={loading}
        ></Button>
      </form>
    </LoginWrapper>
  );
};
