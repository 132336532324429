import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

export const RenderTeamMembers = ({ teamMembers }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const navigate = useNavigate();
  const n = 5;

  const handleGetAllButtonClick = (userId) => {
    if (!userId) return;

    navigate(`transactions?partnerStaff=${userId}`);
  };
  return (
    <div className="team-container">
      <div className="card team dashboard-card">
        <div className="card-header">
          <Link to={"/settings/team"}>
            <div className="card-title">
              <h6 style={{ color: "black" }}>Team</h6>
              <p className="subtitle">{teamMembers?.length} People</p>
            </div>
          </Link>
        </div>
        <div className="peoples">
          {teamMembers.slice(0, 5).map((user) => (
            <div
              className={`person ${isTabletOrMobile && "disable-person-hover"}`}
              key={JSON.stringify(user)}
              onClick={() =>
                isTabletOrMobile && handleGetAllButtonClick(user?.id)
              }
            >
              <img
                src={
                  user?.profilePicture
                    ? `data:image/png;base64,${user?.profilePicture}`
                    : `${process.env.PUBLIC_URL}/images/defaultProfilePicture.jpeg`
                }
                alt="freya-layout"
              />
              <div className="info">
                <span>
                  <i className="fa fa-user"></i> {user?.fullName}
                </span>
                <Button
                  label="Get All transactions"
                  onClick={() => handleGetAllButtonClick(user?.id)}
                />
              </div>
            </div>
          ))}
          <div className="no-picture ">
            <span>
              +{teamMembers?.length - n >= 0 ? teamMembers.length : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
