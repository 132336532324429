import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CrudTable from "../../components/crudTable/CrudTable";
import clubService from "../../services/club/clubService";
import partnersService from "../../services/partners/partnersService";
import { ListBox } from "primereact/listbox";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./clubsConfig";
import { Controller } from "react-hook-form";
import { useErrors } from "../../utils/error/useErrors";
import { useNotification } from "../../hooks/notification/useNotification";

const Clubs = () => {
  const [clubs, setClubs] = useState([]);
  const [partners, setPartners] = useState([]);
  const [fieldsToEditRevised, setFields] = useState(fieldsToEdit);
  const [loading, setLoading] = useState(true);
  const { showSuccess, showError } = useNotification();
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllClubs();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const partnerClubMappingEditBody = ({ control, name, data }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={data.partnerClubMappings?.map((map) => {
          return {
            partnerId: map.partnerId,
            clubId: map.clubId,
          };
        })}
        render={({ field }) => (
          <ListBox
            {...field}
            options={partners}
            optionLabel={"companyName"}
            optionValue={"id"}
            multiple
            filter
            value={field.value.map((map) => {
              return map.partnerId ?? map;
            })}
            onChange={(e) =>
              field.onChange(
                e.value.map((map) => {
                  return {
                    partnerId: map.partnerId ?? map,
                    clubId: data.id,
                  };
                })
              )
            }
          />
        )}
      />
    );
  };

  useEffect(() => {
    setFields([
      ...fieldsToEdit,
      {
        title: "Partner Club Mapping",
        name: "partnerClubMappings",
        body: partnerClubMappingEditBody,
      },
    ]);
  }, [partners]);

  useEffect(() => {
    partnersService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setPartners(res.items);
      });
  }, []);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllClubs = () => {
    setLoading(true);
    clubService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((clubs) => {
        setClubs(clubs.items);
        setLazyParams({ ...lazyParams, totalRecords: clubs.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    clubService
      .update(data)
      .then(() => {
        getAllClubs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    clubService
      .create(data)
      .then(() => {
        getAllClubs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    setLoading(true);
    clubService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllClubs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };
  const getSingleClub = async (id) => {
    const clubData = await clubService.get({ id });
    return clubData;
  };

  const handleGenerateScreens = async (id) => {
    try {
      await clubService.generateScreens({ clubId: id });
      showSuccess("You have successfully generated the screens");
    } catch (e) {
      showError("An error occured while generating the screens");
    }
  };

  const ExtendBody = ({ data }) => {
    return (
      <React.Fragment>
        {data.id && (
          <React.Fragment>
            <Link to={"/partnerclubmapping/" + data.id}>
              <Button
                icon="pi pi-map"
                className="p-button-rounded p-button-help mt-2 ml-2"
              />
            </Link>
            <Button
              icon="fa fa-user"
              className="p-button-rounded p-button-info mt-2 ml-2"
              onClick={() => handleGenerateScreens(data.id)}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={clubs}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEditRevised}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Clubs"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleClub}
        canCreate={false}
        extendBody={ExtendBody}
        createPermission="Pages.Club.Create"
        editPermission={"Pages.Club.Edit"}
        deletePermission={"Pages.Club.Delete"}
      />
    </div>
  );
};

export default Clubs;
