import http, { getFormData } from "../http/httpService";

class UsersService {
  async create(createUserInput) {
    let result = await http.post(
      "api/services/app/User/Create",
      createUserInput
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/User/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/User/Get", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/User/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async changePassword(changePasswordInput) {
    let result = await http.post(
      "api/services/app/User/ChangePassword",
      changePasswordInput
    );
    return result.data;
  }

  async changeLanguage(changeLanguageInput) {
    let result = await http.post(
      "api/services/app/User/ChangeLanguage",
      changeLanguageInput
    );
    return result.data;
  }

  async update(updateRoleInput) {
    let result = await http.put(
      "api/services/app/User/Update",
      updateRoleInput
    );
    return result.data.result;
  }

  async updateProfilePicture(data) {
    var formData = getFormData(data);

    let result = await http.put("api/services/app/User/UpdateProfilePicture", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }
}

export default new UsersService();
