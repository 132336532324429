import {
  imageShowBody,
} from "../../components/crudTable/crudTableUtils";

const imageBody = (rowData) => {
  return (
    <img
      src={`data:image/jpeg;base64,${rowData.image?.contents}`}
      alt="notifications"
      style={{ maxWidth: "100px", borderRadius: "10px" }}
    />
  );
};

const fieldsToShow = [
  {
    header: "Image",
    name: "image",
    body: imageBody,
  },
  {
    header: "Title",
    name: "title",
  },
  {
    header: "Sub Title",
    name: "subTitle",
  },
  {
    header: "Start Date",
    name: "startDate",
    body: (rowData) => <p>{new Date(rowData.startDate).toDateString()}</p>,
  },
  {
    header: "End Date",
    name: "endDate",
    body: (rowData) => <p>{new Date(rowData.endDate).toDateString()}</p>,
  },
];

const fieldsToCreate =[
  {
    title: "Title",
    name: "title",
    type: "string",
    rules: {
      required: "Title is required",
    },
  },
  {
    title: "Sub Title",
    name: "subTitle",
    type: "string",
    rules: {
      required: "Sub title is required",
    },
  },
  {
    name: "startDate",
    title: "Start Date",
    type: "calendar",
    rules: {
      required: "Start Date is required",
    },
  },
  {
    name: "endDate",
    title: "End Date",
    type: "calendar",
    rules: {
      required: "End Date is required",
    },
  },
    {
      name: "image",
      title: "Image",
      type: "imageCrop",
    },
];

const fieldsToEdit = [
  {
    title: "Title",
    name: "title",
    type: "string",
    rules: {
      required: "Title is required",
    },
  },
  {
    title: "Sub Title",
    name: "subTitle",
    type: "string",
    rules: {
      required: "Sub title is required",
    },
  },
  {
    name: "startDate",
    title: "Start Date",
    type: "calendar",
    rules: {
      required: "Start Date is required",
    },
  },
  {
    name: "endDate",
    title: "End Date",
    type: "calendar",
    rules: {
      required: "End Date is required",
    },
  },
  {
    name: "image",
    title: "Image",
    type: "imageCrop",
  },
]

export { fieldsToShow, fieldsToEdit, fieldsToCreate };
