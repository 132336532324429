import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useUser } from "../../../hooks/user/useUser";
import usersService from "../../../services/users/usersService";
import { Dialog } from "primereact/dialog";
import "./Team.scss";
import rolesService from "../../../services/roles/rolesService";
import authenticationService from "../../../services/authentication/authenticationService";
import { useErrors } from "../../../utils/error/useErrors";
import { Toast } from "primereact/toast";

let abp = window.abp;

const Team = () => {
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState();
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { checkErrors } = useErrors();
  const user = useUser();
  const toast = useRef(null);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    rolesService.getAll().then((response) => {
      setRoles(response.items);
    });
  }, []);

  const getAllUsers = () => {
    usersService
      .getAll({
        PartnerId: user?.partnerId,
        ClubId: user?.clubId,
      })
      .then((response) => {
        setUsers(response.items);
      });
  };

  const getBaseUrl = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}${process.env.PUBLIC_URL}/verification/email/`;
    return url;
  };

  const generateUrl = (id) => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}${id}`;
    return url;
  };

  const onSubmit = (copy) => {
    setLoading(true);
    authenticationService
      .registerUser({
        emailAddress: email,
        roleNames: [selectedRole],
        baseVerificationUrl: getBaseUrl()
      })
      .then(({ userId }) => {
        getAllUsers();
        setVisible(false);
        if (copy) {
          const url = generateUrl(userId);
          navigator.clipboard.writeText(url);
          toast.current.show({
            severity: "success",
            summary: "Link copied",
            detail: "The invite link was successfully copied to your clipboard",
          });
        }
        setSelectedRole(null);
        setEmail("");
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const handleRoleClick = (roleName) => {
    setVisible(true);
    setSelectedRole(roleName);
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <i className="fa fa-user"></i>
        <h4 style={{ margin: 0 }}>Invite user - {selectedRole}</h4>
      </div>
    );
  };
  return (
    <div className="card manageTeamContainer">
      <div className="header">
        <div className="teamManageInfo">
          <h1>
            <i className="fa-solid fa-star" />
            Team Members {user.partner && `- ${user.partner?.companyName}`}
          </h1>
          <p>Manage your team members and their account permissions here</p>
        </div>
        {abp.auth.hasPermission("Pages.Users.Invite") && (
          <div className="inviteSection">
            <button>
              <i className="fa fa-plus"></i>
              Add team member
            </button>
            <div className="inviteRoles">
              {roles.map((i) => (
                <div
                  className="item"
                  key={i.name}
                  onClick={() => handleRoleClick(i.name)}
                >
                  {i.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="teamBodyContainer">
        <div className="teamTableInfo">
          <h5>Staff users</h5>
          <p>
            Staff users have limited permissions. They can create and view
            transactions.
          </p>
        </div>
        <div className="table crud-demo">
          <DataTable
            value={users}
            paginator
            rows={7}
            responsiveLayout="stack"
            className="datatable-responsive"
          >
            <Column field="name" header="Name" body={renderName}></Column>
            <Column field="userName" header="UserName"></Column>
            <Column
              field="creationTime"
              header="Date Joined"
              body={renderDate}
            ></Column>
            <Column field="roles" header="Roles" body={renderRoles}></Column>
          </DataTable>
        </div>
      </div>
      <Dialog
        header={renderHeader}
        visible={visible}
        draggable={false}
        onHide={() => setVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <hr />
        <div className="inviteDialog">
          <h3>Share an invite</h3>
          <p className="m-0">
            A person with a link <b>can join</b>
          </p>
          <input
            type="email"
            placeholder="Add an email address here"
            disabled={loading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="actions">
            <div
              className={`link ${email && !loading && "link-active"}`}
              onClick={() => {
                if (email && !loading) {
                  onSubmit(true);
                }
              }}
            >
              <i className="fa-solid fa-paperclip"></i>
              Copy link
            </div>
            <button
              className={`invite ${email && !loading && "active"}`}
              disabled={!email && loading}
              onClick={() => onSubmit(false)}
            >
              Share invite
            </button>
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
const renderRoles = (rowData) => {
  return <p>{rowData.roleNames.join(",")}</p>;
};
const renderDate = (rowData) => {
  return <p>{new Date(rowData.creationTime).toDateString()}</p>;
};
const renderName = (rowData) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <img
        style={{
          width: "40px",
          objectFit: "contain",
          borderRadius: "50%",
        }}
        src={
          rowData?.profilePicture
            ? `data:image/jpeg;base64,${rowData?.profilePicture}`
            : `${process.env.PUBLIC_URL}/images/defaultProfilePicture.jpeg`
        }
      />
      <div>
        <p>
          <b>{rowData?.name}</b>
        </p>
        <p>{rowData?.emailAddress}</p>
      </div>
    </div>
  );
};

export default Team;
