import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import partnerClubMappingService from "../../services/partnerClubMapping/partnerClubMappingService";
import CrudTable from "../../components/crudTable/CrudTable";
import clubService from "../../services/club/clubService";
import { useErrors } from "../../utils/error/useErrors";
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import partnersService from "../../services/partners/partnersService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { OrderList } from "primereact/orderlist";
import { Toolbar } from "primereact/toolbar";

let abp = window.abp;

const PartnerClubMapping = () => {
  const [partners, setPartners] = useState([]);
  const [showEditMappingsDialog, setShowEditMappingsDialog] = useState(false);
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clubs, setClubs] = useState([]);
  const { checkErrors } = useErrors();
  const { clubId } = useParams();
  const [selectedClub, setSelectedClub] = useState(clubId);
  const [editMappingsValue, setEditMappingsValue] = useState([]);
  const [mappingLayout, setMappingLayout] = useState(0);
  const [selectedMapping, setSelectedMapping] = useState();

  useEffect(() => {
    let mapping = localStorage.getItem("partnerClubMappingLayout");
    if (mapping === null) {
      mapping = 0;
    }
    var mappingLayout = parseInt(mapping);
    setMappingLayout(mappingLayout);
  }, []);

  useEffect(() => {
    localStorage.setItem("partnerClubMappingLayout", mappingLayout);
  }, [mappingLayout]);

  const getAllByClubId = (selectedClubId) => {
    partnerClubMappingService.getAllByClubId(selectedClubId).then((maps) => {
      setMappings(maps.items);
      setEditMappingsValue(maps.items);
      setLoading(false);
    });
  };

  useEffect(() => {
    partnersService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setPartners(res.items);
      });
  }, []);

  useEffect(() => {
    clubService
      .getAll({ hasNoPaginator: true, hasNoImages: true })
      .then((res) => {
        setClubs(res.items);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (selectedClub) {
      getAllByClubId(selectedClub ? selectedClub : clubId);
    } else {
      setMappings([]);
      setLoading(false);
    }
  }, [clubId, selectedClub]);

  const commisionBody = (rowData) => {
    return <p>{rowData?.partner?.defaultCommision} %</p>;
  };

  const priorityBody = (rowData) => {
    return (
      <button className="p-button p-button-outlined p-button-danger">
        {rowData?.partner?.priority || 0}
      </button>
    );
  };

  const partnerTypeBody = (rowData) => {
    let tableMap = {
      Active: {
        label: "Active",
        color: "p-button-info",
      },
      InActive: {
        label: "In Active",
        color: "p-button-danger",
      },
      ComingSoon: {
        label: "Coming Soon",
        color: "p-button-success",
      },
    };
    let map = tableMap[rowData?.partner?.partnerType];
    return map && <Button label={map.label} className={`${map.color}`} />;
  };

  const removePartner = (rowData) => {
    return (
      <Button icon="pi pi-times" onClick={() => reorderRemove(rowData.id)} />
    );
  };

  const showFields = [
    {
      header: "Company Name",
      name: "partner.companyName",
    },
    {
      header: "Default Commision",
      name: "defaultCommision",
      body: commisionBody,
    },
    {
      header: "Priority",
      name: "priority",
      body: priorityBody,
      sortable: true,
    },
    {
      header: "State",
      name: "partnerType",
      body: partnerTypeBody,
    },
    {
      header: "",
      name: "",
      body: removePartner,
    },
  ];

  const onClubChange = (value) => {
    setSelectedClub(value);
  };

  const handleEditMappingsBtnClicked = async () => {
    setLoading(true);
    await partnerClubMappingService.create(editMappingsValue);
    setEditMappingsValue([]);
    getAllByClubId(selectedClub);
    setShowEditMappingsDialog(false);
    setLoading(false);
  };

  const ExtendHeader = () => {
    return (
      <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <Dropdown
          className="ml-3"
          value={selectedClub}
          onChange={(e) => onClubChange(e.target.value)}
          options={clubs}
          optionValue="id"
          optionLabel="clubName"
          placeholder="Select a club..."
          showClear
        />
        {selectedClub && mappings != null && (
          <Button
            label="Edit mappings"
            onClick={() => setShowEditMappingsDialog(true)}
          />
        )}
      </div>
    );
  };

  const onRowReorder = (e) => {
    setLoading(true);
    const partnersToReorder = e.value.map((item, idx) => {
      return {
        id: item.partnerId,
        orderIndex: idx + 1,
      };
    });
    const orderingObject = {
      clubId: selectedClub,
      partners: partnersToReorder,
    };

    partnerClubMappingService
      .reordering(orderingObject)
      .then((res) => {
        getAllByClubId(selectedClub);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const reorderRemove = (id) => {
    setLoading(true);
    let updatedMappings = mappings.filter((mapping) => mapping.id !== id);
    const partnersToReorder = updatedMappings.map((item, idx) => {
      return {
        id: item.partnerId,
        orderIndex: idx + 1,
      };
    });

    const orderingObject = {
      clubId: selectedClub,
      partners: partnersToReorder,
    };

    partnerClubMappingService
      .reordering(orderingObject)
      .then(() => {
        getAllByClubId(selectedClub);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const itemTemplate = (mapping) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span className="font-bold text-xl ml-4">
          {mapping.partner.companyName}
        </span>
        <div style={{ display: "flex", gap: "15px" }}>
          {partnerTypeBody(mapping)}
          <Button
            icon="pi pi-times"
            className="p-button-danger"
            disabled={loading}
            loading={loading}
            onClick={() => reorderRemove(mapping.id)}
          />
        </div>
      </div>
    );
  };

  const onOrderChange = (e) => {
    setLoading(true);
    let index = 0;
    for (let mapping of e) {
      mapping.orderIndex = index;
      mapping.id = mapping.partnerId;
      index++;
    }
    setMappings(e);

    const orderingObject = {
      clubId: selectedClub,
      partners: e,
    };

    partnerClubMappingService
      .reordering(orderingObject)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const handleUpSingle = () => {
    let selectedIndex = mappings.indexOf(selectedMapping);
    const updatedArray = [...mappings];
    const temp = updatedArray[selectedIndex];

    updatedArray[selectedIndex] = updatedArray[selectedIndex - 1];
    updatedArray[selectedIndex - 1] = temp;

    setMappings(updatedArray);
    onOrderChange(updatedArray);
  };
  const handleTop = () => {
    let selectedIndex = mappings.indexOf(selectedMapping);
    const updatedArray = [...mappings];
    const temp = updatedArray[selectedIndex];

    if (selectedIndex > 0) {
      updatedArray.splice(selectedIndex, 1);
      updatedArray.unshift(temp);

      setMappings(updatedArray);
      onOrderChange(updatedArray);
    }
  };
  const handleDownSingle = () => {
    let selectedIndex = mappings.indexOf(selectedMapping);
    const updatedArray = [...mappings];
    const temp = updatedArray[selectedIndex];

    if (selectedIndex < mappings.length - 1) {
      updatedArray[selectedIndex] = updatedArray[selectedIndex + 1];
      updatedArray[selectedIndex + 1] = temp;

      setMappings(updatedArray);
      onOrderChange(updatedArray);
    }
  };
  const handleBottom = () => {
    let selectedIndex = mappings.indexOf(selectedMapping);
    const updatedArray = [...mappings];
    const temp = updatedArray[selectedIndex];

    if (selectedIndex < mappings.length - 1) {
      updatedArray.splice(selectedIndex, 1);
      updatedArray.push(temp);

      setMappings(updatedArray);
      onOrderChange(updatedArray);
    }
  };

  return (
    <div className="card">
      <Toolbar
        className="mb-4"
        left={() => <ExtendHeader />}
        right={() => (
          <Button
            label={`Switch to ${mappingLayout === 0 ? "Table" : "List"}`}
            onClick={() => setMappingLayout(mappingLayout === 0 ? 1 : 0)}
          />
        )}
      ></Toolbar>
      {mappingLayout === 0 ? (
        <OrderList
          value={mappings}
          onChange={(e) => onOrderChange(e.value)}
          itemTemplate={itemTemplate}
          header="Manage Partner Club Mappings"
          dragdrop={true}
          listStyle={{
            minHeight: "100vh"
          }}
        ></OrderList>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button
              icon="fa-solid fa-angle-up"
              onClick={handleUpSingle}
              disabled={!selectedMapping}
            />
            <Button
              icon="fa-solid fa-angles-up"
              onClick={handleTop}
              disabled={!selectedMapping}
            />
            <Button
              icon="fa-solid fa-angle-down"
              onClick={handleDownSingle}
              disabled={!selectedMapping}
            />
            <Button
              icon="fa-solid fa-angles-down"
              onClick={handleBottom}
              disabled={!selectedMapping}
            />
          </div>
          <CrudTable
            data={mappings}
            loading={loading}
            headerTitle={"Manage Partner Club Mappings"}
            onSelectionChange={(e) => setSelectedMapping(e)}
            fieldsToShow={showFields}
            hideHeader={true}
            canEdit={false}
            canCreate={false}
            canDelete={false}
            rows={25}
            reorder={
              abp.auth.hasPermission("Pages.PartnerClubMapping.Reorder")
                ? clubId || selectedClub
                  ? true
                  : false
                : false
            }
            onRowReorder={onRowReorder}
            style={{ width: "100%" }}
          />
        </div>
      )}
      <Dialog
        visible={showEditMappingsDialog}
        header="Edit Mappings"
        onHide={() => setShowEditMappingsDialog(false)}
        style={{ width: "50vw" }}
        footer={() => (
          <div className="mt-3">
            <Button
              label="Save"
              onClick={() => handleEditMappingsBtnClicked()}
            />
          </div>
        )}
      >
        <ListBox
          options={partners}
          optionLabel={"companyName"}
          optionValue={"id"}
          multiple
          filter
          value={editMappingsValue.map((map) => {
            return map.partnerId ?? map;
          })}
          onChange={(e) =>
            setEditMappingsValue(
              e.value.map((map) => {
                return {
                  partnerId: map.partnerId ?? map,
                  clubId: selectedClub,
                };
              })
            )
          }
        />
      </Dialog>
    </div>
  );
};

export default PartnerClubMapping;
