import http, { getFormData } from "../http/httpService";

class NotificationsService {
  async get(entityDto) {
    let result = await http.get("api/services/app/Notifications/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Notifications/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async send(data){
    let result = await http.post("api/services/app/Notifications/SendNotification", data);
    return result.data.result;
  }

  async clearCache(data){
    let result = await http.post("api/services/app/Notifications/ClearCache", data);
    return result.data.result;
  }

  async create(createNotificationInput) {
    const formData = getFormData(createNotificationInput);

    let result = await http.post("api/services/app/Notifications/Create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async update(updateNotificationInput) {
    const formData = getFormData(updateNotificationInput);

    let result = await http.put("api/services/app/Notifications/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Notifications/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }
}

export default new NotificationsService();
