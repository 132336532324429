import React, { useEffect, useState, useCallback } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import { sortByProperty } from "../../components/crudTable/crudTableUtils";
import prizesService from "../../services/prizes/prizesService";
import { fieldsToShow } from "./prizesConfig";
import { useNavigate } from "react-router-dom";
import { useErrors } from "../../utils/error/useErrors";
import { Button } from "primereact/button";
import Permission from "../../components/permission/Permission";
import { L } from "../../utils/abpUtility";
import { Dropdown } from "primereact/dropdown";
import clubService from "../../services/club/clubService";
import { useQuery } from "@tanstack/react-query";

const Prizes = () => {
  const [prizes, setPrizes] = useState([]);
  const [allPrizes, setAllPrizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clubId, setClubId] = useState(null);
  const [onlyClub, setOnlyClub] = useState(false);
  const navigate = useNavigate();
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [lazyParamsViewAll, setLazyParamsViewAll] = useState({
    first: 0,
    rows: 100,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  const { data: clubData } = useQuery({
    queryKey: [
      "clubs",
      {
        hasNoImages: true,
        hasNoPanator: true,
      },
    ],
    queryFn: () =>
      clubService.getAll({
        hasNoImages: true,
        hasNoPanator: true,
      }),
  });

  let clubs = clubData?.items ?? [];

  useEffect(() => {
    getAllPrizes();
  }, [lazyParams.first, lazyParams.rows, globalFilter, getAllPrizes]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllPrizes = useCallback(() => {
    setLoading(true);
    if (clubId) {
      getAllPrizesByClubId(clubId);
    }
    prizesService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((prizes) => {
        let allPrizes = sortByProperty(prizes.items, "club.clubName");
        setOnlyClub(prizes.onlyClub);
        if (prizes.onlyClub) {
          getAllPrizesByClubId(prizes.onlyClubId);
        } else {
          setPrizes(allPrizes.filter((prize) => prize.isActive));
          setLazyParams({ ...lazyParams, totalRecords: prizes.totalCount });

          setLoading(false);
        }
      });
  }, [clubId, getAllPrizesByClubId, lazyParams, globalFilter]);

  const getAllPrizesByClubId = useCallback(
    (clubId) => {
      setClubId(clubId);
      setLoading(true);
      prizesService
        .getAll({
          SkipCount: lazyParams.first,
          MaxResultCount: lazyParams.rows,
          Keyword: globalFilter,
          ClubId: clubId,
        })
        .then((prizes) => {
          let allPrizes = prizes.items;
          setAllPrizes(allPrizes);
          setLazyParamsViewAll({
            ...lazyParams,
            totalRecords: prizes.totalCount,
          });
          setLoading(false);
        });
    },
    [globalFilter, lazyParams]
  );

  const onEditSubmit = (data) => {
    if (
      data.partner.companyName === undefined ||
      data.partner.companyName == null
    ) {
      const { partner, ...rest } = data;
      data = rest;
    } else {
      data.partnerCompanyName = data.partner.companyName;
    }
    prizesService.update(data).then(() => {
      getAllPrizes();
      setLoading(false);
    });
  };

  const onCreateSubmit = (data) => {
    data.clubId = data.club.clubId;
    if (data.partner.id === undefined || data.partner.id == null) {
      const { partner, ...rest } = data;
      data = rest;
    } else {
      data.partnerId = data.partner.id;
    }
    prizesService.create(data).then(() => {
      getAllPrizes();
      setLoading(false);
    });
  };

  const onDelete = (data) => {
    setLoading(true);
    prizesService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllPrizes();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSinglePrize = async (id) => {
    const prizeData = await prizesService.get({ id });
    prizeData.clubId = prizeData.club?.id;
    return prizeData;
  };

  const headerTemplate = (data) => {
    return (
      <>
        <span className="image-text m-2">{data.club?.clubName}</span>
        <Button
          className="absolute left-auto right-0 mr-5"
          label="View All"
          onClick={() => {
            getAllPrizesByClubId(data.club?.id);
          }}
        />
      </>
    );
  };

  const deactivate = async (id) => {
    setLoading(true);
    await prizesService.deactivate({ id });
    getAllPrizes();
  };

  const activate = async (id) => {
    setLoading(true);
    await prizesService.activate({ id });
    getAllPrizes();
  };

  const ExtendBody = ({ data }) => {
    return (
      data.id && (
        <React.Fragment>
          <Permission permission={"Pages.Prize"}>
            <Button
              icon="pi pi-eye"
              rounded
              severity="primary"
              onClick={() => navigate(`/prizes/${data.id}`)}
              className="prize-details-btn"
            />
          </Permission>
          <Permission permission={"Pages.Prize.Edit"}>
            <Button
              icon="pi pi-pencil"
              rounded
              severity="success"
              onClick={() => navigate(`/prizes/update/${data.id}`)}
            />
          </Permission>
          <Permission permission={"Pages.Prize.Activate"}>
            <Button
              icon="pi pi-play"
              className="p-button-rounded p-button-info"
              disabled={data.isActive}
              onClick={() => activate(data.id)}
            />
          </Permission>
          <Permission permission={"Pages.Prize.Deactivate"}>
            <Button
              icon="pi pi-ban"
              className="p-button-rounded p-button-danger"
              disabled={!data.isActive}
              onClick={() => deactivate(data.id)}
            />
          </Permission>
        </React.Fragment>
      )
    );
  };

  const ExtendHeader = () => {
    return (
      <>
        {clubId && !onlyClub && (
          <Button
            label="Close"
            severity="danger"
            className="mr-3"
            onClick={() => setClubId(null)}
          />
        )}
        <Permission permission={"Pages.Prize.Create"}>
          <Button
            label={L("New")}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => navigate("/prizes/create")}
          />
        </Permission>
        {!clubId && !onlyClub && (
          <Dropdown
            options={clubs}
            optionValue="id"
            optionLabel="clubName"
            placeholder="Select a club"
            value={clubId}
            onChange={(e) => {
              getAllPrizesByClubId(e.value);
            }}
          />
        )}
      </>
    );
  };

  const onRowDoubleClick = (data) => {
    navigate("/prizes/" + data.id);
  };

  const onRowToggle = () => {};

  const onlyClubProps = !onlyClub
    ? {
        rowGroupMode: "subheader",
        groupRowsBy: "club.clubName",
        sortMode: "single",
        sortField: "club.clubName",
        expandableRowGroups: true,
        rowGroupHeaderTemplate: headerTemplate,
      }
    : {};

  return (
    <div>
      {clubId ? (
        <CrudTable
          data={allPrizes}
          fieldsToShow={fieldsToShow}
          loading={loading}
          onEditSubmit={onEditSubmit}
          onCreateSubmit={onCreateSubmit}
          onDelete={onDelete}
          headerTitle="Manage All Club Prizes"
          setLoading={setLoading}
          lazy={true}
          lazyParams={lazyParamsViewAll}
          setLazyParams={setLazyParams}
          globalFilter={globalFilter}
          setGlobalFilter={onGlobalFilterChange}
          primaryKey="id"
          extendHeader={ExtendHeader}
          getSingleEntity={getSinglePrize}
          createPermission="Pages.Prize.Create"
          editPermission={"Pages.Prize.Edit"}
          deletePermission={"Pages.Prize.Delete"}
          onRowDoubleClick={(e) => onRowDoubleClick(e.data)}
          canCreate={false}
          canDelete={false}
          canEdit={false}
          extendBody={ExtendBody}
        />
      ) : (
        <CrudTable
          data={prizes}
          fieldsToShow={fieldsToShow}
          loading={loading}
          onEditSubmit={onEditSubmit}
          onCreateSubmit={onCreateSubmit}
          onDelete={onDelete}
          headerTitle="Manage Active Club Prizes"
          setLoading={setLoading}
          lazy={true}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          globalFilter={globalFilter}
          setGlobalFilter={onGlobalFilterChange}
          primaryKey="id"
          getSingleEntity={getSinglePrize}
          createPermission="Pages.Prize.Create"
          editPermission={"Pages.Prize.Edit"}
          deletePermission={"Pages.Prize.Delete"}
          sortOrder={1}
          onRowDoubleClick={(e) => onRowDoubleClick(e.data)}
          canDelete={false}
          canCreate={false}
          canEdit={false}
          extendBody={ExtendBody}
          onRowToggle={onRowToggle}
          extendHeader={ExtendHeader}
          {...onlyClubProps}
        />
      )}
    </div>
  );
};

export default Prizes;
