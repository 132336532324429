import http, { getFormData } from "../http/httpService";

class PrizesService {
  async create(createPrizeInput) {
    const formData = getFormData(createPrizeInput);

    let result = await http.post("api/services/app/Prizes/Create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Prizes/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async deactivate(entityDto) {
    let result = await http.post(
      "api/services/app/Prizes/DeactivateById",
      {},
      {
        params: entityDto,
      }
    );
    return result.data;
  }

  async activate(entityDto) {
    let result = await http.post(
      "api/services/app/Prizes/ActivateById",
      {},
      {
        params: entityDto,
      }
    );
    return result.data;
  }

  async get(entityDto, options) {
    let result = await http.get("api/services/app/Prizes/GetById", {
      params: { ...entityDto, ...options },
    });
    return result.data.result;
  }

  async getPrizeWinner(params) {
    let result = await http.get("api/services/app/Prizes/PickPrizeWinner", {
      params,
    });
    return result.data.result;
  }

  async getPrizesDates(params) {
    let result = await http.get("api/services/app/Prizes/GetPrizesDates", {
      params,
    });
    return result.data.result;
  }

  async confirmPrizeWinner(body) {
    let result = await http.post(
      "api/services/app/Prizes/ConfirmPrizeWinner",
      body
    );
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Prizes/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async getAllPrizeEntries(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Prizes/GetAllPrizeEntries", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(updateUserInput) {
    const formData = getFormData(updateUserInput);

    let result = await http.put("api/services/app/Prizes/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }
  async updateTranslations(updatePrizeTranslationsInput) {
    let result = await http.put(
      "api/services/app/Prizes/UpdateTranslations",
      updatePrizeTranslationsInput
    );
    return result.data.result;
  }

  async getClubPrizeReport(params) {
    let result = await http.get("api/services/app/Reports/GetClubPrizesData", {
      params,
    });

    return result.data.result;
  }
}

export default new PrizesService();
