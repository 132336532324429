import { Button } from "primereact/button";

const videoBody = (rowData) => {
  return (
    <video
      src={rowData?.videoUrl}
      alt="images"
      autoPlay
      loop
      muted
      style={{
        maxWidth: "100px",
        borderRadius: "10px",
        aspectRatio: "16/9",
        objectFit: "cover",
      }}
    />
  );
};

const videoEditShow = ({ data }) => {
  return (
    <video
      src={`data:video/mp4;base64,${data?.contents}`}
      alt="images"
      autoPlay
      controls
      style={{ maxWidth: "100%", aspectRatio: "16/9", borderRadius: "10px" }}
    />
  );
};

const fieldsToShow = [
  {
    header: "Video",
    name: "video",
    body: videoBody,
  },
  {
    header: "Name",
    name: "videoName",
  },
  {
    header: "File Name",
    name: "fileName",
  },
  {
    header: "Content Type",
    name: "contentType",
  },
  {
    header: "Video Url",
    name: "videoUrl",
    body: (rowData) =>
      rowData?.videoUrl && (
        <Button
          type="button"
          label="View"
          icon="pi pi-eye"
          className="p-button-plain"
          onClick={() => window.open(rowData.videoUrl)}
          style={{ width: "100%" }}
          tabIndex="0"
        />
      ),
  },
];

const fieldsToEdit = [
  {
    name: "video",
    title: "",
    body: videoEditShow,
  },
  {
    name: "file",
    title: "Video",
    type: "video",
  },
  {
    name: "videoName",
    title: "Video Name",
    type: "string",
  },
  {
    name: "fileName",
    title: "File Name",
    type: "string",
  },
  {
    name: "videoUrl",
    title: "Video Url",
    type: "string",
  },
];

const fieldsToCreate = [
  {
    name: "file",
    title: "Video",
    type: "video",
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
