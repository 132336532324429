import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoginWrapper from "../../components/login/LoginWrapper";
import authenticationService from "../../services/authentication/authenticationService";
import { useErrors } from "../../utils/error/useErrors";

const Confirm = () => {
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const { checkErrors } = useErrors();
  const navigate = useNavigate();

  const StepZero = () => (
    <>
      <button type="button" className="logo p-link">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="freya-layout"
          className="loginLogo"
        />
      </button>

      <h4>You have been invited to join Sports Dynamics portal...</h4>
      <Button
        label="Join"
        type="button"
        onClick={() => setStep((step) => step + 1)}
      />
    </>
  );

  const StepOne = () => (
    <>
      <button type="button" className="logo p-link">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="freya-layout"
          className="loginLogo"
        />
      </button>
      {globalError && <div className="login_errorPanel">{globalError}</div>}
      <div className="confirmInputs">
        <p className="inputMessage" style={{ marginBottom: "0" }}>
          {errors?.name?.message}
        </p>
        <InputText
          placeholder="First Name"
          className={`loginInput ${errors?.name ? "fieldInvalid" : ""}`}
          {...register("name", { required: "First name is required" })}
        />
        <p className="inputMessage" style={{ marginBottom: "0" }}>
          {errors?.surname?.message}
        </p>
        <InputText
          placeholder="Last Name"
          className={`loginInput ${errors?.surname ? "fieldInvalid" : ""}`}
          {...register("surname", { required: "Last name is required" })}
        />
        <Controller
          control={control}
          name="password"
          defaultValue={""}
          rules={{
            required: "Password is required",
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                "Password must consists of atleast one number [0-9] and one special character ",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <p className="inputMessage">{error && error.message}</p>
              <input
                type="password"
                className={classNames({
                  loginInput: true,
                  fieldInvalid: error,
                })}
                disabled={loading}
                placeholder="Password"
                {...field}
              />
            </div>
          )}
        ></Controller>

        <Controller
          control={control}
          name="confirmPassword"
          defaultValue={""}
          rules={{
            required: "Confirm password is required",
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <p className="inputMessage">{error && error.message}</p>
              <input
                type="password"
                className={classNames({
                  loginInput: true,
                  fieldInvalid: error,
                })}
                disabled={loading}
                placeholder="Confirm Password"
                {...field}
              />
            </div>
          )}
        ></Controller>

        <Button
          label="Register"
          type="submit"
          disabled={loading}
          className="p-button-danger"
        />
      </div>
    </>
  );

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setGlobalError("The two passwords did not match!");
      return;
    }
    setLoading(true);
    authenticationService
      .confirmUser({
        id: id,
        name: data.name,
        surname: data.surname,
        password: data.password,
      })
      .then((res) => {
        authenticationService
          .login({
            userNameOrEmailAddress: res.username,
            password: data.password,
            rememberClient: true,
          })
          .then(() => {
            navigate("/?clearCache=true");
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  return (
    <LoginWrapper showAside={false} aside={null}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="confirmContainer">
          {step === 0 && <StepZero />}
          {step === 1 && <StepOne />}
        </div>
      </form>
    </LoginWrapper>
  );
};

export default Confirm;
