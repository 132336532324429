import React from "react";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";
import { useLocalStorage } from "../../hooks/storage/useLocalStorage";
import { GenerateScreensStep } from "./steps/GenerateScreenStep";
import { UploadImagesStep } from "./steps/UploadImagesStep";
import { UpdateOnboarding } from "./steps/UpdateOnboardingStep";
import { UpdateCards } from "./steps/UpdateCardsStep";
import { UpdateFirebaseKey } from "./steps/UpdateFirebaseKeyStep";
import { UpdateLandingPage } from "./steps/UpdateLandingPageStep";
import { UpdateCampaign } from "./steps/UpdateCampaignStep";
import { CreateClubStep } from "./steps/CreateClubStep";
import "./ClubWizard.scss";
import { UpdateStyle } from "./steps/UpdateStyleStep";
import { UpdatePrizesStep } from "./steps/UpdatePrizesStep";

const ClubWizard = () => {
  const [index, setIndex] = useLocalStorage("wizardCurrentStep", 0);
  const [clubName, setClubName, clearName] = useLocalStorage("wizardClubName");
  const [clubId, setClubId] = useLocalStorage("wizardClubId");

  const items = [
    {
      label: "Select Club",
      icon: "fa-solid fa-plus",
      command: () => {
        if (!clubId) {
          setIndex(0);
        }
      },
    },
    {
      label: "Generate Screen",
      icon: "fa-solid fa-gears",
      command: () => {
        if (clubId) {
          setIndex(1);
        }
      },
    },
    {
      label: "Upload Images",
      icon: "fa-solid fa-upload",
      command: () => {
        if (clubId) {
          setIndex(2);
        }
      },
    },
    {
      label: "Update Onboarding",
      icon: "fa-solid fa-file",
      command: () => {
        if (clubId) {
          setIndex(3);
        }
      },
    },
    {
      label: "Update Cards",
      icon: "fa-solid fa-credit-card",
      command: () => {
        if (clubId) {
          setIndex(4);
        }
      },
    },
    {
      label: "Update Firebase key",
      icon: "fa-solid fa-key",
      command: () => {
        if (clubId) {
          setIndex(5);
        }
      },
    },
    {
      label: "Update Landing Page",
      icon: "fa-solid fa-file-circle-check",
      command: () => {
        if (clubId) {
          setIndex(6);
        }
      },
    },
    {
      label: "Update Prizes",
      icon: "fa-solid fa-gift",
      command: () => {
        if (clubId) {
          setIndex(7);
        }
      },
    },
    {
      label: "Update Referral",
      icon: "fa-solid fa-retweet",
      command: () => {
        if (clubId) {
          setIndex(8);
        }
      },
    },
    {
      label: "Update Style",
      icon: "fa-solid fa-border-top-left",
      command: () => {
        if (clubId) {
          setIndex(9);
        }
      },
    },
  ];

  const RenderStep = () => {
    switch (index) {
      case 0:
        return (
          <CreateClubStep
            setClubId={setClubId}
            setIndex={setIndex}
            setClubName={setClubName}
          />
        );
      case 1:
        return <GenerateScreensStep clubId={clubId} setIndex={setIndex} />;
      case 2:
        return <UploadImagesStep clubId={clubId} setIndex={setIndex} />;
      case 3:
        return <UpdateOnboarding clubId={clubId} setIndex={setIndex} />;
      case 4:
        return <UpdateCards clubId={clubId} setIndex={setIndex} />;
      case 5:
        return <UpdateFirebaseKey clubId={clubId} setIndex={setIndex} />;
      case 6:
        return <UpdateLandingPage clubId={clubId} setIndex={setIndex} />;
      case 7:
        return <UpdatePrizesStep clubId={clubId} setIndex={setIndex} />;
      case 8:
        return <UpdateCampaign clubId={clubId} setIndex={setIndex} />;
      case 9:
        return <UpdateStyle clubId={clubId} setIndex={setIndex} />;
      default:
        return <>No step found</>;
    }
  };

  return (
    <div className="card wizard">
      <div className="wizard-header">
        <h2>{clubName ? clubName : "Club Wizard"}</h2>
        {index !== 0 && (
          <Button
            label="Select a new club"
            className="p-button p-button-danger"
            icon="fa fa-xmark"
            onClick={() => {
              localStorage.clear("wizardClubId");
              localStorage.clear("wizardCurrentStep");
              localStorage.clear("wizardScreensGenerated");
              clearName();
              setIndex(0);
            }}
          />
        )}
      </div>
      <TabMenu
        model={items}
        activeIndex={index}
        onTabChange={(e) => e.value.command()}
        readOnly={true}
      />
      <div className="m-6">
        <RenderStep />
      </div>
    </div>
  );
};

export default ClubWizard;
