import { Image } from "primereact/image";
import { useEffect, useState } from "react";
import languagesService from "../../services/languages/languagesService";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";
import clubService from "../../services/club/clubService";
import partnersService from "../../services/partners/partnersService";
import { Controller } from "react-hook-form";

export const defaultImage =
  "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg";

const imageShowBody = ({ data, name }) => {
  return (
    <div>
      <h5>Old photo:</h5>
      <img
        src={`data:image/png;base64,${data?.[name]?.contents}`}
        alt="club logo"
        style={{
          maxWidth: "130px",
          borderRadius: "5px",
          maxHeight: "130px",
          objectFit: "cover",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = defaultImage;
        }}
      />
    </div>
  );
};

const imagePreviewShowBody = ({ data, name }) => {
  return data?.[name]?.contents || data?.[name]?.imageUrl ? (
    <Image
      src={
        data?.[name]?.imageUrl
          ? data?.[name]?.imageUrl
          : `data:image/png;base64,${data?.[name]?.contents}`
      }
      alt="Image"
      downloadable
      preview
      imageStyle={{
        objectFit: "cover",
        maxWidth: "130px",
        maxHeight: "130px",
        borderRadius: "5px",
        filter: "drop-shadow(3px 3px 3px #111)",
      }}
      style={{ maxWidth: "130px", width: "fit-content" }}
    />
  ) : (
    <img
      src={defaultImage}
      alt="club logo"
      style={{
        maxWidth: "130px",
        borderRadius: "5px",
        maxHeight: "130px",
        objectFit: "cover",
      }}
    />
  );
};

const videoShowBody = ({ data, name }) => {
  return (
    <video
      src={`data:video/mp4;base64,${data?.[name]?.contents}`}
      alt="images"
      autoPlay
      muted
      style={{ maxWidth: "130px", borderRadius: "10px" }}
    />
  );
};

const getValue = (item, key) => {
  const keys = key.split(".");
  let value = item;

  for (let keyValue of keys) {
    value = value[keyValue];
  }

  return value;
};

const sortByProperty = (array, key, desc = false) => {
  const sorted = array.sort((a, b) =>
    getValue(a, key) > getValue(b, key) ? (desc ? -1 : 1) : desc ? 1 : -1
  );
  return sorted;
};

const LanguageSelect = (props) => {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    languagesService.getAll().then((data) => setLanguages(data.items));
  }, []);
  return (
    languages && (
      <div>
        <Dropdown
          options={languages}
          optionValue="id"
          placeholder="Select language"
          optionLabel="name"
          {...props}
        />
      </div>
    )
  );
};

export {
  imageShowBody,
  videoShowBody,
  imagePreviewShowBody,
  sortByProperty,
  LanguageSelect,
};

let clubProps = {
  hasNoImages: true,
  hasNoPaginator: true,
};

export const ClubSelection = ({
  control,
  clubValueName = "clubId",
  clubOptionValue = "id",
}) => {
  const { data: clubData } = useQuery({
    queryKey: ["clubs", clubProps],
    queryFn: () => clubService.getAll(clubProps),
  });

  let clubs = clubData?.items ?? [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <label>Club: </label>
      <Controller
        control={control}
        name={clubValueName}
        render={({ field }) => (
          <Dropdown
            {...field}
            options={clubs}
            optionValue={clubOptionValue}
            placeholder="Select a club"
            showClear
            optionLabel="clubName"
            disabled={clubs.length === 0}
            value={field.value}
          />
        )}
      />
    </div>
  );
};

export const PartnerClubSelect = ({
  control,
  partnerValueName = "partnerId",
  clubValueName = "clubId",
  partnerOptionValue = "id",
  clubOptionValue = "id",
  watch,
}) => {
  let clubId = watch(clubValueName);
  const { data: clubData } = useQuery({
    queryKey: ["clubs", clubProps],
    queryFn: () => clubService.getAll(clubProps),
  });

  let clubs = clubData?.items ?? [];

  const { data: partnersData } = useQuery({
    queryKey: ["partners", { clubId }],
    queryFn: () => partnersService.getAllByClub({ ClubId: clubId }),
    enabled: !!clubId,
  });

  let partners = partnersData || [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <label>Club:</label>
      <Controller
        control={control}
        name={clubValueName}
        render={({ field }) => (
          <>
            <Dropdown
              {...field}
              options={clubs}
              optionValue={clubOptionValue}
              placeholder="Select a club"
              showClear
              optionLabel="clubName"
              disabled={clubs.length === 0}
              value={field.value}
            />
          </>
        )}
      />

      <label>Partner:</label>
      <Controller
        control={control}
        name={partnerValueName}
        render={({ field }) => (
          <Dropdown
            {...field}
            options={partners}
            optionValue={partnerOptionValue}
            placeholder={`${
              !clubId ? "Select a club to select partner" : "Select a partner"
            }`}
            optionLabel="companyName"
            disabled={partners.length === 0}
            showClear
          />
        )}
      />
    </div>
  );
};
