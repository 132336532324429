import React from "react";
import { useUser } from "../../../hooks/user/useUser";
import { Link } from "react-router-dom";

export const ClubAppUsage = ({ data, dates }) => {
  const options = { day: "numeric", month: "long", year: "numeric" };

  const startDate = new Date(dates?.[0]);
  const endDate = new Date(dates?.[1]);
  const user = useUser();
  return (
    <div className="card statistics">
      <div className="card-header">
        <div className="card-title">
          <h6>Statistics</h6>
          <p className="subtitle">{user.club?.clubName}</p>
        </div>
        <p className="subtitle">{`${
            startDate?.toLocaleDateString("en-US", options)
        } - ${
          endDate?.toLocaleDateString("en-US", options)
        }`}</p>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>🛒</span>
              <h5>{data.numberOfOrders}</h5>
            </div>
            <h6>Orders</h6>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>
                <i className="fa fa-users" />
              </span>
              <h5>{data?.numberOfUsers || 0}</h5>
            </div>
            <h6>Connected users</h6>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>🎁 </span>
              <h5>{data?.numberOfPrizes || 0}</h5>
            </div>
            <h6>Prizes</h6>
            <Link to={`/clubs/${data?.clubId}/prizes/reports`}>
              <p>See more details</p>
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>💵 </span>
              <h5>{data?.earningTotal ?? 0} lv</h5>
            </div>
            <h6>Earnings/month</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
