import { useState } from "react";
import { useForm } from "react-hook-form";
import clubService from "../../../services/club/clubService";
import { useNotification } from "../../../hooks/notification/useNotification";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ImageUploader } from "../components/ImageUploader";
import { VideoUploader } from "../components/VideoUploader";

export const UpdateOnboarding = ({ clubId, setIndex }) => {
  const [onboardingLoading, setOnboardingLoading] = useState(false);
  const { showError } = useNotification();
  const onboardingStep = useForm();

  const onSubmit = (data) => {
    setOnboardingLoading(true);
    data.id = clubId;
    clubService
      .updateOnboarding(data)
      .then(() => {
        setIndex((index) => index + 1);
        setOnboardingLoading(false);
      })
      .catch(() => {
        showError(
          "An error occured while updating the onboarding items",
          "Error"
        );
      });
  };

  let inputWidth = "450px";
  let fileImageWidth = "350px";

  return (
    <form
      onSubmit={onboardingStep.handleSubmit(onSubmit)}
      className="updateOnboarding"
    >
      <h2>Update onboarding</h2>
      <div
        style={{
          display: "flex",
          gap: "25px",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        {onboardingStringInputs.map((inputField) => {
          return (
            <div
              key={inputField.field}
              className="fieldInput"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: inputWidth,
              }}
            >
              <label>{inputField.label}</label>
              <InputText
                key={inputField.field}
                placeholder={inputField.label}
                {...onboardingStep.register(inputField.field)}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        {onboardingImageFields.map((field) => (
          <ImageUploader
            field={field}
            key={field.field}
            control={onboardingStep.control}
            loading={onboardingLoading}
            style={{
              width: fileImageWidth,
            }}
          />
        ))}
        <VideoUploader
          field={{
            field: "clubVideo",
            label: "Club Video",
          }}
          control={onboardingStep.control}
          loading={onboardingLoading}
        />
      </div>
      <Button
        label="Update onboarding"
        disabled={onboardingLoading}
        loading={onboardingLoading}
      />
    </form>
  );
};

const onboardingStringInputs = [
  {
    field: "supportYourTeamShortDescription",
    label: "Support Your Team Short Description",
  },
  {
    field: "supportYourTeamTitle",
    label: "Support Your Team Title",
  },
  {
    field: "winPrizesShortDescription",
    label: "Win Prizes Short Description",
  },
  {
    field: "winPrizesTitle",
    label: "Win Prizes Title",
  },
  {
    field: "getRewardsShortDescription",
    label: "Get Rewards Short Description",
  },
  {
    field: "getRewardsTitle",
    label: "Get Rewards Title",
  },
  {
    field: "earnPointsShortDescription",
    label: "Earn Points Short Description",
  },
  {
    field: "earnPointsTitle",
    label: "Earn Points Title",
  },
  {
    field: "orderYourCardShortDescription",
    label: "Order Your Card Short Description",
  },
  {
    field: "orderYourCardTitle",
    label: "Order Your Card Title",
  },
  {
    field: "clubVideoUrl",
    label: "Club video url",
  },
];
const onboardingImageFields = [
  {
    field: "orderYourCardFileImage",
    label: "Order Your Card File Image",
    preview: "onboardingOrderYourCardImage.png",
  },
  {
    field: "earnPointsFileImage",
    label: "Earn Points File Image",
    preview: "onboardingEarnPointsImage.png",
  },
  {
    field: "getRewardsFileImage",
    label: "Get Rewards File Image",
    preview: "onboardingGetRewardsImage.png",
  },
  {
    field: "winPrizesFileImage",
    label: "Win Prizes File Image",
    preview: "onboardingWinPrizesImage.png",
  },
];
