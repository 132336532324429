import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { imagePreviewShowBody } from "../../components/crudTable/crudTableUtils";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import cardTypesService from "../../services/cardTypes/cardTypesService";

const showTypeName = (rowData) => {
  return (
    rowData.typeName && (
      <Button label={rowData.typeName} className="p-button-help" />
    )
  );
};

const renderSubTitle = (rowData) => {
  const baseLength = 20;
  const length =
    rowData.displayDescription?.length >= baseLength
      ? baseLength
      : rowData.displayDescription.length;
  return <p>{rowData.displayDescription.substring(0, length)}...</p>;
};

const showPrice = (rowData) => {
  return <Badge value={rowData.price || "free"} severity="danger" />;
};

const SlugChange = (props) => {
  const [slug, setSlug] = useState(null);
  const { data: translations } = useQuery({
    queryKey: ["cardTypesTranslations", slug],
    queryFn: () => cardTypesService.getTranslationsBySlug(slug),
    enabled: !!slug,
  });

  const { data } = props;
  const { control, rules, reset, getValues } = data;

  useEffect(() => {
    if (translations) {
      let values = getValues();
      values.displayName = translations?.displayName;
      values.displayDescription = translations?.displayDescription;
      values.buttonText = translations?.buttonText;
      reset(values);
    }
  }, [translations, getValues, reset]);
  return (
    <>
      <p>
        Select a slug to preload the translated texts.
        <br />
        <span className="p-error">
          If you change either the DISPLAY NAME, DISPLAY DESCRIPTION or BUTTON
          TEXT the changes will be made on all cards with that slug!
        </span>
      </p>
      <Controller
        name={"slug"}
        control={control}
        rules={{ ...rules }}
        render={({ field }) => (
          <Dropdown
            showClear
            {...field}
            options={slugOptions}
            placeholder="Select slug..."
            onChange={(e) => {
              field.onChange(e.value ? e.value : null);
              setSlug(e.value || null);
            }}
          />
        )}
      ></Controller>
    </>
  );
};

const slugOptions = [
  {
    label: "Standard",
    value: "standard",
  },
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Virtual",
    value: "virtual",
  },
];

const formatDay = (date) => {
  let objectDate = new Date(date);

  let day = objectDate.getDate();

  let month = objectDate.getMonth() + 1;

  let year = objectDate.getFullYear();

  let format = day + "/" + month + "/" + year;
  return format;
};

const fieldsToShow = [
  {
    header: "Image",
    name: "image",
    type: "image",
  },
  {
    header: "Mockup Image",
    name: "image",
    type: "image",
  },
  {
    header: "DisplayName",
    name: "displayName",
  },
  {
    header: "DisplayDescription",
    name: "displayDescription",
    body: renderSubTitle,
  },
  {
    header: "Price",
    name: "price",
    body: showPrice,
  },
  {
    header: "Start Date",
    name: "validFrom",
    body: (rowData) => <p>{formatDay(rowData.validFrom)}</p>,
  },
  {
    header: "End Date",
    name: "validTo",
    body: (rowData) => <p>{formatDay(rowData.validTo)}</p>,
  },
  {
    header: "Active",
    name: "active",
    body: (rowData) => (
      <button
        className={`p-button ${
          rowData.active ? "p-button-primary" : "p-button-danger"
        }`}
      >
        {rowData.active ? "Active" : "Inactive"}
      </button>
    ),
  },
  {
    header: "Type Name",
    name: "typeName",
    body: showTypeName,
  },
  {
    header: "Slug",
    name: "slug",
    body: (rowData) => (
      <button className="p-button">{rowData.slug.toUpperCase()}</button>
    ),
  },
];
const fieldsToEdit = [
  {
    name: "displayName",
    title: "Display Name",
    type: "string",
    rules: {
      required: "Display name is required",
    },
  },
  {
    name: "active",
    title: "Active",
    type: "bool",
  },
  {
    name: "buttonText",
    title: "Button Text",
    type: "string",
    rules: {
      required: "Button text is required",
    },
  },
  {
    name: "designUrl",
    title: "Design Url",
    type: "string",
    rules: {
      required: "Design url is required",
    },
  },
  {
    name: "displayDescription",
    title: "Display Description",
    type: "textarea",
    rules: {
      required: "Description is required",
    },
  },
  {
    name: "nextScreenLayout",
    title: "Next Screen Layout",
    type: "string",
  },
  {
    name: "price",
    title: "Price",
    type: "number",
    rules: {
      required: "Price is required",
    },
  },
  {
    name: "screenLayout",
    title: "Screen Layout",
    type: "string",
  },
  {
    name: "typeName",
    title: "Type Name",
    type: "string",
    rules: {
      required: "Type name is required",
    },
  },
  {
    name: "validFrom",
    title: "Valid From",
    type: "calendar",
    rules: {
      required: "Valid from is required",
    },
  },
  {
    name: "validTo",
    title: "Valid To",
    type: "calendar",
    rules: {
      required: "Valid from is required",
    },
  },
  {
    name: "virtual",
    title: "Virtual",
    type: "bool",
  },
  {
    name: "useTypeOver",
    title: "Use Type Over",
    type: "bool",
  },
  {
    name: "image",
    title: "Image",
    type: "image",
    rules: {
      required: "Image is required",
    },
  },
  {
    name: "image",
    title: "",
    body: imagePreviewShowBody,
  },
  {
    name: "mockupImage",
    title: "Mockup Image",
    type: "image",
    rules: {
      required: "Mockup is required",
    },
  },
  {
    name: "mockupImage",
    title: "",
    body: imagePreviewShowBody,
  },
  {
    type: "club-select",
  },
];

const fieldsToCreate = [
  {
    name: "custom",
    title: "",
    body: (props) => <SlugChange data={props} />,
  },
  {
    name: "displayName",
    title: "Display Name",
    type: "string",
    rules: {
      required: "Display name is required",
    },
  },
  {
    name: "active",
    title: "Active",
    type: "bool",
  },
  {
    name: "buttonText",
    title: "Button Text",
    type: "string",
    rules: {
      required: "Button text is required",
    },
  },
  {
    name: "designUrl",
    title: "Design Url",
    type: "string",
    rules: {
      required: "Design url is required",
    },
  },
  {
    name: "displayDescription",
    title: "Display Description",
    type: "textarea",
    rules: {
      required: "Description is required",
    },
  },
  {
    name: "nextScreenLayout",
    title: "Next Screen Layout",
    type: "string",
  },
  {
    name: "price",
    title: "Price",
    type: "number",
    rules: {
      required: "Price is required",
    },
  },
  {
    name: "screenLayout",
    title: "Screen Layout",
    type: "string",
  },
  {
    name: "typeName",
    title: "Type Name",
    type: "string",
    rules: {
      required: "Type name is required",
    },
  },
  {
    name: "validFrom",
    title: "Valid From",
    type: "calendar",
    rules: {
      required: "Valid from is required",
    },
  },
  {
    name: "validTo",
    title: "Valid To",
    type: "calendar",
    rules: {
      required: "Valid from is required",
    },
  },
  {
    name: "virtual",
    title: "Virtual",
    type: "bool",
  },
  {
    name: "useTypeOver",
    title: "Use Type Over",
    type: "bool",
  },
  {
    name: "image",
    title: "Image",
    type: "image",
    rules: {
      required: "Image is required",
    },
  },
  {
    name: "mockupImage",
    title: "Mockup Image",
    type: "image",
    rules: {
      required: "Mockup Image is required",
    },
  },
  {
    type: "club-select",
  },
];

export { fieldsToShow, fieldsToEdit, fieldsToCreate };
