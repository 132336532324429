import React, { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import "./Login.scss";

const LoginWrapper = ({ children, aside: AsideComponent, showAside }) => {
  const { colorScheme } = useContext(ThemeContext);
  return (
    <div
      className={`loginBackground ${
        colorScheme === "dark" ? "loginDarkBackground" : ""
      }`}
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("${process.env.PUBLIC_URL}/images/background.jpeg")
      no-repeat`,
      }}
    >
      <div
        className={`loginContainer ${!showAside ? "loginContainerHide" : ""}`}
      >
        <div className="loginForm">{children}</div>

        {showAside && (
          <div className="loginAside">
            <AsideComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginWrapper;
