import http from "../http/httpService";

class CardBatchesService {
  async get(entityDto) {
    let result = await http.get("api/services/app/CardsBatches/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/CardsBatches/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async update(input) {
    input.clubId = input.club.id;

    let result = await http.put("api/services/app/CardsBatches/Update", input);

    return result.data.result;
  }

  async create(input) {
    let result = await http.post("api/services/app/CardsBatches/Create", input);

    return result.data.result;
  }
}

export default new CardBatchesService();
