import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export const NotFound = (props) => {
  const navigate = useNavigate();

  const goDashboard = () => {
    navigate("/");
  };

  return (
    <div className="exception-body notfound">
      <div className="exception-topbar">
        <button
          id="logolink"
          onClick={goDashboard}
          className="layout-topbar-logo p-link"
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/layout/images/logo-${
              props.colorScheme === "dark" ? "freya-white" : "freya"
            }.svg`}
            alt="freya-layout"
          />
        </button>
      </div>
      <div className="exception-wrapper">
        <div className="exception-content">
          <img
            src={`${process.env.PUBLIC_URL}/assets/layout/images/pages/asset-404.svg`}
            alt="freya-layout"
          />
          <span>404</span>
          <span className="exception-subtitle">Looks like you are lost.</span>
          <Button
            type="button"
            label="Go back to home"
            onClick={goDashboard}
          ></Button>
        </div>
      </div>
    </div>
  );
};
