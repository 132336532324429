import { Button } from "primereact/button";

const showClubName = (rowData) => {
  return (
    rowData.club && (
      <Button label={rowData.club.clubName} className="p-button-help" />
    )
  );
};

const showOnboardingImage = (rowData) => {
  return (
    rowData.imageUrl && (
      <img
        src={rowData.imageUrl}
        alt="onboarding"
        style={{ maxWidth: "100px" }}
      />
    )
  );
};

const renderDescription = (rowData) => {
  const baseLength = 40;
  const length =
    rowData?.shortDescription?.length >= baseLength
      ? baseLength
      : rowData?.shortDescription?.length;

  return <p>{rowData?.shortDescription?.substring(0, length)}...</p>;
};

const showImageLogo = (rowData) => {
  return rowData.logoUrl ? (
    <img src={rowData.logoUrl} alt="logo url" style={{ maxWidth: "100px" }} />
  ) : null;
};

const fieldsToShow = [
  {
    header: "Image",
    name: "imageUrl",
    body: showOnboardingImage,
  },
  {
    header: "Title",
    name: "title",
  },
  {
    header: "Description",
    name: "shortDescription",
    body: renderDescription,
  },
  {
    header: "Logo Image",
    name: "logoUrl",
    body: showImageLogo,
  },
  {
    header: "Club",
    name: "club",
    body: showClubName,
  },
];

const fieldsToEdit = [
  {
    title: "Title",
    name: "title",
    type: "string",
  },
  {
    title: "Image url",
    name: "imageUrl",
    type: "string",
  },
  {
    title: "Logo url",
    name: "logoUrl",
    type: "string",
  },
  {
    title: "Description",
    name: "shortDescription",
    type: "textarea",
  },
  {
    title: "Btn text",
    name: "btnText",
    type: "string",
  },
];

const fieldsToCreate = [
  {
    title: "Title",
    name: "title",
    type: "string",
  },
  {
    title: "Image url",
    name: "imageUrl",
    type: "string",
  },
  {
    title: "Logo url",
    name: "logoUrl",
    type: "string",
  },
  {
    title: "Description",
    name: "shortDescription",
    type: "textarea",
  },
  {
    title: "Btn text",
    name: "btnText",
    type: "string",
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
