import React from "react";
import { useUser } from "../../../hooks/user/useUser";

export const PartnerAppUsage = ({data}) => {
  const user = useUser();
  return (
    <div className="card statistics">
      <div className="card-header">
        <div className="card-title">
          <h6>*Statistics*</h6>
          <p className="subtitle">{user.club?.clubName}</p>
        </div>
        <p className="subtitle">8 May</p>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>🖥 </span>
              <h5>2K</h5>
            </div>
            <h6>Connected devices</h6>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>🎁 </span>
              <h5>{data?.numberOfPrizes || 0}</h5>
            </div>
            <h6>Prizes</h6>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>💵 </span>
              <h5>{data?.earningTotal ?? 0} lv</h5>
            </div>
            <h6>Earnings/month</h6>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="statistic-item">
            <div className="item-title">
              <span>👨🏻‍💻</span>
              <h5>123452</h5>
            </div>
            <h6>Website views</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
