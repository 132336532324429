import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ThemeContext, {
  changeStyleSheetsColor,
} from "../../context/theme/ThemeContext";
import UserContext from "../../context/user/userContext";
import { useNotification } from "../../hooks/notification/useNotification";
import authenticationService from "../../services/authentication/authenticationService";
import abpUserConfigurationService from "../../services/configurations/abpUserConfigurationService";
import tenantService from "../../services/tenants/tenantsService";
import Utils from "../../utils/utils";
import Loading from "../loading/Loading";

let abp = window.abp;

const getAllData = ({ setLoading, showError, setUser }) => {
  let tenantName = process.env.REACT_APP_TENANT_NAME ?? "";

  tenantService
    .getIdByTenantName({ tenancyName: tenantName })
    .then((response) => {
      if (!response?.tenantId) {
        showError("There is no tenant with that name");
      }
      abp.multiTenancy.setTenantIdCookie(response?.tenantId);
    })
    .catch(() => {
      showError();
    });

  abpUserConfigurationService
    .getAll()
    .then((data) => {
      Utils.extend(true, abp, data.data.result);
      abp.clock.provider = Utils.getCurrentClockProvider(
        data.data.result.clock.provider
      );

      // get user if auth
      if (authenticationService.isAuthenticated()) {
        authenticationService.getCurrentLoginInfo().then((res) => {
          setUser(res.user);
        });
      }
      setLoading(false);
    })
    .catch(() => {
      showError("An error occured while fetching the user information");
    });
};

const InitialWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [colorScheme, setColorScheme] = useState(
    localStorage.getItem("colorScheme")
  );
  const [themeScheme, setThemeScheme] = useState(
    localStorage.getItem("themeScheme")
  );
  const [topbarScheme, setTopbarScheme] = useState("light");
  const [menuScheme, setMenuScheme] = useState("light");
  const [menuMode, setMenuMode] = useState(localStorage.getItem("menuMode"));
  const queryClient = useQueryClient();

  const { showError } = useNotification();

  useEffect(() => {
    if (searchParams.get("clearCache")) {
      getAllData({ setLoading, showError, setUser });
      navigate("/");
    }
  }, [location.pathname, searchParams]);

  useEffect(() => {
    getAllData({ setLoading, showError, setUser });
  }, []);

  const refreshUserData = () => {
    queryClient
      .invalidateQueries({
        queryKey: ["dashboard"],
      })
      .then(() => {
        getAllData({ setLoading, showError, setUser });
      });
  };

  useEffect(() => {
    if (colorScheme !== "null" && colorScheme !== "undefined" && colorScheme) {
      changeColorScheme(colorScheme);
    } else {
      changeColorScheme("light");
    }

    if (menuMode !== "null" && menuMode !== "undefined" && menuMode) {
      setMenuMode(menuMode);
    } else {
      setMenuMode("sidebar");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem("colorScheme", colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    localStorage.setItem("menuMode", menuMode);
  }, [menuMode]);

  const changeColorScheme = (scheme) => {
    changeStyleSheetsColor("layout-css", "layout-" + scheme + ".css", 1);
    changeStyleSheetsColor("theme-css", "theme-" + scheme + ".css", 1);

    setColorScheme(scheme);
    setMenuScheme(scheme);
    setTopbarScheme(scheme);

    if (scheme !== colorScheme) {
      window.location.reload();
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <UserContext.Provider value={{ user, refresh: refreshUserData }}>
        <ThemeContext.Provider
          value={{
            colorScheme,
            setColorScheme,
            topbarScheme,
            setTopbarScheme,
            menuScheme,
            setMenuScheme,
            themeScheme,
            setThemeScheme,
            menuMode,
            setMenuMode,
            changeStyleSheetsColor,
            changeColorScheme,
          }}
        >
          {children}
        </ThemeContext.Provider>
      </UserContext.Provider>
    </React.Fragment>
  );
};

export default InitialWrapper;
