import http from "../http/httpService";

class LanguagesService {
  async getAll(params) {
    let result = await http.get("api/services/app/Language/GetAll", { params });
    return result.data.result;
  }

  async get(data) {
    let result = await http.get("api/services/app/Language/GetById", {
      params: data,
    });
    return result.data.result;
  }

  async create(data) {
    let result = await http.post("api/services/app/Language/Create", data);
    return result.data.result;
  }

  async update(data) {
    let result = await http.put("api/services/app/Language/Update", data);
    return result.data.result;
  }

  async delete(data) {
    let result = await http.delete("api/services/app/Language/DeleteById", {
      params: data,
    });
    return result.data.result;
  }
}

export default new LanguagesService();
