import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import imagesService from "../../services/images/imagesService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./imagesConfig";

const Images = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllImages();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  const getAllImages = () => {
    setLoading(true);
    imagesService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((clubs) => {
        clubs.items.map((item) => {
          if (item.fileName.endsWith(".json")) {
            item.contents = b64_to_utf8(item.contents);
          }
        });
        setImages(clubs.items);
        setLazyParams({ ...lazyParams, totalRecords: clubs.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onEditSubmit = (data) => {
    imagesService
      .update(data)
      .then((res) => {
        getAllImages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    imagesService
      .upload(data)
      .then((res) => {
        getAllImages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    imagesService
      .delete({
        id: data.id,
      })
      .then((res) => {
        getAllImages();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleImage = async (id) => {
    const imageData = await imagesService.get({ id });
    return imageData;
  };

  return (
    <div>
      <CrudTable
        data={images}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        headerTitle="Manage Images"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        primaryKey="id"
        getSingleEntity={getSingleImage}
        createPermission="Pages.Image.Create"
        editPermission={"Pages.Image.Edit"}
        deletePermission={"Pages.Image.Delete"}
        onRowDoubleClick={(e) => window.open(e.data?.imageUrl, "_blank")}
      />
    </div>
  );
};

export default Images;
