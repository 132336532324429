import { Chart } from "primereact/chart";

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  hover: {
    mode: "index",
  },
  scales: {
    x: {
      display: true,
      grid: {
        color: "transparent",
      },
      ticks: {
        color: "#BFC2C6",
      },
    },
    y: {
      display: true,
      grid: {
        color: "rgba(191, 194, 198, .45)",
        borderDash: [5, 10],
      },
      ticks: {
        color: "#BFC2C6",
        min: 0,
        stepSize: 5,
      },
    },
  },
};

const ClubCardsPerMonthChart = ({ data }) => {
  const getChart = () => {
    const borderColor =
      getComputedStyle(document.body).getPropertyValue("--primary-color") ||
      "#2c84d8";
    const backgroundColor =
      getComputedStyle(document.body).getPropertyValue(
        "--primary-lighter-color"
      ) || "#2c84d8";
    return {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Cards",
          data: data?.numberOfClubCardsPerMonth || [],
          borderColor: [borderColor],
          borderWidth: 4,
          fill: true,
          backgroundColor: [backgroundColor],
          tension: 0.4,
        },
      ],
    };
  };
  const chartData = getChart();

  return (
    <div>
      <Chart
        type="line"
        data={chartData}
        options={chartOptions}
        style={{ maxHeight: "800px" }}
      ></Chart>
    </div>
  );
};

export default ClubCardsPerMonthChart;
