import http from "../http/httpService";

class StaticContentService {
  async update(entityDto) {
    let result = await http.put(
      "api/services/app/StaticContent/Update",
      entityDto
    );
    return result.data.result;
  }

  async create(entityDto) {
    let result = await http.post(
      "api/services/app/StaticContent/Create",
      entityDto
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete(
      "api/services/app/StaticContent/DeleteById",
      {
        params: entityDto,
      }
    );
    return result.data.result;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/StaticContent/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/StaticContent/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new StaticContentService();
