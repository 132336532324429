import http, { getFormData } from "../http/httpService";

class CardTypesService {
  async get(entityDto) {
    let result = await http.get("api/services/app/CardTypes/GetById", {
      params: {
        ...entityDto,
      },
    });
    return result.data.result;
  }

  async update(updateCardTypeInput, lang) {
    const formData = getFormData(updateCardTypeInput);

    let result = await http.put("api/services/app/CardTypes/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        lang,
      },
    });
    return result.data.result;
  }

  async create(createCardTypeInput) {
    const formData = getFormData(createCardTypeInput);

    let result = await http.post(
      "api/services/app/CardTypes/Create",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/CardTypes/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/CardTypes/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
  async getTranslationsBySlug(slug) {
    let result = await http.get("api/services/app/CardTypes/GetTranslationsBySlug", {
      params: { slug },
    });
    return result.data.result;
  }
}

export default new CardTypesService();
