import http from "../http/httpService";

class DatabaseSynchronizer {
  async getAll() {
    let result = await http.get("api/services/app/DatabaseSynchronizer/GetAll");
    return result.data.result;
  }

  async getAllTableNames() {
    let result = await http.get(
      "api/services/app/DatabaseSynchronizer/GetAllTablesNames"
    );
    return result.data.result;
  }

  async syncronizeData(data) {
    let result = await http.post(
      "api/services/app/DatabaseSynchronizer/SyncronizeData",
      data
    );
    return result.data.result;
  }
}

export default new DatabaseSynchronizer();
