import { ClubAppUsage } from "../components/ClubAppUsage";
import ClubCardsPerMonthChart from "../components/ClubCardsPerMonthChart";
import { RenderTeamMembers } from "../components/RenderTeamMembers";

export const ClubDashboard = ({ data, loading, dates }) => {
  return (
    <div className="club-dashboard">
      <div className="dataColumn">
        <div className="card overview-box dashboard-card">
          <div className="overview-info">
            <h6>Total Prizes</h6>
            <h1>{data.numberOfPrizes}</h1>
            <p>Club Provided: {data.prizesProvidedByClub}</p>
          </div>
          <i className="pi pi-users"></i>
        </div>
        <div className="card overview-box blue dashboard-card">
          <div className="overview-info">
            <h6>Number of cards</h6>
            <h1>{data.numberOfClubCards}</h1>
            <p>Users: {data.numberOfCardUsers}</p>
          </div>
          <i className="pi pi-users"></i>
        </div>
        <RenderTeamMembers teamMembers={data?.teamMembers} />
      </div>
      <div className="dataColumn">
        <div className="col-6">
          <h3>Number of cards per month:</h3>
          <ClubCardsPerMonthChart data={data} />
        </div>
        <div className="col-6">
          <ClubAppUsage dates={dates} data={data} />
        </div>
      </div>
      <br />
    </div>
  );
};
