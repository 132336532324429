import { Button } from "primereact/button";

const showClubName = (rowData) => {
  return (
    rowData.club && (
      <Button label={rowData.club.clubName} className="p-button-help" />
    )
  );
};

const fieldsToShow = [
  {
    header: "Id",
    name: "id",
  },
  {
    header: "Title",
    name: "title",
  },
  {
    header: "LayoutName",
    name: "layoutName",
  },
  {
    header: "Club",
    name: "club.clubName",
    body: showClubName,
  },
];

const fieldsToEdit = [
  {
    name: "title",
    title: "Title",
    type: "string",
  },
  {
    name: "layoutName",
    title: "LayoutName",
    type: "string",
    rules: {
      required: "LayoutName is required",
    },
  },
  {
    name: "layoutJson",
    title: "LayoutJson",
    type: "json",
    rules: {
      required: "LayoutJson is required",
    },
  },
];

const fieldsToCreate = [];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
