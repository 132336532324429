import { Button } from "primereact/button";

const showPartnerName = (rowData) => {
  return (
    rowData.partnerId && (
      <Button label={rowData.partnerId} className="p-button-help" />
    )
  );
};

const fieldsToShow = [
  {
    header: "ApplicationName",
    name: "applicationName",
  },
  {
    header: "IP Address White List",
    name: "ipaddressWhiteList",
  },
  {
    header: "Api Key",
    name: "apiKey",
  }
];

const fieldsToEdit = [
  {
    title: "Application Name",
    name: "applicationName",
    type: "string",
    rules: {
      required: "Application Name is required",
    },
  },
  {
    title: "IP Address White List",
    name: "ipaddressWhiteList",
    type: "string",
    rules: {
      required: "IP Address White List is required",
    },
  },
  {
    title: "Api Key",
    name: "apiKey",
    type: "string",
    rules: {
      required: "Api Key is required",
    },
  },
];

const fieldsToCreate = [
  {
    title: "Application Name (if You leave it empty, will generate 'name' automaticaly)",
    name: "applicationName",
    type: "string",
  },
  {
    title: "IP Address White List (if You leave it empty, will generate '*.*')",
    name: "ipaddressWhiteList",
    type: "string",
  },
  {
    title: "Api Key (if You leave it empty, will generate 'key' automaticaly)",
    name: "apiKey",
    type: "string",
  },
];

export { fieldsToCreate, fieldsToEdit, fieldsToShow };
