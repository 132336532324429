import React from "react";
import { Link } from "react-router-dom";

export const SDClubCards = ({ data }) => {
  return (
    <div className="sd-club-cards">
      {data.clubCards.map((club) => (
        <div key={club.clubId} className="club-card-child">
          <div className="club-card-info">
            <img src={`data:image/jpeg;base64,${club.clubImage}`} alt="club" />
            <div className="club-info">
              <h2>{club.clubName}</h2>
              <div className="club-text">
                <p>
                  <i className="fa-regular fa-id-card"></i>
                  <span>{club.numberOfCards}</span>
                </p>
                <p>
                  <i className="fa-solid fa-trophy"></i>
                  <span>{club.numberOfPrizes}</span>
                </p>
                <p>
                  <i className="fa-solid fa-handshake"></i>
                  <span>{club.numberOfPartners}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="club-card-data">
            <div>
              <h5>Prizes:</h5>
              <div>
                <p className="m-0">Active: {club.activePrizes}</p>
                <p className="m-0">Cost: {club.prizesCost}</p>
                <Link to={`/clubs/${club.clubId}/prizes/reports`}>
                  <p style={{ color: "red", marginTop: "10px", textDecoration: "underline" }}>See more details</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
