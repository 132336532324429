import { useState, useEffect } from "react";

function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  let initial;
  try {
    initial = JSON.parse(saved);
  } catch (e) {
    try {
      initial = parseFloat(saved);
    } catch (e) {}
    initial = saved;
  }
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue = undefined) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  const clearValue = () => {
    setValue(null);
    localStorage.removeItem(key);
  };

  useEffect(() => {
    // storing input name
    let newValue;
    newValue = JSON.stringify(value);
    if (value) {
      localStorage.setItem(key, newValue);
    }
  }, [key, value]);

  return [value, setValue, clearValue];
};
