import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageCropDialog from "../../components/ImageCrop/ImageCropDialog";
import { useUser } from "../../hooks/user/useUser";
import usersService from "../../services/users/usersService";
import { L } from "../../utils/abpUtility";
import "./Profile.scss";

const Profile = () => {
  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [imageName, setImageName] = useState("");
  const user = useUser();

  const uploadRef = useRef(null);

  const onCancel = () => {
    setImage(null);
  };

  useEffect(() => {
    if (croppedImg) {
      (async () => {
        const pr = await blobToBase64(croppedImg);
        setPreviewImg(pr);

        await usersService.updateProfilePicture({
          id: user?.id,
          file: croppedImg,
        });
      })();
    }
  }, [croppedImg]);

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const handleFileChange = (e) => {
    let image = e.target.files?.[0];

    if (!image) return;

    if (image) {
      const imageReader = new FileReader();
      imageReader.readAsDataURL(image);
      imageReader.onloadend = () => {
        setImage(imageReader.result);
        setImageName(image.name);
      };
    }
  };

  return (
    <div className="card">
      <div className="profile">
        <div className="col-12 md:col-4 mb-4 px-5">
          <div
            className="imageShowcase"
            onClick={() => uploadRef.current.click()}
          >
            <img
              src={
                previewImg
                  ? previewImg
                  : user?.profilePicture
                  ? `data:image/jpeg;base64,${user.profilePicture}`
                  : `${process.env.PUBLIC_URL}/images/defaultProfilePicture.jpeg`
              }
              alt=""
            />
            <div className="preview">
              <i className="pi pi-eye" />
            </div>
          </div>

          <div className="text-900 mb-3 font-medium text-xl uppercase">
            {user.name} {user.surname}
            <span className="text-500 text-sm px-1 line-height-3 text-lg">
              - @{user.userName}
            </span>
          </div>
          <span className="text-700 text-md line-height-3">
            {user.emailAddress}
          </span>
          <br />
          <br />
          <Link to={"/change-password"}>
            <Button label={L("Change") + (" ") + L("Password")} />
          </Link>
        </div>
      </div>
      {image ? (
        <ImageCropDialog
          image={image}
          onCancel={onCancel}
          setImage={setImage}
          isEdit={true}
          imageName={imageName}
          setImageName={setImageName}
          setCroppedImg={setCroppedImg}
          ratio={3 / 3}
          hideRatios={true}
          hideRawUpload={true}
        />
      ) : null}
      <input
        type="file"
        ref={uploadRef}
        onChange={(e) => handleFileChange(e)}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default Profile;
