import { useEffect, useState } from "react";
import ImageCropDialog from "./ImageCropDialog";
import { Button } from "primereact/button";
import ImageUpload from "../imageUpload/ImageUpload";
import "./imageCrop.css";

/**
 * It renders a button that opens a dialog that allows you to crop an image
 */
const ImageCrop = ({ setValue, name, data, hideRatios }) => {
  const [image, setImage] = useState(undefined);
  const [croppedImg, setCroppedImg] = useState(undefined);
  const [previewImg, setPreviewImg] = useState(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [imageName, setImageName] = useState("");

  /**
   * It takes a blob and returns a promise that resolves to a base64 string
   * @param blob - The blob to convert to base64.
   * @returns A promise that resolves to a base64 encoded string.
   */
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    setIsEdit(data.contents);
    setPreviewImg(`data:image/png;base64,${data.contents}`);
  }, [name, data]);

  useEffect(() => {
    const imageNameValue = data?.imageName || imageName;
    const imageValue = {
      name: imageNameValue,
      image: croppedImg,
    };
    setValue(name, imageValue);

    if (croppedImg) {
      (async () => {
        const pr = await blobToBase64(croppedImg);
        setPreviewImg(pr);
      })();
    }
  }, [croppedImg, name, setValue]);

  const onClear = () => {
    setPreviewImg(undefined);
    setValue(name, undefined);
  };

  const openDialog = () => {
    setImage(`data:image/png;base64,${data.contents}`);
  };

  /**
   * When the user selects an image, we read the image as a data URL and set the image state to the
   * data URL
   * @param e - the event object
   */
  const handleFileChange = (e) => {
    let image = e.files[0];

    if (image) {
      const imageReader = new FileReader();
      imageReader.readAsDataURL(image);
      imageReader.onloadend = () => {
        setImage(imageReader.result);
        setImageName(image.name);
      };
    }
  };

  const onCancel = () => {
    setImage(null);
  };

  return (
    <div className="imageCropContainer">
      <ImageUpload
        onSelect={(e) => handleFileChange(e)}
        onClear={onClear}
        onRemove={onClear}
      />

      {previewImg && (
        <img
          src={previewImg}
          style={{
            width: "30%",
          }}
          alt=""
        />
      )}

      {isEdit ? (
        <Button label="Edit Image" type="button" onClick={openDialog} />
      ) : null}

      {image ? (
        <ImageCropDialog
          image={image}
          onCancel={onCancel}
          setImage={setImage}
          isEdit={isEdit}
          hideRatios={hideRatios}
          imageName={imageName}
          setImageName={setImageName}
          setCroppedImg={setCroppedImg}
        />
      ) : null}
    </div>
  );
};

export default ImageCrop;
