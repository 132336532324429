import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import cardsService from "../../services/cards/cardsService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./cardsConfig";
import cardBatchesService from "../../services/cardBatches/cardBatchesService";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../../hooks/user/useUser";
import clubService from "../../services/club/clubService";

const cardStatusArray = [
  { label: "Ordered", value: 1 },
  { label: "Printed", value: 2 },
  { label: "Posted", value: 3 },
  { label: "Delivered", value: 4 },
  { label: "Activated", value: 5 },
  { label: "For Printing", value: 10 },
  { label: "Deactivated", value: 999 },
];

const Cards = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [cardBatches, setCardBatches] = useState([]);
  const [selectedCardBatch, setSelectedCardBatch] = useState();
  const [selectedClubId, setSelectedClubId] = useState(null);
  const [cardStatus, setCardStatus] = useState(undefined);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  const user = useUser();

  const { data: clubData } = useQuery({
    queryKey: [
      "clubs",
      {
        hasNoImages: true,
        hasNoPanator: true,
      },
    ],
    queryFn: () =>
      clubService.getAll({
        hasNoImages: true,
        hasNoPanator: true,
      }),
  });

  let clubs = clubData?.items ?? [];

  const onSort = (field, sortOrder) => {
    setLazyParams({ ...lazyParams, sortField: field, sortOrder: sortOrder });
  };

  useEffect(() => {
    getAllCards();
  }, [
    lazyParams.first,
    lazyParams.rows,
    globalFilter,
    selectedCardBatch,
    lazyParams.sortField,
    cardStatus,
    selectedClubId,
  ]);

  useEffect(() => {
    (async () => {
      const data = await cardBatchesService.getAll({
        HasNoPaginator: true,
      });
      setCardBatches(data.items);
    })();
  }, []);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllCards = () => {
    setLoading(true);
    cardsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        BatchId: selectedCardBatch,
        Status: cardStatus,
        ClubId: selectedClubId || undefined,
        Sorting: lazyParams.sortField
          ? lazyParams.sortField + " DESC"
          : "OrderedOn DESC",
      })
      .then((cards) => {
        setCards(cards.items);
        setLazyParams({ ...lazyParams, totalRecords: cards.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleCard = async (id) => {
    const cardData = await cardsService.get({ id });
    return cardData;
  };

  const extendHeader = () => {
    return (
      <>
        <Dropdown
          options={cardBatches}
          optionValue="id"
          className="mr-3"
          optionLabel="title"
          placeholder="Select batch..."
          value={selectedCardBatch}
          onChange={(e) => setSelectedCardBatch(e.value)}
          showClear
        />
        <Dropdown
          options={cardStatusArray}
          placeholder="Select card status"
          value={cardStatus}
          className="mr-3"
          onChange={(e) => setCardStatus(e.value)}
          showClear
        />
        {!user?.clubId && (
          <Dropdown
            options={clubs}
            optionValue="id"
            optionLabel="clubName"
            placeholder="Select a club"
            value={selectedClubId}
            onChange={(e) => {
              setSelectedClubId(e.value);
            }}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={cards}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Cards"
        onSort={onSort}
        extendHeader={extendHeader}
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleCard}
        canCreate={false}
        canDelete={false}
        canEdit={false}
      />
    </div>
  );
};

export default Cards;
