function formatCardNumber(cardNumber) {
  if (!cardNumber || cardNumber.length !== 16) {
    return cardNumber;
  }

  return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4");
}

const getCardNumber = (number) => {
  number = number ?? "";
  return number.replace(/-/g, "").replace(/\s/g, "");
};

export { getCardNumber, formatCardNumber };
