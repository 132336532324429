import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import ticketsService from "../../services/tickets/ticketsService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToCreate, fieldsToEdit, fieldsToShow } from "./ticketsConfig";

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllTickets();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const getAllTickets = () => {
    setLoading(true);
    ticketsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((tickets) => {
        setTickets(tickets.items);
        setLazyParams({ ...lazyParams, totalRecords: tickets.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleTicket = async (id) => {
    const ticketData = await ticketsService.get({ id });
    return ticketData;
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={tickets}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Tickets"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleTicket}
        canCreate={false}
        canDelete={false}
        canEdit={false}
      />
    </div>
  );
};

export default Tickets;
