import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import reportsService from "../../services/dashboard/dashboardService";
import authenticationService from "../../services/authentication/authenticationService";
import { useNavigate } from "react-router-dom";
import "../../styles/Dashboard.scss";
import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "primereact/dropdown";
import { ClubDashboard } from "./pages/ClubDashboard";
import { PartnerDashboard } from "./pages/PartnerDashboard";
import { SportsDynamicsDashboard } from "./pages/SportsDynamicsDashboard";
import { useMemo } from "react";

const today = new Date();

export const datePickingOptions = [
  {
    label: "Today",
    value: {
      startDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      endDate: today,
    },
  },
  {
    label: "Last 7 days",
    value: {
      startDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 6
      ),
      endDate: today,
    },
  },
  {
    label: "Last month",
    value: {
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      endDate: today,
    },
  },
  {
    label: "Last 6 months",
    value: {
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate()
      ),
      endDate: today,
    },
  },
  {
    label: "Last year",
    value: {
      startDate: new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      ),
      endDate: today,
    },
  },
  {
    label: "All time",
    value: {
      startDate: new Date(
        today.getFullYear() - 5,
        today.getMonth(),
        today.getDate()
      ),
      endDate: today,
    },
  },
];

const Dashboard = () => {
  const defaultValue = useMemo(() => {
    const option = datePickingOptions.find((i) => i.label === "Last 7 days").value;
    return option;
  }, []);

  const [date, setDate] = useState(defaultValue);

  const { data, isLoading } = useQuery({
    queryKey:
      date.startDate || date.endDate
        ? ["dashboard", date.startDate, date.endDate]
        : ["dashboard"],
    queryFn: () =>
      reportsService.getDashboard({
        startDate: date.startDate || undefined,
        endDate: date.endDate || undefined,
      }),
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticationService.isAuthenticated()) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="card p-7">
      <div>
        <div className="dashboard-calendar-picker">
          <Dropdown
            value={date}
            onChange={(e) => setDate(e.value)}
            options={datePickingOptions}
          />
        </div>

        <br />
        <br />
        <br />
        <RootDashboard
          data={data}
          loading={isLoading}
          dates={[date.startDate, date.endDate]}
        />
      </div>
    </div>
  );
};

const RootDashboard = ({ data, loading, dates }) => {
  switch (data?.type) {
    case "partner":
      return <PartnerDashboard data={data} loading={loading} dates={dates} />;

    case "club":
      return <ClubDashboard data={data} dates={dates} loading={loading} />;

    case "sd":
      return (
        <SportsDynamicsDashboard data={data} dates={dates} loading={loading} />
      );

    default:
      return <Loading />;
  }
};

const Loading = () => {
  return (
    <>
      <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="10rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="5rem" borderRadius="16px" className="mb-2"></Skeleton>
      <Skeleton height="2rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="10rem" height="4rem" borderRadius="16px"></Skeleton>
    </>
  );
};

export default React.memo(Dashboard);
