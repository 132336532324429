import { useQuery } from "@tanstack/react-query";
import languagesService from "../../services/languages/languagesService";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "./createPrize.scss";
import { Calendar } from "primereact/calendar";
import clubService from "../../services/club/clubService";
import partnersService from "../../services/partners/partnersService";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import { useEffect, useState } from "react";
import { generatePrizeVideoDescription } from "./prizesConfig";
import prizesService from "../../services/prizes/prizesService";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  defaultImage,
  imageShowBody,
} from "../../components/crudTable/crudTableUtils";

let clubProps = {
  hasNoImages: true,
  hasNoPaginator: true,
};

export const UpdatePrize = () => {
  const { id } = useParams();
  const [clubPackageName, setPackageName] = useState();
  const [loading, setLoading] = useState(true);
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      promoUrl: "",
      isFreeClosed: true,
      maxFreeEntries: 3,
    },
  });

  const { data: prize, isLoading } = useQuery({
    queryKey: ["prizes", id],
    queryFn: () => prizesService.get({ id }),
    onSuccess: (data) => {
      reset(data);
      setPackageName(data?.club?.packageName);
    },
  });

  const [error, setError] = useState();
  const [translatedErrors, setTranslatedErrors] = useState({});
  const [translatedProperties, setTranslatedProperties] = useState({});
  const navigate = useNavigate();
  const { data: langs } = useQuery({
    queryKey: ["languages"],
    queryFn: () => languagesService.getAll(),
  });
  let languages = langs?.items || [];
  let openedIds = languages.map((i, idx) => idx);

  let clubId = watch("clubId");
  const { data: clubData } = useQuery({
    queryKey: ["clubs", clubProps],
    queryFn: () => clubService.getAll(clubProps),
  });

  let clubs = clubData?.items ?? [];

  const { data: partnersData } = useQuery({
    queryKey: ["partners", { clubId }],
    queryFn: () => partnersService.getAllByClub({ ClubId: clubId }),
    enabled: !!clubId,
  });

  let partners = partnersData || [];

  useEffect(() => {
    (async () => {
      if (languages.length > 0 && id) {
        for (let lang of languages) {
          let prize = await prizesService.get({
            id: id,
            lang: lang.id,
            hasNoImages: true,
          });

          if (!prize) continue;

          setTranslatedProperties((prev) => ({
            ...prev,
            [lang.id]: {
              prizeTitle: prize.prizeTitle,
              description: prize.description,
            },
          }));
        }
        setLoading(false);
      }
    })();
  }, [languages, id]);

  const getDefaultId = () => {
    let language = languages.find((i) => i.code === "en");

    if (language) {
      return language.id;
    }
    return language?.[0]?.id;
  };

  const onFormSubmit = async (data) => {
    setLoading(true);
    let defaultId = getDefaultId();
    let translation = translatedProperties[defaultId];
    let modData = {
      ...data,
      ...translation,
      lang: defaultId,
      isFreeClosed: !data.isFreeClosed,
    };
    modData.id = id;
    await prizesService.update(data).catch(() => {
      setError("An unexpected error occured during the prize creation");
      return null;
    });
    for (let lang of languages) {
      let translations = translatedProperties[lang.id];
      await prizesService.updateTranslations({
        id: id,
        ...translations,
        lang: lang.id,
      });
    }
    navigate(-1);
  };

  const handleVideoButtonClicked = () => {
    let base64 = generatePrizeVideoDescription(clubPackageName, id);
    navigator.clipboard.writeText(base64);
  };

  return isLoading || loading ? (
    <div className="flex card">
      <ProgressSpinner />
    </div>
  ) : (
    <div className="card prize-create">
      {error && <div className="errorMessage">{error}</div>}
      <div className="prize-container">
        <form onSubmit={handleSubmit(onFormSubmit)} className="inputs">
          {languages && openedIds && (
            <Accordion activeIndex={[0, 1]} multiple>
              {languages.map((lang) => (
                <AccordionTab disabled header={lang.name} key={lang.locale}>
                  <div className="flex flex-column" style={{ gap: "12px" }}>
                    <div className="flex flex-column w-100">
                      <label className="small">
                        Title{" "}
                        <span className="error">
                          {translatedErrors?.[lang.id]?.prizeTitle}
                        </span>
                      </label>
                      <InputText
                        placeholder={"Prize title"}
                        required
                        value={translatedProperties[lang.id]?.prizeTitle}
                        onChange={(e) => {
                          setTranslatedErrors({
                            ...translatedErrors,
                            [lang.id]: {
                              ...translatedErrors[lang.id],
                              prizeTitle: null,
                            },
                          });
                          setTranslatedProperties({
                            ...translatedProperties,
                            [lang.id]: {
                              ...translatedProperties[lang.id],
                              prizeTitle: e.target.value,
                            },
                          });
                        }}
                        onInvalid={() =>
                          setTranslatedErrors({
                            ...translatedErrors,
                            [lang.id]: {
                              ...translatedErrors[lang.id],
                              prizeTitle: "is required",
                            },
                          })
                        }
                      />
                    </div>
                    <div>
                      <label className="small">
                        Description{" "}
                        <span className="error">
                          {translatedErrors?.[lang.id]?.description}
                        </span>
                      </label>
                      <InputTextarea
                        placeholder="Prize description"
                        rows={5}
                        className="mt-2"
                        required
                        autoResize={false}
                        value={translatedProperties[lang.id]?.description}
                        onChange={(e) => {
                          setTranslatedErrors({
                            ...translatedErrors,
                            [lang.id]: {
                              ...translatedErrors[lang.id],
                              description: null,
                            },
                          });
                          setTranslatedProperties({
                            ...translatedProperties,
                            [lang.id]: {
                              ...translatedProperties[lang.id],
                              description: e.target.value,
                            },
                          });
                        }}
                        onInvalid={() =>
                          setTranslatedErrors({
                            ...translatedErrors,
                            [lang.id]: {
                              ...translatedErrors[lang.id],
                              description: "is required",
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </AccordionTab>
              ))}
            </Accordion>
          )}
          <div className="calendars">
            <div className="flex flex-column">
              <label>
                Start Date{" "}
                <span className="error">{errors.startDate?.message}</span>
              </label>
              <Controller
                control={control}
                name="startDate"
                rules={{
                  required: "is required",
                }}
                render={({ field }) => (
                  <Calendar
                    inline
                    showTime
                    {...field}
                    value={new Date(field.value)}
                    onChange={(e) =>
                      field.onChange(
                        new Date(
                          e.value.setMinutes(
                            e?.value == null
                              ? 0
                              : -1 * e?.value?.getTimezoneOffset()
                          )
                        ).toISOString()
                      )
                    }
                  />
                )}
              />
            </div>

            <div className="flex flex-column">
              <label>
                End Date{" "}
                <span className="error">{errors.endDate?.message}</span>
              </label>
              <Controller
                control={control}
                rules={{
                  required: "is required",
                }}
                name="endDate"
                render={({ field }) => (
                  <Calendar
                    inline
                    showTime
                    {...field}
                    value={new Date(field.value)}
                    onChange={(e) =>
                      field.onChange(
                        new Date(
                          e.value.setMinutes(
                            e?.value == null
                              ? 0
                              : -1 * e?.value?.getTimezoneOffset()
                          )
                        ).toISOString()
                      )
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-column">
            <label>Provider club</label>
            <span>
              If empty it's assumed that the prize is provided by Sports Dynamics
            </span>
            <br />
            <Controller
              control={control}
              name={"clubProviderId"}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={clubs}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  optionValue={"id"}
                  placeholder="Select a prize provider club"
                  showClear
                  optionLabel="clubName"
                  className="m-0"
                  disabled={clubs.length === 0}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="flex flex-column">
            <label>
              Club <span className="error">{errors.clubId?.message}</span>
            </label>
            <Controller
              control={control}
              name={"clubId"}
              rules={{
                required: "is required",
              }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={clubs}
                  onChange={(e) => {
                    field.onChange(e.value);
                    if (e.value) {
                      let packageName = clubs.find(
                        (i) => i.id === e.value
                      )?.packageName;
                      setPackageName(packageName);
                    } else {
                      setPackageName(null);
                    }
                  }}
                  optionValue={"id"}
                  placeholder="Select a club"
                  showClear
                  optionLabel="clubName"
                  className="m-0"
                  disabled={clubs.length === 0}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="flex flex-column">
            <label>
              Partner <span className="error">{errors.partnerId?.message}</span>
            </label>
            <Controller
              control={control}
              name={"partnerId"}
              rules={{ required: "is required" }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={partners}
                  optionValue={"id"}
                  placeholder={`${
                    !clubId
                      ? "Select a club to select partner"
                      : "Select a partner"
                  }`}
                  optionLabel="companyName"
                  disabled={partners.length === 0}
                  showClear
                />
              )}
            />
          </div>
          <div className="flex flex-column">
            <label>
              Price <span className="error">{errors.price?.message}</span>
            </label>
            <Controller
              control={control}
              defaultValue={0}
              name="price"
              rules={{
                required: {
                  value: true,
                  message: "is required",
                },
              }}
              render={({ field }) => (
                <InputNumber
                  placeholder="Price"
                  {...field}
                  value={field.value}
                  minFractionDigits={0}
                  maxFractionDigits={2}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
          <Accordion>
            <AccordionTab header="Free Entrance">
              <div className="flex flex-column" style={{ gap: 13 }}>
                <div>
                  <h5>Active free entrance</h5>
                  <Controller
                    name="isFreeClosed"
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        style={{ width: "45px" }}
                        {...field}
                        checked={!field.value}
                        onChange={(e) => field.onChange(!e.value)}
                      />
                    )}
                  />
                </div>
                <div>
                  <h5>Max free entries for a person</h5>
                  <Controller
                    name="maxFreeEntries"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                </div>
                <div>
                  <h5>Promotion url</h5>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">www</span>
                    <InputText
                      placeholder="Promotion url"
                      {...register("promoUrl")}
                    />
                  </div>
                </div>
                <div>
                  <h5>YouTube video</h5>
                  <Button
                    type="button"
                    label="Copy YouTube video description"
                    icon="fa-solid fa-copy"
                    severity="help"
                    disabled={!id || !clubPackageName}
                    onClick={handleVideoButtonClicked}
                  />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
          <div className="flex flex-column">
            <label>Winner Video</label>
            <InputText
              placeholder="Winner video url"
              {...register("winnerVideoUrl")}
            />
          </div>
          <div className="flex flex-column">
            <label>
              Image <span className="error">{errors.image?.message}</span>
            </label>
            <Controller
              name={"image"}
              rules={{
                required: "is required",
              }}
              control={control}
              render={({ field }) => (
                <ImageUpload
                  onClear={() => field.onChange(null)}
                  onRemove={() => field.onChange(null)}
                  onSelect={(event) => field.onChange(event.files[0])}
                />
              )}
            ></Controller>
            <div className="mt-3">
              {imageShowBody({ data: prize, name: "image" })}
            </div>
          </div>
          <div className="flex flex-column">
            <label>Multiple images</label>
            <Controller
              name={"prizeImages"}
              control={control}
              render={({ field }) => (
                <ImageUpload
                  onClear={() => field.onChange(null)}
                  onRemove={(e) =>
                    field.onChange(field.value?.filter((i) => i !== e.file))
                  }
                  multiple
                  onSelect={(event) => field.onChange(event.files)}
                />
              )}
            ></Controller>
            <div className="flex flex-wrap">
              {prize?.prizeImages?.map((img) => (
                <img
                  key={img.imageId}
                  src={`data:image/png;base64,${img?.image?.contents}`}
                  className="mr-3"
                  alt="club logo"
                  style={{
                    maxWidth: "130px",
                    borderRadius: "5px",
                    maxHeight: "130px",
                    objectFit: "cover",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = defaultImage;
                  }}
                />
              ))}
            </div>
          </div>
          <Button label="Update prize" />
        </form>
      </div>
    </div>
  );
};
