import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import environmentsService from "../../services/environments/environmentsService";
import { useErrors } from "../../utils/error/useErrors";

const Environments = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllEnvs();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const onEditSubmit = (data) => {
    environmentsService
      .update(data)
      .then(() => {
        getAllEnvs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getAllEnvs = () => {
    setLoading(true);
    environmentsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((campaigns) => {
        setCampaigns(campaigns.items);
        setLazyParams({ ...lazyParams, totalRecords: campaigns.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    environmentsService
      .create(data)
      .then((res) => {
        getAllEnvs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    environmentsService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllEnvs();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleEnvironment = async (id) => {
    const environmentData = await environmentsService.get({ id });
    return environmentData;
  };

  const fieldsToShow = [
    {
      header: "Name",
      name: "name",
    },
    {
      header: "Admin API",
      name: "adminApiUrl",
    },
    {
      header: "Admin UI",
      name: "adminUiUrl",
    },
  ];

  const fieldsToCreate = [
    {
      title: "Name",
      name: "name",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
    {
      title: "Admin API Url",
      name: "adminApiUrl",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
    {
      title: "Admin UI Url",
      name: "adminUiUrl",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
  ];

  const fieldsEdit = [

    {
      title: "Name",
      name: "name",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
    {
      title: "Admin API Url",
      name: "adminApiUrl",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
    {
      title: "Admin UI Url",
      name: "adminUiUrl",
      type: "string",
      rules: {
        required: "This is required",
      },
    },
  ];

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={campaigns}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Campaigns"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleEnvironment}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        createPermission="Pages.Environment.Create"
        editPermission={"Pages.Environment.Edit"}
        deletePermission={"Pages.Environment.Delete"}
      />
    </div>
  );
};

export default Environments;
